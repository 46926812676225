export const Icons = {
	abroadData: require('./abroad_data.svg'),
	abroadVoice: require('./abroad_voice.svg'),
	address_book_empty_state: require('./address_book_empty_state.svg'),
	addressBook: require('./address_book.svg'),
	arrowDown: require('./arrow_down.svg'),
	arrowSwipe: require('./arrow_swipe.svg'),
	background: require('./background.svg'),
	billingAddress: require('./billing_address.svg'),
	buyHbbWfbb: require('./buy_hbb_wfbb.svg'),
	calendar: require('./calendar.svg'),
	cancel: require('./cancel.svg'),
	cardAdd: require('./card_add.svg'),
	cardBackBlank: require('./card_back_blank.svg'),
	cardBackCCV: require('./card_back_ccv.svg'),
	cardFrontBlank: require('./card_front_blank.svg'),
	cardFrontNumber: require('./card_front_number.svg'),
	cardFrontName: require('./card_front_name.svg'),
	cardFrontExp: require('./card_front_exp.svg'),
	cardInformation: require('./card_information.svg'),
	car: require('./car.svg'),
	card: require('./card.svg'),
	cart: require('./cart.svg'),
	cartEmptyState: require('./cart_empty_state.svg'),
	check: require('./check.svg'),
	chevronDown: require('./chevron_down.svg'),
	chevronLeft: require('./chevron_left.svg'),
	chevronRight: require('./chevron_right.svg'),
	chevronUp: require('./chevron_up.svg'),
	clearInput: require('./clear_input.svg'),
	close: require('./close.svg'),
	comparisonArrow: require('./comparison_arrow.svg'),
	currentPosition: require('./current_position.svg'),
	dashboard: require('./dashboard.svg'),
	deliveryBus: require('./delivery_bus.svg'),
	details: require('./details.svg'),
	detailsActive: require('./details_active.svg'),
	device: require('./device.svg'),
	dimensions: require('./dimensions.svg'),
	document: require('./document.svg'),
	error: require('./error.svg'),
	errorFilled: require('./error_filled.svg'),
	eSim: require('./e_sim.svg'),
	eSimColor: require('./esim_color.svg'),
	emptyState: require('./empty_state.svg'),
	folderClosed: require('./folder_closed.svg'),
	folderOpen: require('./folder_open.svg'),
	frame: require('./frame.svg'),
	facebookCircle: require('./facebook_circle.svg'),
	file: require('./file.svg'),
	filter: require('./filter.svg'),
	filterActive: require('./filter_active.svg'),
	hamburgerMenu: require('./hamburger_menu.svg'),
	headphones: require('./headphones.svg'),
	heart: require('./heart.svg'),
	heartEmpty: require('./heart_empty.svg'),
	house: require('./house.svg'),
	info: require('./info.svg'),
	instagramCircle: require('./instagram_circle.svg'),
	insuranceType: require('./insurance_type.svg'),
	insuranceVehicle: require('./insurance_vehicle.svg'),
	insuranceProvider: require('./insurance_provider.svg'),
	warningFilled: require('./insurance_warning.svg'),
	insurer: require('./insurer.svg'),
	landingPageCar: require('./landing_page_car.svg'),
	locationBuild: require('./location_build.svg'),
	location: require('./location.svg'),
	locationPin: require('./location_pin.svg'),
	locationPinOutlined: require('./location_pin_outlined.svg'),
	lock: require('./lock.svg'),
	login: require('./login.svg'),
	loginBg: require('./login_bg.svg'),
	logoBima: require('./logo_bima.svg'),
	makasib: require('./makasib.svg'),
	makasibFilled: require('./makasib_filled.svg'),
	mastercard: require('./mastercard.svg'),
	messages: require('./messages.svg'),
	messengerCircle: require('./messenger_circle.svg'),
	msgInfo: require('./msg_info.svg'),
	msgInfoBackground: require('./msg_info_background.svg'),
	minus: require('./minus.svg'),
	more: require('./more.svg'),
	notifyBell: require('./notify_bell.svg'),
	offerRight: require('./offer_right.svg'),
	omantelLogo: require('./omantel_logo.svg'),
	order: require('./order.svg'),
	outlet: require('./outlet.svg'),
	package: require('./package.svg'),
	omantelMapPoint: require('./omantel_map_point.svg'),
	outletLocation: require('./outlet_location.svg'),
	outOfStock: require('./out_of_stock.svg'),
	paymentMethod: require('./payment_method.svg'),
	person: require('./person.svg'),
	personalInfo: require('./personal_info.svg'),
	plus: require('./plus.svg'),
	preorder_en: require('./preorder_en.svg'),
	preorder_ar: require('./preorder_ar.svg'),
	promotion: require('./promotion.svg'),
	promotion2: require('./promotion2.svg'),
	promotionDisabled: require('./promotion_disabled.svg'),
	promotionSmall: require('./promotion_small.svg'),
	promotionSmallDisabled: require('./promotion_small_disabled.svg'),
	protection: require('./protection.svg'),
	quotes: require('./quotes.svg'),
	quotesActive: require('./quotes_active.svg'),
	recommendationLaptop: require('./recommendation_laptop.svg'),
	recommendationPhone: require('./recommendation_phone.svg'),
	recommendationSim: require('./recommendation_sim.svg'),
	recommendationWiFi: require('./recommendation_wifi.svg'),
	resident: require('./resident.svg'),
	roaming: require('./roaming.svg'),
	scaleActive: require('./scale_active.svg'),
	scaleInactive: require('./scale_inactive.svg'),
	searchEmptyState: require('./search_empty_state.svg'),
	success: require('./success.svg'),
	starFilled: require('./star_filled.svg'),
	starOutline: require('./star_outline.svg'),
	subscriptionAR: require('./subscription_ar.svg'),
	subscriptionEN: require('./subscription_en.svg'),
	search: require('./search.svg'),
	searchBig: require('./searchBig.svg'),
	shield: require('./shield.svg'),
	shipping: require('./shipping.svg'),
	specialOffer: require('./special_offer.svg'),
	stepInsurer: require('./step_insurer.svg'),
	stepVehicle: require('./step_vehicle.svg'),
	stepDrivers: require('./step_drivers.svg'),
	stepQuotes: require('./step_quotes.svg'),
	summary: require('./summary.svg'),
	summaryActive: require('./summary_active.svg'),
	termsAndConditions: require('./terms_and_conditions.svg'),
	playArrowIcon: require('./play_arrow_icon.svg'),
	transferSim: require('./transfer_sim.svg'),
	twitterCircle: require('./twitter_circle.svg'),
	unlimited: require('./unlimited.svg'),
	vehicle: require('./vehicle.svg'),
	vehicleDetails: require('./vehicle_details.svg'),
	vehicleDetailsActive: require('./vehicle_details_active.svg'),
	vehicleValue: require('./vehicle_value.svg'),
	viewGRID: require('./view_grid.svg'),
	viewLIST: require('./view_list.svg'),
	visa: require('./visa.svg'),
	waiting: require('./waiting.svg'),
	warning: require('./warning.svg'),
	voice: require('./voice.svg'),
	whatsappCircle: require('./whatsapp_circle.svg'),
	wifiProduct: require('./wifi_product.svg'),
	youtubeCircle: require('./youtube_circle.svg'),
	checkCircle: require('./check_circle.svg'),
	instagramSquare: require('./ic24_fct_Instagram.svg'),
	whatsappSquare: require('./ic24_fct_WhatsApp.svg'),
	snapchatSquare: require('./ic24_fct_Snapchat.svg'),
	twitterSquare: require('./ic24_fct_Twitter.svg'),
	facebookSquare: require('./ic24_fct_Facebook.svg'),
	deliveryDate: require('./ic_ill_DeliveryDate.svg'),
	warningRedFilled: require('./warning_red_filled.svg'),
	eyeOpen: require('./eye_open.svg'),
	eyeClosed: require('./eye_closed.svg'),
	arrowRightBox: require('./arrowRightBox.svg'),
	arrowLeftBox: require('./arrowLeftBox.svg'),
	phantomUser: require('./phantom_user.svg'),
	searchFromPortal: require('./searchFromPortal.svg'),
	bag: require('./bag.svg'),
	payBill: require('./payBill.svg'),
	topUp: require('./topUp.svg'),
	notLoggedPerson: require('./notLoggedPerson.svg'),
	shop: require('./shop.svg'),
	sms: require('./sms.svg'),
	warningTwo: require('./warningTwo.svg'),
	home: require('./home.svg'),
	phone: require('./phone.svg'),
	facebook: require('./facebook.svg'),
	instagram: require('./instagram.svg'),
	twitter: require('./twitter.svg'),
	whatsApp: require('./whatsApp.svg'),
	youtube: require('./youtube.svg'),
	omantelLogoTransparent: require('./omantelLogoTransparent.svg'),
	eVoucher: require('./e_voucher.svg'),
	makasibPoints: require('./makasib_points.svg'),
	equal: require('./equal.svg'),
	questionMarkCircle: require('./question_mark_circle.svg'),
	american_express: require('./american_express.svg'),
	apple_pay: require('./applePay.svg'),
};
