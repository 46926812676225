import React, { FunctionComponent, useEffect, useState } from 'react';
import { IPaymentToken } from '@ApiModels/paymentTokens';
import {
	RadioWrapper,
	AddCardFormWrapper,
	HeaderDescriptionWrapper,
	HeaderTitle,
	HeaderWrapper,
	ContentWrapper,
	LabelWrapper,
} from './SavedPaymentTokens.styled';
import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import RadioButton from '@Components/controls/RadioButton/RadioButton';
import { Col, Row, useScreenClass } from 'react-grid-system';
import AddCardForm from '@Components/Card/cards/PaymentData/subcomponents/AddCardForm/AddCardForm';
import PaymentTokenItem from '@Components/PaymentTokenItem/PaymentTokenItem';
import { ICheckout as ICheckoutAction } from '@Redux/modules/checkout/actions';
import { ProfileService } from '@Services/profile/profileService';
import { useSelector } from 'react-redux';
import { IconWrapper } from '@Components/PlanBar/PlanBar.styled';
import { getLang } from '@Utils/language/language';

interface ISavedPaymentTokens {
	paymentTokens: IPaymentToken[];
	setPaymentData: (data: ICheckoutAction['payload']['paymentData'] | undefined) => void;
	selectedPaymentData: ICheckoutAction['payload']['paymentData'];
}

const SavedPaymentTokens: FunctionComponent<ISavedPaymentTokens> = ({
	paymentTokens,
	setPaymentData,
	selectedPaymentData,
}) => {
	const { translate } = useTranslate();
	const [activeRadio, setActiveRadio] = useState(1);
	const [userIsLoggedIn, setUserIsLoggedIn] = useState<boolean>(false);
	const telesales = useSelector((state: types.redux.IState) => state.api.authentication.telesales);
	const screenClass = useScreenClass();

	const prepaidProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.prepaidProcessCheckout
	);
	const isApplePayAllow = useSelector((state: types.redux.IState) => state.api.authentication.isApplePayAllow);

	useEffect(() => {
		if (isApplePayAllow) {
			setActiveRadio(0); // to auto select the apple pay if Apple Pay option is enabled.
			setPaymentData({ paymentMethod: 'applePay' });
		} else if (paymentTokens.length <= 0) {
			setActiveRadio(2); // if there is no saved card then Add new card radio button will be auto selected
			setPaymentData({ card: { cardNumber: '', name: '', expire: '', cvv: '', savePaymentToken: false } });
		}
		ProfileService.getUserProfile(false, telesales).subscribe(
			() => {
				setUserIsLoggedIn(true);
			},
			() => {
				setUserIsLoggedIn(false);
			}
		);
	}, []);

	const handleOnChange = (token: IPaymentToken) => {
		setPaymentData({ token });
		setActiveRadio(1);
	};

	const handleRadioButtonClick = (val: number) => {
		if (val === 0) {
			setPaymentData({ paymentMethod: 'applePay' });
		} else if (val === 1) {
			setPaymentData({ token: paymentTokens[0] });
		} else {
			setPaymentData({ card: { cardNumber: '', name: '', expire: '', cvv: '', savePaymentToken: false } });
		}
		setActiveRadio(val);
	};

	return (
		<>
			<Col lg={12}>
				{isApplePayAllow && (
					<RadioWrapper>
						<RadioButton
							label={
								<LabelWrapper>
									<IconWrapper
										style={
											getLang() === 'en'
												? { marginLeft: '25px', marginRight: '8px' }
												: { marginLeft: '8px', marginRight: '25px' }
										}
									>
										<Icon name="apple_pay" width={37} height={36} />
									</IconWrapper>

									{translate('secure-checkout.payment-appple-pay')}
								</LabelWrapper>
							}
							selected={activeRadio}
							name="payment-method"
							onClick={() => handleRadioButtonClick(0)}
							value={0}
						/>
					</RadioWrapper>
				)}
				<ConditionalRender
					show={!!paymentTokens.length}
					onTrue={() => (
						<RadioWrapper>
							<RadioButton
								label={translate('secure-checkout.payment-tokens.select-from-saved-cards')}
								selected={activeRadio}
								name="payment-method"
								onClick={() => handleRadioButtonClick(1)}
								value={1}
							/>
						</RadioWrapper>
					)}
				/>
				<ConditionalRender
					show={!!paymentTokens.length}
					onTrue={() => (
						<ConditionalRender
							show={activeRadio === 1}
							onTrue={() => (
								<>
									{paymentTokens.map((token) => (
										<PaymentTokenItem
											key={token.id}
											token={token}
											active={
												selectedPaymentData?.token && selectedPaymentData.token.id === token.id
											}
											showDelete={paymentTokens.length > 1}
											showSelect={paymentTokens.length > 1}
											handleOnChange={() => handleOnChange(token)}
											prepaidProcessCheckout={!!prepaidProcessCheckout}
										/>
									))}
								</>
							)}
						/>
					)}
				/>
				<RadioWrapper>
					<RadioButton
						label={
							!!paymentTokens.length
								? translate('secure-checkout.payment-tokens.use-different-card')
								: translate('secure-checkout.payment-tokens.add-new-card')
						}
						selected={activeRadio}
						name="payment-method"
						onClick={() => handleRadioButtonClick(2)}
						value={2}
					/>
				</RadioWrapper>
				<ConditionalRender
					show={activeRadio === 2}
					onTrue={() => (
						<AddCardFormWrapper>
							<HeaderWrapper>
								<Icon name="cardAdd" fill="black38" width={40} height={40} />
								<HeaderDescriptionWrapper>
									<HeaderTitle>{translate('secure-checkout.add-card')}</HeaderTitle>
								</HeaderDescriptionWrapper>
							</HeaderWrapper>
							<ContentWrapper isSmallScreen={screenClass.includes('xs') || screenClass.includes('sm')}>
								<Row>
									<AddCardForm
										setPaymentData={setPaymentData}
										selectedPaymentData={selectedPaymentData}
										displaySaveCard={userIsLoggedIn}
									/>
								</Row>
							</ContentWrapper>
						</AddCardFormWrapper>
					)}
				/>
			</Col>
		</>
	);
};

export default SavedPaymentTokens;
