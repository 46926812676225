import React, { FunctionComponent, useEffect, useMemo } from 'react';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';
import {
	ButtonsContainer,
	ButtonUnderline,
	TitleColumn,
	TitleContainer,
	TitleWrapper,
	Container,
	Button,
	VatWrapper,
	Vat,
} from './GetInsurance.styled';
import PriceTag from '@Components/PriceTag/PriceTag';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { IProductsByPartNumber } from '@ApiModels/productsByPartNumber';
import { useDeviceInsurance } from '@Utils/context/DeviceInsuranceContext';
import useScreen from '@Utils/hooks/useScreen';
import { useCart } from '@Utils/context/CartContext';
import { PaymentMethod } from '@Components/Modal/modals/AdditionalProduct/AdditionalProduct';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { disableBNPLInsurance } from '@Config/features.config';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';
import { useSelector } from 'react-redux';

export const EXCLUDE_CART_ITEMS = [
	'DEVICE_INSURANCE_36',
	'DEVICE_INSURANCE_72',
	'DEVICE_INSURANCE_00',
	'DEVICE_INSURANCE_02',
	'Insurance 12 months',
	'Insurance 24 months',
	'DEVICE_INSU_12M_00',
	'DEVICE_INSU_24M_36',
];

export const INSURANCE_PROMOTION_CART_ITEMS = ['DEVICE_INSU_12M_00', 'DEVICE_INSU_24M_36'];
export const ADD_INSURANCE_BI = 'ADD_INSURANCE_BI';
export const REMOVE_INSURANCE_BI = 'REMOVE_INSURANCE_BI';
const NONE = 'NONE';
interface IGetInsurance {
	partNumber: string;
	hideButtons: boolean;
	isOrderHistoryView?: boolean;
	summaryView?: boolean;
	insurancePromotion?: boolean;
	purchasedInsurance?: IProductsByPartNumber;
	installmentType?: string;
}

const ICON_SIZE = 48;
export const GET_PRICE_BY_CAT_ENTRY_INSURANCE_BI = 'GET_PRICE_BY_CAT_ENTRY_INSURANCE_BI';
export const DEVICE_INSURANCE_PARTNUMBER = ['DEVICE_INSURANCE_00', 'DEVICE_INSURANCE_02'];

const GetInsurance: FunctionComponent<IGetInsurance> = ({
	partNumber,
	hideButtons = false,
	isOrderHistoryView = false,
	summaryView = false,
	purchasedInsurance,
	insurancePromotion,
	installmentType,
}) => {
	const { translate } = useTranslate();
	const {
		handleChangePeriod,
		onRemove,
		openModal,
		getInsurance,
		activeInsuranceInCart,
		activeInsuranceDetails,
		priceFromCatEntry,
		setPaymentMethod,
	} = useDeviceInsurance();
	const { isBuyNowPayLaterFlow, isBuyNowPayLaterInCart } = useBuyNowPayLater();
	const remoteFeatureConfig = useSelector(remoteConfigSelector);
	// this kill switch flag to disable insurance call with product incase of BNPL
	const isDisableBNPLInsurance = disableBNPLInsurance(remoteFeatureConfig);

	const { cart } = useCart();

	const { screen } = useScreen();

	useEffect(() => {
		setPaymentMethod(installmentType ? PaymentMethod.INSTALLMENTS_PAYMENT : PaymentMethod.UPFRONT_PAYMENT);
	}, [installmentType]);

	useEffect(() => {
		const fetchInsurance = async () => {
			await getInsurance(partNumber, insurancePromotion);
		};
		fetchInsurance().catch(() => {});
	}, [partNumber]);

	const isInsuranceInstallment = useMemo(() => {
		return cart.items.some((item) => DEVICE_INSURANCE_PARTNUMBER.includes(item.partNumber));
	}, [cart.items]);

	return (
		<ConditionalRender
			show={
				(isDisableBNPLInsurance &&
					!isBuyNowPayLaterFlow &&
					!isBuyNowPayLaterInCart &&
					(!!purchasedInsurance || (!isOrderHistoryView && !summaryView))) ||
				(!isDisableBNPLInsurance && (!!purchasedInsurance || (!isOrderHistoryView && !summaryView)))
			}
			onTrue={() => (
				<Container isXs={screen(['xs'])}>
					<>
						<TitleContainer isXs={screen(['xs'])}>
							<Icon name="protection" width={ICON_SIZE} height={ICON_SIZE} fill="black38" />
							<TitleColumn isXs={screen(['xs'])}>
								<TitleWrapper isXs={screen(['xs'])}>
									{activeInsuranceInCart
										? translate(
												'get.insurance.device.will.be.protected',
												isInsuranceInstallment
													? activeInsuranceDetails?.installmentPeriod || ''
													: activeInsuranceDetails?.duration || ''
										  )
										: purchasedInsurance
										? translate(
												'order.history.purchased.insurandce',
												String(purchasedInsurance.duration || '')
										  )
										: translate(
												'cart.item.insurance.title',
												cart.items.some((item) => item.plan?.planDetails?.planId !== NONE)
													? translate('cart.item.insurance.title-instalments')
													: ''
										  )}
								</TitleWrapper>
								<ConditionalRender
									show={!!activeInsuranceInCart && screen('xs')}
									onTrue={() => (
										<ConditionalRender
											show={!purchasedInsurance || !isOrderHistoryView}
											onTrue={() => (
												<VatWrapper isXs={screen(['xs'])}>
													<BusyIndicator
														listener={[GET_PRICE_BY_CAT_ENTRY_INSURANCE_BI]}
														skeleton="displayMBold"
													>
														<>
															<PriceTag
																size="mini"
																duration={
																	isInsuranceInstallment
																		? translate(
																				'select-protection-modal.payment.mo'
																		  )
																		: ''
																}
																durationColor="black54"
																price={Number(priceFromCatEntry?.offerPrice || '0')}
															/>

															<Vat>
																{translate(
																	'select-protection-modal.payment.vat',
																	priceFromCatEntry?.vatPercent ?? ''
																)}
															</Vat>
														</>
													</BusyIndicator>
												</VatWrapper>
											)}
										/>
									)}
								/>
								<ConditionalRender
									show={!!activeInsuranceInCart && !hideButtons}
									onTrue={() => (
										<ButtonsContainer isXs={screen(['xs'])}>
											<BusyIndicator listener={[REMOVE_INSURANCE_BI, ADD_INSURANCE_BI]} size={16}>
												<>
													<ConditionalRender
														show={!isInsuranceInstallment}
														onTrue={() => (
															<ButtonUnderline
																onClick={() =>
																	handleChangePeriod(
																		activeInsuranceDetails?.duration === '12'
																			? '24'
																			: '12'
																	)
																}
															>
																{translate(
																	'get.insurance.open.modal',
																	activeInsuranceDetails?.duration === '12'
																		? '24'
																		: '12'
																)}
															</ButtonUnderline>
														)}
													/>
													<ButtonUnderline onClick={onRemove}>
														{translate('get.insurance.remove.button')}
													</ButtonUnderline>
												</>
											</BusyIndicator>
										</ButtonsContainer>
									)}
								/>
							</TitleColumn>
						</TitleContainer>

						<ConditionalRender
							show={!!activeInsuranceInCart}
							onTrue={() => (
								<ConditionalRender
									show={(!purchasedInsurance || !isOrderHistoryView) && !screen('xs')}
									onTrue={() => (
										<VatWrapper>
											<BusyIndicator
												listener={[GET_PRICE_BY_CAT_ENTRY_INSURANCE_BI]}
												skeleton="displayMBold"
											>
												<>
													<PriceTag
														duration={
															isInsuranceInstallment
																? translate('select-protection-modal.payment.mo')
																: ''
														}
														durationColor="black54"
														size="mini"
														price={
															isInsuranceInstallment
																? Number(
																		activeInsuranceDetails?.installmentValue || '0'
																  )
																: Number(priceFromCatEntry?.offerPrice || '0')
														}
													/>

													<Vat>
														{translate(
															'select-protection-modal.payment.vat',
															priceFromCatEntry?.vatPercent ?? ''
														)}
													</Vat>
												</>
											</BusyIndicator>
										</VatWrapper>
									)}
								/>
							)}
							onFalse={() => (
								<ConditionalRender
									show={!purchasedInsurance || !isOrderHistoryView}
									onTrue={() => (
										<Button
											onClick={openModal}
											uniqueId="get.insurance.select-plan"
											underlayColor="grey100"
										>
											{translate('get.insurance.select-plan')}
										</Button>
									)}
								/>
							)}
						/>
					</>
				</Container>
			)}
		/>
	);
};

export default GetInsurance;
