import React, { FunctionComponent, useState, useEffect } from 'react';
import NavigationBar from '@Components/NavigationBar/NavigationBar';
import { PaymentWrapper } from './Payment.styled';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Footer from '@Components/Footer/Footer';
import CartMobile from '@Components/CartMobile/CartMobile';
import Divider from '@Components/Divider/Divider';
import Card from '@Components/Card/Card';
import { CheckoutSummaryType, MS_PRODUCT_QUERY_PARAM } from '@Components/Card/cards/CheckoutSummary/CheckoutSummary';
import { Container } from 'react-grid-system';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { IOrderDetails, IOrderDetailsResponse } from '@ApiModels/orderDetails';
import { dispatch } from '@Redux/store';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { GET_ORDER_HISTORY_DETAILS_BI } from '@Components/Card/cards/OrderHistory/OrderItem/OrderItem';
import { PaymentService } from '@Services/payment/payment';
import { clearCheckoutDetails, ICheckout as ICheckoutAction } from '@Redux/modules/checkout/actions';
import useCommerceProfile from '@Utils/hooks/useCommerceProfile';
import moment from '@Utils/date/moment';
import { setCheckoutTimer } from '@Redux/modules/settings/settingsCheckout/actions';
import ReactGA from 'react-ga';
import { getLang } from '@Utils/language/language';
import { clearPersistentCheckoutDetails } from '@Redux/modules/persistentCheckoutData/actions';
import { useSelector } from 'react-redux';
import getHbbWfbbPayload from '@Utils/converters/getHbbWfbbPayload';
import TagManager from 'react-gtm-module';
import { isLargeScreen, matchScreen } from '@Utils/layout/layoutSupport';
import { EBU_DOMAIN } from '@Config/app.config';
import { getLoginType } from '@Redux/modules/api/authentication/selectors';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { paymentRedirection } from '@Utils/payment/payment';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';

export const NAVBAR_HEIGHT = 100;
export const NAVBAR_HEIGHT_WITH_PADDING = NAVBAR_HEIGHT + 80;
export const SET_RETRY_ORDER_BI = 'SET_RETRY_ORDER_BI';
const TAG_MANAGER_PAYMENT_SUCCESSFUL = 'Payment successful';

const Payment: FunctionComponent = () => {
	const { search } = useLocation();
	const [footerHeight, setFooterHeight] = useState<number>(0);
	const [cartHeight, setCartHeight] = useState<number>(0);
	const query = new URLSearchParams(search);
	const [showPayAgain, setShowPayAgain] = useState<boolean>(false);
	const [paymentData, setPaymentData] = useState<ICheckoutAction['payload']['paymentData']>({});
	const [order, setOrder] = useState<IOrderDetails>();
	const [showTryAgainButton, setShowTryAgainButton] = useState<boolean>(true);
	const { contactDetails, billingAddress } = useCommerceProfile();
	const hbbWfbbProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.hbbWfbbProcessCheckout
	);
	const history = useHistory();
	const location = useLocation();
	const isSmallScreen = matchScreen(['xs', 'sm']);
	const { isBuyNowPayLaterInCart } = useBuyNowPayLater();
	const pathname = window.location.href.toLocaleLowerCase();
	const isApplePayAllow = useSelector((state: types.redux.IState) => state.api.authentication.isApplePayAllow);
	const orderNumber = () => {
		return query.get('orderId');
	};
	const getRequestNumber = () => {
		return query.get('hbbwfbboffer');
	};
	const getGiftcardsParam = () => {
		return query.get('giftcards');
	};
	const getDigitalProdParams = () => {
		return query.get('digitalproducts');
	};

	const remoteFeatureConfig = useSelector(remoteConfigSelector);

	const retryHbbWfbbOrder =
		!!hbbWfbbProcessCheckout?.latitude &&
		!!hbbWfbbProcessCheckout?.region &&
		!!hbbWfbbProcessCheckout?.documentScan?.base64backSide?.base64File; // if redux data is still set ( is not undefined ) it means we should retry handleSetOrderAgain function after payment success

	const isWfbbProcess = !!hbbWfbbProcessCheckout?.wfbbType;

	const handleUpdatePaymentData = (paymentData: ICheckoutAction['payload']['paymentData']) => {
		setPaymentData(paymentData);
	};

	useEffect(() => {
		const paymentSuccess = location.pathname.includes('payment/success');
		if (paymentSuccess)
			TagManager.dataLayer({
				dataLayer: {
					event: 'transactionView',
					products: JSON.parse(sessionStorage.getItem('products') || '[]'),
					transactionId: String(orderNumber()),
					userId: getUserId(),
				},
			});
		const isRequestId = location.pathname.includes('hbbwfbboffer');
		if (paymentSuccess && !isRequestId && retryHbbWfbbOrder) {
			setTimeout(handleSetOrderAgain, 5000);
		}
	}, [location.pathname, retryHbbWfbbOrder]);

	useEffect(() => {
		ReactGA.set({ page: location.pathname + location.search });
		ReactGA.pageview(location.pathname + location.search);
	}, [location.pathname]);

	useEffect(() => {
		if (orderNumber() !== 'null') {
			dispatch(addBusyIndicator(GET_ORDER_HISTORY_DETAILS_BI));
			OrderFlowService.getOrderDetails(String(orderNumber())).subscribe(
				(orderDetails: IOrderDetailsResponse) => {
					setOrder(orderDetails.response);
					TagManager.dataLayer({
						dataLayer: {
							Amount_paid: orderDetails.response?.grandTotal,
							Count_of_Items_in_the_cart: orderDetails.response?.orderItem?.length,
							event: TAG_MANAGER_PAYMENT_SUCCESSFUL,
							userId: getUserId(),
						},
					});
					dispatch(removeBusyIndicator(GET_ORDER_HISTORY_DETAILS_BI));
				},
				() => {
					setOrder(undefined);
					dispatch(removeBusyIndicator(GET_ORDER_HISTORY_DETAILS_BI));
				}
			);
		}
	}, []);

	const eshopIsLoadedInIframe = () => {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	};

	const ebuMessageHandler = (event: MessageEvent) => {
		if (EBU_DOMAIN.includes(event.origin)) {
			window.parent.postMessage({ url: window.location.href }, event.origin);
		}
	};

	useEffect(() => {
		if (eshopIsLoadedInIframe() && getLoginType() === 'user') {
			window.addEventListener('message', ebuMessageHandler);
			return () => window.removeEventListener('message', ebuMessageHandler);
		}
	}, []);

	const handlePayment = () => {
		dispatch(setCheckoutTimer({ checkoutTimer: undefined }));
		if (order?.orderItem?.length) {
			const orderContactDetails = {
				city: order.orderItem[0].city,
				country: 'OM',
				email: order.orderItem[0].email1 ?? '',
				firstName: order.orderItem[0].firstName ?? '',
				lastName: order.orderItem[0].lastName ?? '',
				title: '',
				middleName: '',
				phoneNumber: order.orderItem[0].phone1 ?? '',
				street1: Array.isArray(order.orderItem[0].addressLine)
					? order.orderItem[0].addressLine[0]
					: order.orderItem[0].addressLine,
			};

			const makePaymentProps: types.payment.IMakePayment = {
				contactDetails: orderContactDetails.city ? orderContactDetails : contactDetails,
				address: billingAddress,
				orderId: String(orderNumber()),
				paymentData,
				totalPrice: Number(order?.grandTotal),
				flowType:
					paymentData?.paymentMethod === 'applePay' && isApplePayAllow ? 'APPLEPAY' : 'OMANNET_PREFFERRED',
			};

			PaymentService.makePayment({ makePaymentProps, origin: 'cart' }).subscribe(
				() => {
					setShowPayAgain(false);
					dispatch(clearCheckoutDetails());

					let _giftCard = false;
					let _msProduct = false;
					if (order && order.orderItem && order.orderItem?.length > 0) {
						order.orderItem.forEach((_item) => {
							if (_item.xitem_name?.toLowerCase().includes('gift')) {
								_giftCard = true;
							} else if (_item.shipModeCode === 'MAIL') {
								_msProduct = true;
							}
						});
					}
					if (retryHbbWfbbOrder) {
						setTimeout(handleSetOrderAgain, 5000);
					} else {
						dispatch(clearPersistentCheckoutDetails());
						if (_giftCard) {
							history.push(
								`/${getLang()}/payment/success?orderId=${String(orderNumber())}&giftcards=true`
							);
						} else {
							history.push(
								`/${getLang()}/payment/success?orderId=${String(orderNumber())}${
									_msProduct ? `&${MS_PRODUCT_QUERY_PARAM}=true` : ''
								}`
							);
						}
					}
				},
				(error) => {
					dispatch(setCheckoutTimer({ checkoutTimer: moment().unix() }));
					switch (error.response.code) {
						case 'PAYER_AUTHENTICATION_REQUIRED':
							setShowPayAgain(false);
							setShowTryAgainButton(true);

							paymentRedirection(error?.response?.url);
							break;

						case 'TRANSACTION_REJECTED':
							setShowPayAgain(false);
							setShowTryAgainButton(true);
							history.push(`/${getLang()}/payment/failure?orderId=${String(orderNumber())}`);
							break;

						case 'ORDER_ALREADY_PLACED_BEFORE':
						case 'INVALID_CARD_DATA':
							setShowPayAgain(false);
							setShowTryAgainButton(true);
							history.push(
								`/${getLang()}/payment/failure?orderId=${String(orderNumber())}&error=${
									error.response.code
								}`
							);
							break;
						case 'VOUCHER_IS_NOT_RESERVED_BY_USER':
							setShowPayAgain(false);
							setShowTryAgainButton(false);
							history.push(`/${getLang()}/payment/failure?orderId=${String(orderNumber())}`);
							break;
						default:
							setShowPayAgain(false);
							setShowTryAgainButton(true);
							history.push(
								`/${getLang()}/payment/failure?orderId=${String(
									orderNumber()
								)}&error=GENERIC_PRE_VALIDATION_ERROR`
							);
							break;
					}
				}
			);
		}
	};

	const handleSetOrderAgain = () => {
		dispatch(addBusyIndicator(SET_RETRY_ORDER_BI));
		const allParams = {
			...(hbbWfbbProcessCheckout || {}),
			orderId: hbbWfbbProcessCheckout?.orderId || String(orderNumber()),
		};
		const giftcard = getGiftcardsParam();
		const digitalProducts = getDigitalProdParams();
		const payload = getHbbWfbbPayload(allParams, isWfbbProcess);

		OrderFlowService.setHbbWfbbOrder(payload, isWfbbProcess).subscribe(
			(response) => {
				dispatch(clearPersistentCheckoutDetails());
				dispatch(removeBusyIndicator(SET_RETRY_ORDER_BI));
				const requestNumber = response.requestId || response.internalRequestId;
				if (requestNumber) {
					history.push(
						`/${getLang()}/payment/success?orderId=${String(orderNumber())}${
							requestNumber ? `&hbbwfbboffer=${requestNumber}` : ''
						}&giftcards=${giftcard ? giftcard : ''}&digitalproducts=${
							digitalProducts ? digitalProducts : ''
						}`
					);
				} else
					history.push(
						`/${getLang()}/payment/failure/hbbwfbb?orderId=${String(orderNumber())}${
							giftcard ? '&giftcards=' + giftcard : ''
						}${digitalProducts ? '&digitalproducts=' + digitalProducts : ''}`
					);
			},
			() => {
				dispatch(removeBusyIndicator(SET_RETRY_ORDER_BI));
				history.push(
					`/${getLang()}/payment/failure/hbbwfbb?orderId=${String(orderNumber())}${
						giftcard ? '&giftcards=' + giftcard : ''
					}${digitalProducts ? '&digitalproducts=' + digitalProducts : ''}`
				);
			}
		);
	};

	return (
		<PaymentWrapper footerHeight={footerHeight + cartHeight}>
			<NavigationBar />
			<Divider marginBottom={NAVBAR_HEIGHT_WITH_PADDING - (isLargeScreen() ? 0 : isSmallScreen ? 56 : 12)} />
			<Container>
				<Switch>
					<Route path={['/payment/success/submissionfailed', '/:lang/payment/success/submissionfailed']}>
						<Card<types.cards.ICheckoutSummaryCardProps>
							card="checkoutSummary"
							params={{
								type: CheckoutSummaryType.SUBMISSION_FAILED,
								orderNumber: orderNumber() ?? '',
								requestNumber: getRequestNumber() ?? '',
								order,
							}}
						/>
					</Route>
					<Route path={['/payment/cancel', '/:lang/payment/cancel']}>
						<Card<types.cards.ICheckoutSummaryCardProps>
							card="checkoutSummary"
							params={{
								type: CheckoutSummaryType.CANCEL,
								orderNumber: orderNumber() ?? '',
								requestNumber: getRequestNumber() ?? '',
								order,
							}}
						/>
					</Route>
					<Route path={['/payment/success', '/:lang/payment/success']}>
						<Card<types.cards.ICheckoutSummaryCardProps>
							card="checkoutSummary"
							params={{
								type: CheckoutSummaryType.SUCCESS,
								orderNumber: orderNumber() ?? '',
								requestNumber: getRequestNumber() ?? '',
								order,
							}}
						/>

						<ConditionalRender
							show={pathname.includes('&bnpl=true')}
							onTrue={() => (
								<>
									<Divider color="transparent" marginBottom={24} />
									<Card card="paymentBNPLInfo" />
								</>
							)}
						/>
						<Divider
							color="transparent"
							marginBottom={NAVBAR_HEIGHT - (isLargeScreen() ? 0 : isSmallScreen ? 56 : 12)}
						/>
					</Route>
					<Route path={['/payment/failure/hbbwfbb', '/:lang/payment/failure/hbbwfbb']}>
						<Card<types.cards.ICheckoutSummaryCardProps>
							card="checkoutSummary"
							params={{
								showTryAgainButton,
								type: CheckoutSummaryType.REJECTED,
								tryAgain: () => {
									setTimeout(handleSetOrderAgain, 5000);
								},
								hbbWfbbProcess: true,
							}}
						/>
					</Route>
					<Route path={['/payment/failure', '/:lang/payment/failure']}>
						<Card<types.cards.ICheckoutSummaryCardProps>
							card="checkoutSummary"
							params={{
								showTryAgainButton,
								type: CheckoutSummaryType.REJECTED,
								tryAgain: () => {
									if (!order) {
										history.push(`/${getLang()}/checkout/1`);
										return;
									}
									dispatch(setCheckoutTimer({ checkoutTimer: undefined }));

									setShowPayAgain(true);
								},
							}}
							onEvent={(event) => {
								event === 'setButtonVisibility' && setShowTryAgainButton(false);
							}}
						/>
						{showPayAgain && !!order ? (
							<>
								<Divider color="transparent" marginBottom={16} />
								<Card<types.cards.ICardProps>
									card="paymentData"
									withoutContainerPadding
									onEvent={(event, data: ICheckoutAction['payload']['paymentData']) => {
										event === 'updatePaymentData' && handleUpdatePaymentData(data);
									}}
								/>
								<Divider color="transparent" marginBottom={16} />
								<Card<types.cards.ICheckoutSummaryTryAgainCardProps>
									card="checkoutSummaryTryAgain"
									params={{
										pay: () => {
											handlePayment();
										},
									}}
								/>
							</>
						) : (
							''
						)}
						<Divider color="transparent" marginBottom={80} />
					</Route>
				</Switch>
			</Container>
			<Footer setFooterHeight={setFooterHeight} />
			<CartMobile />
		</PaymentWrapper>
	);
};

export default Payment;
