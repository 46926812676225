import { STORE_ID } from '@Config/app.config';
import * as categoriesEndpointsGenerators from './endpointsGenerators/omantelShop/categories/categoriesEndpointsGenerators';

import * as productsEndpointsGenerators from './endpointsGenerators/products/productsEndpointsGenerators';

import * as inventoryEndpointGenerators from './endpointsGenerators/inventory/inventoryEndpointsGenerators';

import * as typeaheadEndpointGenerators from './endpointsGenerators/typeahead/typeaheadEndpointsGenerators';

import * as espotEndpointGenerators from './endpointsGenerators/espot/espotEndpointsGenerators';

import * as orderFlowEndpointGenerators from './endpointsGenerators/orderFlow/orderFlowEndpointsGenerators';

import * as addressesEndpointsGenerators from './endpointsGenerators/adresses/addressesEndpointsGenerators';

import * as eCommerceEndpointsGenerators from './endpointsGenerators/ecommerce/services/eCommerceEndpointsGenerators';

import * as profileEndpointsGenerators from './endpointsGenerators/omantelShop/profile/profileEndpointsGenerator';

import * as carInsuranceGenerators from './endpointsGenerators/insurance/insuranceEndpointsGenerators';

import * as remoteConfigEndpointsGenerators from './endpointsGenerators/remoteConfig/remoteConfigEndpointsGenerators';

import * as omantelPortalEndpointsGenerators from './endpointsGenerators/omantelPortal/omantelPortalEndpointsGenerators';

import * as enhancedTermAndConditionEndpointsGenerators from './endpointsGenerators/enhancedTermAndCondition/enhancedTermAndConditionEndpointsGenerators';

import * as configEndpointsGenerators from './endpointsGenerators/config/configEndpointsGenerator';

import * as buyNowPayLaterEndpointsGenerator from './endpointsGenerators/buyNowPayLater/buyNowPayLaterEndpointsGenerator';

export const endpoints: types.endpoints.IList = {
	eCommerce: {
		...eCommerceEndpointsGenerators,
	},
	omantelApi: {
		getAccountsForLoggedUser: () => ({
			method: 'GET',
			url: 'OmantelApi/webengine/Accounts/Action!getAccountsForLoggedUserRest',
		}),
		getCustomerAccountOperation: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/CustomerManagment/Action!getCustomerAccountOperationRestPost',
		}),
		postUserPersonalInfo: () => ({
			method: 'POST',
			url: 'external-logging-service/v1/log',
		}),
		login: () => ({
			method: 'POST',
			url:
				process.env.REACT_APP_ENV === undefined || process.env.REACT_APP_ENV === 'TST'
					? 'ttrauth/secureauth'
					: 'auth/secureauth',
		}),
		logout: () => ({
			method: 'POST',
			url: 'auth/logout',
		}),
		// UserManagement
		getFullUserProfileForCurrentUser: () => ({
			method: 'GET',
			url: 'OmantelApi/webengine/UserManagement/Action!GetFullUserProfileForCurrentUserOperationRestPost',
		}),
		getEbuUserProfileForCurrentUser: () => ({
			method: 'GET',
			url: 'OmantelApi/webengine/CorpoMasterChild/Action!getCurrentUserDetailsRest',
		}),
		getPaymentTokensForCurrentUser: () => ({
			url: 'OmantelApi/webengine/CybersourcePayments/Action!GetPaymentTokensForCurrentUserOperationRestPost',
		}),
		removePaymentToken: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/CybersourcePayments/Action!RemovePaymentTokenByIfForCurrentUserRestPost',
		}),
		// Plans
		getRecommendedPlan: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ProductInventory/Action!getRecommendedPlanRestPost',
		}),
		getCustomerProducts: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ProductConfiguration/Action!getCustomerProductsRestPost',
		}),
		makePayment: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/CybersourcePayments/Action!MakePaymentOperationRestPost',
		}),
		paymentSite: () => ({
			url: 'CybersourcePayments/Action!GeneratePayerAuthenticationPostFormOperationRest',
		}),
		getUsage: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/UsageSummary/Action!getUsageRestPost',
		}),
		getMakasibLoyaltyAccountOperation: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/LoyaltyManagementSystem/Action!getLoyaltyUserProfileRestPost',
		}),
		getBillsHistoryForAccounts: () => ({
			url: 'OmantelApi/webengine/PaymentManagement/Action!getBillsHistoryForAccountsRestPost',
		}),
		getUserGiftCards: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/Services/Action!getUserGiftCardsRestPost',
		}),
		getUserGiftCardsByOrderId: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/Services/Action!getUserGiftCardsByOrderIdRestPost',
		}),
		getSimDetails: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ResourceManagement/Action!getSimDetailsRestPost',
		}),
		directContact: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/FeedbackModel/Action!DirectContactOperationRestPost',
		}),
		getContractDetails: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/PaymentManagement/Action!getContractDetailsRestPost',
		}),
		makePaymentRefund: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/AdminPayments/Action!makePaymentRefundRestPost',
		}),
		// BIMA insurance
		getVehicleInsurance: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/VehicleInsurance/Action!getVehicleInsuranceOffersRestPost',
		}),
		getVehiclePlateChars: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/VehicleInsurance/Action!getVehiclePlateCharCodesRestPost',
		}),
		generateOTP: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/OTP/Action!generateOTPRestPost',
		}),
		getProductsUnauthorized: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ProductInventory/Action!GetProductsUnauthorizedRestPost',
		}),
		validateOperation: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/Services/Action!validateOperationRestPost',
		}),
		validateOTP: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/OTP/Action!validateOTPRestPost',
		}),
		submitProductOrder: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ProductConfiguration/Action!submitProductOrderOperationRestPost',
		}),
		validateResource: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/CybersourcePayments/Action!validateResourceRequestRestPost',
		}),
		// Pick up from outlet
		getBranches: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ResourceManagement/Action!getBranchesRestPost',
		}),
		getServices: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ResourceManagement/Action!getServicesRestPost',
		}),
		getDates: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ResourceManagement/Action!getAvailableDaysRestPost',
		}),
		getHours: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ResourceManagement/Action!getAvailableHoursRestPost',
		}),
		bookAppointment: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ResourceManagement/Action!bookAppointmentRestPost',
		}),
		cancelAppointment: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ResourceManagement/Action!cancelAppointmentRestPost',
		}),
		getAppointment: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ResourceManagement/Action!getAppointmentRestPost',
		}),
		getAppointments: () => ({
			method: 'POST',
			url: 'OmantelApi/webengine/ResourceManagement/Action!getAppointmentsRestPost',
		}),
		getTenant: (orderId: string) => ({
			method: 'POST',
			url: `wcs/resources/store/${STORE_ID}/mscsp/orderId/${orderId}/omantelTenant`,
		}),
		createTenant: (orderId: string) => ({
			method: 'POST',
			url: `wcs/resources/store/${STORE_ID}/mscsp/orderId/${orderId}/createOmantelTenant`,
		}),
		changeTenantProvider: (orderId: string) => ({
			method: 'POST',
			url: `wcs/resources/store/${STORE_ID}/mscsp/orderId/${orderId}/checkoutOmantelTenant`,
		}),
		getTenantFromOrdersHistory: (orderId: string) => ({
			method: 'GET',
			url: `wcs/resources/store/${STORE_ID}/mscsp/orderId/${orderId}/omantelTenantFromOrdersHistory`,
		}),
		getCorpAccountsForCurrentUser: () => ({
			method: 'GET',
			url: 'OmantelApi/webengine/CorpoMasterChild/Action!getCorpAccountsForCurrentUserRest',
		}),

		// Makasib Vouchers
		manageMakasibVoucherReservation: () => ({
			method: 'PUT',
			url: 'product-inventory/vouchers/makasib-evoucher/status',
		}),
	},
	omantelPortal: {
		...omantelPortalEndpointsGenerators,
	},
	omantelShop: {
		...productsEndpointsGenerators,
		...categoriesEndpointsGenerators,
		...inventoryEndpointGenerators,
		...typeaheadEndpointGenerators,
		...espotEndpointGenerators,
		...orderFlowEndpointGenerators,
		...profileEndpointsGenerators,
		...configEndpointsGenerators,
		...buyNowPayLaterEndpointsGenerator,
	},
	wcm: {
		...addressesEndpointsGenerators,
		...carInsuranceGenerators,
		...remoteConfigEndpointsGenerators,
		...enhancedTermAndConditionEndpointsGenerators,
	},
	microApi: {
		getSmartVoucher: ({ cr, phone }: { cr: string; phone: string }) => ({
			method: 'GET',
			url: `customer-management/customer/smart-voucher?crNumber=${cr}&phoneNumber=${phone}`,
		}),
		calculateInstallment: () => ({
			method: 'POST',
			url: `product-inventory/calculateInstallment`,
		}),
	},
};

export default endpoints;
