import { isUserSignedIn } from '@Redux/modules/api/authentication/selectors';

type IFunctionalEndpointConfig = types.endpoints.functionalEndpointConfig;
export const remoteConfig: IFunctionalEndpointConfig = () => {
	return {
		url: `wcm/${
			isUserSignedIn() ? 'myconnect' : 'connect'
		}/ebu_en/content?srv=cmpnt&cmpntname=eshop_flags&source=library`,
	};
};
