import React, { FunctionComponent, useMemo, useState, useEffect } from 'react';
import {
	SelectedItemsWrapper,
	ItemWrapper,
	ItemDescriptionWrapper,
	ItemTitle,
	ItemDescription,
	ButtonWrapper,
	SummaryWrapper,
	VerticalDivider,
	SecureTransactionWrapper,
	SecureTransactionText,
	HighlightedText,
	OutOfStockWrapper,
	OutOfStockDescription,
	SmallOMRWrapper,
	OutOfStockItemTitle,
	BottomSectionCartWrapper,
	ArrowIconContainer,
	BottomSectionPricingWithButton,
	BottomSectionButtonWrapper,
	ButtonContent,
	PriceContentWrapper,
	PlanTitleWrapper,
	TitleWrapper,
	IconWrapper,
	PriceContainer,
	VatInfo,
	PriceBlockContainer,
	VatWrapper,
	PlanEditWarning,
	PlanEditWarningTitle,
	PlanEditWarningDescriptionUnderline,
	WarningSubtitle,
	DeviceWithPlanWrapper,
	PriceAccordion,
	PriceHeaderWrapper,
	PriceIconWrapper,
	PriceTagWrapper,
	PriceContentRowWrapper,
	PriceDetailsWrapper,
	VatRow,
	TooltipIconWrapper,
	BottomColumn,
	StrikethroughWrapper,
	SelectNumberWarning,
	SelectNumberTitle,
} from './ProductCartCard.styled';
import { Row } from 'react-grid-system';
import Divider from '@Components/Divider/Divider';
import CartAdditionalInfo from '@Components/CartAdditionalInfo/CartAdditionalInfo';
import useTranslate from '@Utils/hooks/useTranslate';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import PriceTag from '@Components/PriceTag/PriceTag';
import Button from '@Components/controls/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@Components/Icon/Icon';
import { IAddItemsToCartPayload, IValidateEbuOffer, OrderFlowService } from '@Services/orderFlow/orderFlow';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { errors } from '@Utils/api/errors/errors';
import { IItem } from '@ApiModels/productsByCategoryId';
import { ItemType, ProductsService } from '@Services/productsService/productsService';
import { PaymentMethod } from '../PaymentSummary/PaymentSummary';
import { ButtonSize, ButtonVariant, ButtonWidth } from '@Components/controls/Button/Button.styled';
import { CUSTOMER_B2B2C_CARE_PHONE, CUSTOMER_CARE_PHONE, emfConfigId } from '@Config/app.config';
import { NOT_CHOSEN_NUMBER, PlanType } from '../Plan/Plan';
import { setSelectedItems } from '@Redux/modules/selectedItems/actions';
import { getEbuPlanActionByActionKey, getPayload, getValidateOfferPayload } from '@Services/packages/packages';
import { EXCLUDE_CART_ITEMS } from '@Components/GetInsurance/GetInsurance';
import { useCart } from '@Utils/context/CartContext';
import { ProfileService } from '@Services/profile/profileService';
import { setPersistentCheckoutDetails } from '@Redux/modules/persistentCheckoutData/actions';
import { useDeviceInsurance } from '@Utils/context/DeviceInsuranceContext';
import { IDetails } from '@Redux/modules/selectedItems/actions';
import TagManager from 'react-gtm-module';
import { EbuUserType } from '@ApiModels/ebuUserProfile';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import useMicrosoftAutomationProcess from '@Utils/hooks/useMicrosoftAutomationProcess';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import {
	BodyMBold,
	BodyS,
	BodySBold,
	CaptionBigger,
	CaptionHigherBold,
	Info,
	InfoBold,
	ParagraphSBold,
} from '@Components/Typography/Typography.styled';
import { roundMoney } from '@Utils/converters/roundMoney';
import Tooltip from '@Components/Tooltip/Tooltip';
import useScreen from '@Utils/hooks/useScreen';
import { isRTL } from '@Utils/layout/layoutSupport';
import { useHistory } from 'react-router-dom';
import { getLang } from '@Utils/language/language';
import { IPlanAction } from '@Services/orderFlow/orderFlow';
import { isWifiFreeDevice, isNewPCTLaunch } from '@Config/features.config';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { BillingType, AccountType } from '@ApiModels/accounts';
import { isDisabledBnplCr7985, isBnplCartWarning, disableBNPLInsurance } from '@Config/features.config';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';

const ICON_SIZE = 24;
const ICON_SIZE_40 = 40;
const WARNING_ICON_SIZE = 35;
const ADD_TO_CART_BI = 'ADD_TO_CART_BI';
const DEF_COLOR = 'DEF_Color';
const DEF_CAPACITY = 'DEF_Capacity';
const BNPL_PLAN_ACTIONS = ['BNPL_BAQATI', 'BNPL_HBB', 'BNPL_WFBB'];
interface IUserProfile {
	loggedUserPhoneNumber: string;
}

const ProductCartCard: FunctionComponent<types.cards.IProductCartCardProps> = ({
	params: {
		outOfStock = false,
		bottomMode = false,
		expanded,
		selectedFilters,
		installmentPeriod,
		preorder,
		commitment,
		installmentAmount,
		installmentAmountId,
		salesLead,
		errorCodeForInstallmentAmount,
		bnplGetInstallmentAmount,
	},
}) => {
	const { isBuyNowPayLaterFlow, canIaddBuyNowPayLaterToCart } = useBuyNowPayLater();
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const { screen } = useScreen();
	const [item, setItem] = useState<IItem | null>(null);
	const [accessoryWithPlan, setAccessoryWithPlan] = useState<boolean>(true);
	const [mobileDeviceWithPlan, setMobileDeviceWithPlan] = useState<boolean>(false);
	const [tabletWithHBBWFBBPlan, setTabletWithHBBWFBBPlan] = useState<boolean>(false);
	const [accessoryWithHBBWFBBPlan, setAccessoryWithHBBWFBBPlan] = useState<boolean>(false);
	const [showNotValidWarning, setShowNotValidWarning] = useState<boolean>(false);
	const [addToCartWarning, setAddToCartWarning] = useState<boolean>(false);

	const [backendWarnings, setBackendWarnings] = useState<string[]>([]);
	const [showFullUpfront, setShowFullUpfront] = useState<boolean>(false);
	const [showInstallements, setShowInstallements] = useState<boolean>(false);
	const [showFull12Mo, setShowFull12Mo] = useState<boolean>(false);
	const [showFull24Mo, setShowFull24Mo] = useState<boolean>(false);
	const [showLastMonth, setShowLastMonth] = useState<boolean>(false);
	const [showFullMonthly, setShowFullMonthly] = useState<boolean>(false);
	const selectedProduct = useSelector((state: types.redux.IState) => state.selectedItems?.details);
	const selectedProducts = useSelector((state: types.redux.IState) => state.selectedItems);
	const selectedProductPaymentType = useSelector(
		(state: types.redux.IState) => state.selectedItems?.productPaymentType
	);
	const selectedPlan = useSelector((state: types.redux.IState) => state.selectedItems?.plan);
	const currentPlan = useSelector((state: types.redux.IState) => state.selectedItems?.currentPlan);
	const activeProvisioning = useSelector((state: types.redux.IState) => state.selectedItems?.activeProvisioning);
	const activePlanCommitment = useSelector((state: types.redux.IState) => state.selectedItems?.activePlanCommitment);
	const isB2b2c = useSelector((state: types.redux.IState) => state.api.authentication.isB2b2c);
	const isB2b = useSelector((state: types.redux.IState) => state.api.authentication.isB2b);
	const isHBB = useSelector((state: types.redux.IState) => state.selectedItems?.isHBB);
	const isWFBB5G = useSelector((state: types.redux.IState) => state.selectedItems?.isWFBB5G);
	const isWFBB4G = useSelector((state: types.redux.IState) => state.selectedItems?.isWFBB4G);
	const isNewPCTSmartphones = useSelector((state: types.redux.IState) => state.selectedItems.isNewPCTSmartphones);
	const isNewPCTAccessory = useSelector((state: types.redux.IState) => state.selectedItems.isNewPCTAccessory);
	const isNewPCTLaptopTablet = useSelector((state: types.redux.IState) => state.selectedItems.isNewPCTLaptopTablet);
	const {
		oldDeviceCommitmentId,
		activeDeviceAccessoriesInstallmentId,
		activeMobileDeviceInstallmentId,
		sendDeviceCommitment,
	} = useSelector((state: types.redux.IState) => state.selectedItems);
	const telemarketer = useSelector((state: types.redux.IState) => state.api.authentication.telemarketer);
	const telesalesCustomerPhoneNumber = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.telesalesInformation?.phoneNumber
	);
	const showTwoMonthWarning = useSelector((state: types.redux.IState) => state.selectedItems?.showTwoMonthWarning);
	const selectedAccount = useSelector((state: types.redux.IState) => state.selectedItems?.selectedAccount);
	const showWarning = !!useSelector((state: types.redux.IState) => state.selectedItems?.showWarning);
	const wrongDeviceChoosen = !!useSelector((state: types.redux.IState) => state.selectedItems?.wrongDeviceChoosen);
	const profileHasMultiAccount = useSelector(
		(state: types.redux.IState) => state.selectedItems?.profileHasMultiAccount
	);

	const isHbbInstallmentsOffer = useSelector(
		(state: types.redux.IState) => state.selectedItems?.isHbbInstallmentsOffer
	);
	const isLogged = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const planType = useSelector((state: types.redux.IState) => state.selectedItems?.planType);
	const userHaveLegacyPlan = useSelector((state: types.redux.IState) => state.selectedItems?.userHaveLegacyPlan);
	const allowedAccountCategories = useSelector((state: types.redux.IState) => state.settings.config.accountCategory);
	const calculatedProduct = useSelector((state: types.redux.IState) => state.selectedItems?.calculatedProduct);
	const selectedInstallment = useSelector((state: types.redux.IState) => state.selectedItems?.selectedInstallment);

	const selectedCommitment = useSelector(
		(state: types.redux.IState) => state.selectedItems?.selectedCommitmentPeriod
	);
	const voucherWarning = useSelector((state: types.redux.IState) => state.selectedItems?.voucherWarning);
	const history = useHistory();
	const userIsCorporate = useSelector((state: types.redux.IState) => state.selectedItems.isCorporateUser);
	const isEbuDocumentType = useSelector((state: types.redux.IState) => state.selectedItems.isEbuDocumentType);
	const busyIndicatorsList = useSelector((state: types.redux.IState) => state.busyIndicator.list);
	const remoteFeatureConfig = useSelector(remoteConfigSelector);
	const DISABLE_WIFI_FREE_DEVICE = isWifiFreeDevice(remoteFeatureConfig);
	const DISABLE_NEW_PCT_LAUNCH = isNewPCTLaunch(remoteFeatureConfig);

	const { creditRating } = useSelector((state: types.redux.IState) => state.api.personalData);
	const DISABLE_BNPL_CR_ODF_7985 = isDisabledBnplCr7985(remoteFeatureConfig);
	// this kill switch flag to enable the BNPL cart warning popup fix
	const isBnplAddToCartWarning = isBnplCartWarning(remoteFeatureConfig);
	// this kill switch flag to disable insurance call with product incase of BNPL
	const isDisableBNPLInsurance = disableBNPLInsurance(remoteFeatureConfig);

	const notChosenNumberWarning = busyIndicatorsList.includes(NOT_CHOSEN_NUMBER);

	const showTwoDeviceAccessoriesWarning =
		planType === PlanType.WITH_PLAN &&
		activeDeviceAccessoriesInstallmentId &&
		(accessoryWithPlan || accessoryWithHBBWFBBPlan);
	const showTwoMobileDeviceWarning =
		planType === PlanType.WITH_PLAN &&
		activeMobileDeviceInstallmentId &&
		(mobileDeviceWithPlan || tabletWithHBBWFBBPlan);
	const isWFBB = !!isWFBB5G || !!isWFBB4G;

	const skuNotFound = !selectedProduct && !!selectedPlan;
	const disableAddToCartButton =
		skuNotFound || !!showWarning || addToCartWarning || showNotValidWarning || !!voucherWarning;

	const newPlanIsHigher =
		(currentPlan?.commerceAttributes?.hierarchyIndex || 0) < (selectedPlan?.hierarchyIndex || 0);
	const onlyDevice = planType === 'DEVICE_ONLY';
	const { getInsurance, additionalProduct, validateDeviceInsurance, setPaymentMethod } = useDeviceInsurance();
	const { cart, cartItemsLength } = useCart();

	const isHayyak =
		selectedAccount?.accountType === AccountType.MOBILE && selectedAccount?.billingType === BillingType.PREPAID;

	const isBNPLOrderInCart = isBnplAddToCartWarning && BNPL_PLAN_ACTIONS.includes(cart?.items[0]?.plan?.PA ?? '');

	const isBaqati =
		selectedAccount?.accountType === AccountType.MOBILE && selectedAccount?.billingType === BillingType.POSTPAID;

	const activeInsuranceInCart = useMemo(() => {
		return cart.items.find((item) => EXCLUDE_CART_ITEMS.includes(item.partNumber));
	}, [cart, additionalProduct]);

	const { isMicrosoftAutomationProcess } = useMicrosoftAutomationProcess();

	const { nonInstallmentItems, installmentItems } = useMemo(() => {
		const _installmentItems = additionalProduct?.items?.filter((item) => {
			const requiredProductAttribute = item?.attributes?.find((attribute) => attribute.identifier === 'REQ_PROD');
			const plansForInstallments = requiredProductAttribute?.values[0]?.value.split(',');
			const planForInstallment = plansForInstallments?.find((plan) => plan === currentPlan?.productId);
			return !!planForInstallment;
		});
		const _nonInstallmentItems = additionalProduct?.items?.filter((item) => {
			const requiredProductAttribute = item?.attributes?.find((attribute) => attribute.identifier === 'REQ_PROD');
			return !requiredProductAttribute;
		});
		return {
			nonInstallmentItems: _nonInstallmentItems,
			installmentItems: _installmentItems,
		};
	}, [additionalProduct, currentPlan]);

	const selectedInsuranceProduct = useMemo(() => {
		const installmentProduct = installmentItems?.find(
			(item) => item.installmentPeriod?.toString() === selectedFilters?.installmentPeriod
		);

		// eslint-disable-next-line
		return installmentProduct?.id
			? installmentProduct
			: installmentItems?.find((item) => item.installmentPeriod?.toString() === '12');
	}, [nonInstallmentItems, installmentItems, selectedProduct, selectedFilters]);

	useEffect(() => {
		const insurec = async () =>
			await getInsurance(selectedProduct?.additionalService || '', selectedProduct?.insurancePromotion);
		insurec().catch(() => {});
	}, [selectedProduct]);

	useEffect(() => {
		return () => {
			dispatch(removeBusyIndicator(ADD_TO_CART_BI));
		};
	}, []);

	useEffect(() => {
		setShowNotValidWarning(false);
		setBackendWarnings([]);
	}, [selectedPlan, selectedAccount, currentPlan, selectedFilters]);

	useEffect(() => {
		setAddToCartWarning(false);
	}, [selectedProduct?.installmentPeriod, selectedProduct?.installmentValue]);

	useEffect(() => {
		setAccessoryWithPlan(selectedProduct?.defItemType === ItemType.ITEM_ACCESSORY_INSTALMENTS);
		setMobileDeviceWithPlan(selectedProduct?.defItemType === ItemType.TABLETS_LAPTOPS_INSTALMENTS);
		setTabletWithHBBWFBBPlan(
			selectedProduct?.defItemType === ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB ||
				selectedProduct?.defItemType === ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB
		);
		setAccessoryWithHBBWFBBPlan(
			selectedProduct?.defItemType === ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB ||
				selectedProduct?.defItemType === ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB
		);
		if (selectedProduct?.id) {
			ProductsService.getProductByIdSpecifiedSkus({
				productId: selectedProduct.id,
				itemType: accessoryWithPlan
					? ItemType.ITEM_ACCESSORY_INSTALMENTS
					: mobileDeviceWithPlan
					? ItemType.TABLETS_LAPTOPS_INSTALMENTS
					: ItemType.UNKNOWN,
			}).subscribe(
				({ item }) => {
					setItem(item);
					setAccessoryWithPlan(item?.itemType === ItemType.ITEM_ACCESSORY_INSTALMENTS);
					setMobileDeviceWithPlan(item?.itemType === ItemType.TABLETS_LAPTOPS_INSTALMENTS);
				},
				() => {}
			);
		}
	}, [selectedProduct]);

	const validateOffer = async (isEbuUserAdmin: boolean): Promise<boolean> => {
		return new Promise((resolve) => {
			if (userIsCorporate && !!calculatedProduct) {
				dispatch(addBusyIndicator(ADD_TO_CART_BI));

				const payload: IValidateEbuOffer = getValidateOfferPayload({
					mobilePhone:
						telemarketer && telesalesCustomerPhoneNumber
							? telesalesCustomerPhoneNumber
							: selectedAccount?.accountNum ?? '',
					commitment: calculatedProduct?.device?.productId,
					smartVoucherCommitmentId: calculatedProduct?.discount?.productId,
					calculatedProduct,
					onlyDevice,
					hasAdditionalInfo: isEbuUserAdmin,
					isB2b,
				});

				OrderFlowService.validateEbuOffer(payload).subscribe(
					(response) => {
						dispatch(removeBusyIndicator(ADD_TO_CART_BI));
						if (response.valid) {
							resolve(true);
						} else {
							resolve(false);
							dispatch(setSelectedItems({ wrongDeviceChoosen: true }));
						}
					},
					(e) => {
						const error = e?.response?.errors || null;
						if (
							backendWarnings &&
							error?.length &&
							backendWarnings.indexOf(error[0]?.errorParameters[0]) === -1
						) {
							backendWarnings.push(error[0]?.errorParameters[0]);
						}
						dispatch(removeBusyIndicator(ADD_TO_CART_BI));
						setShowNotValidWarning(true);
						resolve(false);
					}
				);
			} else if ((selectedPlan || isHbbInstallmentsOffer) && !isBuyNowPayLaterFlow) {
				dispatch(addBusyIndicator(ADD_TO_CART_BI));
				OrderFlowService.validateOffer(
					getPayload({
						mobilePhone:
							telemarketer && telesalesCustomerPhoneNumber
								? telesalesCustomerPhoneNumber
								: selectedAccount?.accountNum ?? '',
						selectedSKU: selectedProduct,
						planToCheck: selectedPlan,
						currentPlan,
						skusName: selectedProduct?.partNumber,
						activeProvisioning,
						activePlanCommitment,
						isHBB: !!isHBB,
						isWFBB,
						isHbbInstallmentsOffer,
						oldDeviceCommitment: oldDeviceCommitmentId,
						sendDeviceCommitment,
						isB2b2c,
						isB2b,
						commitment,
						installmentAmount: installmentAmountId && installmentAmount,
					})
				).subscribe(
					(response) => {
						dispatch(removeBusyIndicator(ADD_TO_CART_BI));
						if (response.valid) {
							resolve(true);
						} else {
							resolve(false);
							dispatch(setSelectedItems({ wrongDeviceChoosen: true }));
						}
					},
					(e) => {
						const error = e?.response?.errors || null;
						if (
							backendWarnings &&
							error?.length &&
							backendWarnings.indexOf(error[0]?.errorParameters[0]) === -1
						) {
							backendWarnings.push(error[0]?.errorParameters[0]);
						}
						dispatch(removeBusyIndicator(ADD_TO_CART_BI));
						setShowNotValidWarning(true);
						resolve(false);
					}
				);
			} else {
				setShowNotValidWarning(true);
				return resolve(false);
			}
		});
	};

	const getUserProfile = async (): Promise<IUserProfile> => {
		return new Promise((resolve) => {
			ProfileService.getUserProfile().subscribe(
				(response) => {
					resolve({ loggedUserPhoneNumber: response.uid });
				},
				() => {
					resolve({ loggedUserPhoneNumber: '' });
				}
			);
		});
	};

	const checkAvailabilityToAddToCart = () => {
		if (activeInsuranceInCart) {
			dispatch(
				setModal({
					type: modalTypes.GENERIC_ERROR,
					data: {
						icon: 'warning',
						iconFill: 'primary',
						title: translate('get.insurance.warning.title'),
						description: translate('get.insurance.warning.subtitle'),
					},
				})
			);
			return false;
		}
		if (isMicrosoftAutomationProcess) {
			dispatch(
				setModal({
					type: modalTypes.GENERIC_ERROR,
					data: {
						icon: 'warning',
						iconFill: 'primary',
						title: translate('microsoft-automation-proccess.is-microsoft-product-in-cart-title'),
						description: translate(
							'microsoft-automation-proccess.is-microsoft-product-in-cart-description'
						),
					},
				})
			);
			return false;
		}
		if (isBnplAddToCartWarning && isBNPLOrderInCart) {
			dispatch(
				setModal({
					type: modalTypes.GENERIC_ERROR,
					data: {
						icon: 'warning',
						iconFill: 'primary',
						title: translate('add-device-when-buy-now-pay-later-in-cart'),
						description: translate('add-device-when-buy-now-pay-later-in-cart-description'),
						goToCartButton: true,
					},
				})
			);
			return false;
		}
		if (
			!canIaddBuyNowPayLaterToCart &&
			isBuyNowPayLaterFlow &&
			(selectedProduct?.installmentPeriod === '12' || selectedProduct?.installmentPeriod === '24')
		) {
			dispatch(
				setModal({
					type: modalTypes.GENERIC_ERROR,
					data: {
						icon: 'warning',
						iconFill: 'primary',
						title: translate('buy-now-pay-later.is-bnpl-in-cart-title'),
						description: translate('buy-now-pay-later.is-bnpl-in-cart-description'),
						goToCartButton: true,
					},
				})
			);
			return false;
		}
		return true;
	};

	const getSameInstallmentItemFromCart = (choosenProduct: IDetails) => {
		const sameInstallmentItemFromCart = cart.items.find(
			(item) =>
				(item.defType === ItemType.TABLETS_LAPTOPS_INSTALMENTS &&
					choosenProduct.defItemType === ItemType.TABLETS_LAPTOPS_INSTALMENTS) ||
				(item.defType === ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB &&
					choosenProduct.defItemType === ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB) ||
				(item.defType === ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB &&
					choosenProduct.defItemType === ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB) ||
				(item.defType === ItemType.ITEM_ACCESSORY_INSTALMENTS &&
					choosenProduct.defItemType === ItemType.ITEM_ACCESSORY_INSTALMENTS) ||
				(item.defType === ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB &&
					choosenProduct.defItemType === ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB) ||
				(item.defType === ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB &&
					choosenProduct.defItemType === ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB)
		);
		return sameInstallmentItemFromCart && sameInstallmentItemFromCart.name ? sameInstallmentItemFromCart.name : '';
	};

	const isEbuUserAdmin = async (): Promise<boolean> => {
		return new Promise((resolve) => {
			ProfileService.getEbuUserProfile().subscribe(
				(response) => {
					resolve(response.userType === EbuUserType.ADMIN || response.userType === EbuUserType.SUPERADMIN);
				},
				() => {
					resolve(false);
				}
			);
		});
	};

	const handleAddToCart = async () => {
		const installmentProductId = selectedProducts.productById
			? selectedProducts?.productById.filter(
					(item) => item.partNumber === selectedProduct?.partNumber.split('##')[0]
			  )[0]?.id
			: '';
		const available = checkAvailabilityToAddToCart();
		const isEbuUserAdminData: boolean = userIsCorporate ? await isEbuUserAdmin() : false;
		if (available) {
			const valid = !onlyDevice && (await validateOffer(isEbuUserAdminData));
			if (onlyDevice || valid || isBuyNowPayLaterFlow) {
				if (selectedProduct?.id) {
					dispatch(addBusyIndicator(ADD_TO_CART_BI));
					const itemIncludesPlan = selectedProduct?.partNumber.includes('##') || installmentAmountId;
					let payload: IAddItemsToCartPayload =
						(isHBB || isWFBB) && (!tabletWithHBBWFBBPlan || !accessoryWithHBBWFBBPlan) && newPlanIsHigher
							? {
									quantity: '1',
									productId: selectedProduct?.id || '',
									productName: selectedProduct?.name || '',
									xitem_mobilePhoneType: selectedAccount?.billingType,
									xitem_mobilePhone: selectedAccount?.accountNum,
									xitem_planAction: isWFBB ? 'WFBB_PLAN_UPGRADE' : 'HBB_PLAN_UPGRADE',
									xitem_planCommitment: isWFBB
										? selectedPlan?.wfbbCommitment
										: selectedPlan?.planCommitment,
									xitem_provisioning: selectedPlan?.provisioningID,
									xitem_oldTariff: currentPlan?.productId,
									xitem_oldPlanCommitment: activePlanCommitment?.productId,
									xitem_oldProvisioning: activeProvisioning?.productId,
							  }
							: {
									quantity: '1',
									productId: selectedProduct?.id || '',
									productName: selectedProduct?.name || '',
									xitem_mobilePhoneType: selectedAccount?.billingType,
									xitem_mobilePhone: selectedAccount?.accountNum,
									xitem_planAction: getPlanAction(),
									xitem_oldTariff: '',
							  };
					if (!itemIncludesPlan && !isBuyNowPayLaterFlow) {
						delete payload.xitem_planAction;
						delete payload.xitem_mobilePhoneType;
						delete payload.xitem_mobilePhone;
						payload.payment_plan = translate('product.details.plan.device.only');
						payload.installmentPeriod = '';
					} else {
						payload.installmentPeriod = selectedFilters?.installmentPeriod;
						payload.payment_plan = translate('product.details.plan.with.plan');
					}
					if (isLogged && !telemarketer) {
						const { loggedUserPhoneNumber } = await getUserProfile();
						const preparedLoginNumber = loggedUserPhoneNumber.replace(/[^0-9]+/g, '');
						payload = {
							...payload,
							xitem_login: preparedLoginNumber,
						};
					}
					if (isLogged && telemarketer && telesalesCustomerPhoneNumber) {
						const preparedLoginNumber = telesalesCustomerPhoneNumber.replace(/[^0-9]+/g, '');
						payload = {
							...payload,
							xitem_login: preparedLoginNumber,
						};
					}
					if (!!installmentAmount) {
						payload = {
							...payload,
							xitem_commitmentId: commitment,
							productId: installmentProductId || selectedProduct?.id,
							xitem_IAI: installmentAmountId,
							xitem_installmentDuration: selectedProduct?.installmentPeriod,
							xitem_emfConfigId: emfConfigId.POSTPAID,
							xitem_accountNo: String(selectedAccount?.accountId),
						};
					}
					if (
						isBuyNowPayLaterFlow &&
						(selectedProduct?.installmentPeriod === '12' || selectedProduct?.installmentPeriod === '24')
					) {
						delete payload.payment_plan;
						delete payload.xitem_mobilePhoneType;
						delete payload.xitem_oldTariff;
						delete payload.xitem_mobilePhone;
						delete payload.xitem_IAI;
						delete payload.xitem_emfConfigId;
						payload = {
							...payload,
							xitem_installmentDuration: selectedProduct?.installmentPeriod,
							xitem_BNPLMP: payload.xitem_login,
						};
					}
					if (
						payload?.xitem_planAction !== ItemType.ITEM_ACCESSORY_INSTALMENTS &&
						payload?.xitem_planAction !== ItemType.TABLETS_LAPTOPS_INSTALMENTS &&
						payload?.xitem_planAction !== ItemType.B2B_ITEM_ACCESSORY_BAQATI &&
						payload?.xitem_planAction !== ItemType.B2B_TABLETS_LAPTOPS_BAQATI &&
						payload?.xitem_planAction !== ItemType.B2B2C_ITEM_ACCESSORY_BAQATI &&
						payload?.xitem_planAction !== ItemType.B2B2C_TABLETS_LAPTOPS_BAQATI &&
						sendDeviceCommitment
					) {
						payload = { ...payload, xitem_oldDeviceCommitment: oldDeviceCommitmentId };
					}
					const insurancePayload = {
						quantity: '1',
						productId: '',
						productName: '',
						payment_plan: '',
						installmentPeriod: '',
					};
					if (
						validateDeviceInsurance(
							selectedProduct?.additionalService || '',
							selectedProduct?.insurancePromotion || false
						)
					) {
						const getInsuranceResponse = await getInsurance(selectedProduct?.additionalService || '', true);
						insurancePayload.productId =
							getInsuranceResponse?.items?.find((item) => item.duration === '12')?.id || '';
						insurancePayload.productName =
							getInsuranceResponse?.items?.find((item) => item.duration === '12')?.name || '';
						insurancePayload.payment_plan = translate('product.details.plan.device.only');
						insurancePayload.installmentPeriod = '';
					}

					if (isEbuDocumentType && onlyDevice) {
						payload = {
							...payload,
							xitem_planAction: isB2b ? 'B2B_WITHOUT_VOUCHER_UPFRONT' : 'B2B2C_WITHOUT_VOUCHER_UPFRONT',
						};
					}

					if (userIsCorporate && !!calculatedProduct) {
						payload = {
							...payload,
							productId: selectedProduct?.id || '',
							productName: selectedProduct?.name || '',
							xitem_planAction: Number(calculatedProduct?.device?.deviceCommitments?.installmentMonths)
								? isB2b
									? 'B2B_WITH_VOUCHER_INSTALL'
									: 'B2B2C_WITH_VOUCHER_INSTALL'
								: isB2b
								? 'B2B_WITH_VOUCHER_UPFRONT'
								: 'B2B2C_WITH_VOUCHER_UPFRONT',
							xitem_commitmentDuration: String(selectedCommitment?.commitmentDuration),
							xitem_installmentDuration: String(selectedInstallment?.duration),
							xitem_mobilePhoneType: selectedAccount?.billingType,
							xitem_mobilePhone: selectedAccount?.accountNum,
							xitem_accountNo: String(selectedAccount?.accountId),
							xitem_corporateNo: selectedAccount?.accountNum,
							xitem_hasAdditionalInfo: isEbuUserAdminData.toString(),
						};
						delete payload.productName;
						delete payload.payment_plan;
					}

					if (!DISABLE_BNPL_CR_ODF_7985 && isBuyNowPayLaterFlow) {
						payload = {
							...payload,
							xitem_BNPLPP: bnplGetInstallmentAmount,
							xitem_emfConfigId: emfConfigId.POSTPAID,
							xitem_BNPLCR: creditRating,
						};
					}

					if (!DISABLE_WIFI_FREE_DEVICE) {
						payload = {
							...payload,
							DISABLE_WIFI_FREE_DEVICE,
						};
						selectedProducts?.details?.attributes?.map((item) => {
							if (item.identifier === 'FREE_PRODUCT_PARAM_ID') {
								payload = {
									...payload,
									Free_Product_xitem_commitmentId: item.values[0].value,
								};
							}
							if (item.identifier === 'FREE_PRODUCT_CATENTRY_ID') {
								payload = {
									...payload,
									Free_Product_quantity: '1',
									Free_Product_productId: item.values[0].value,
									Free_Product_productName: selectedProducts?.details?.name,
									Free_Product_payment_plan: 'Device only',
								};
							}
						});
					}

					OrderFlowService.addItemsToCart(
						insurancePayload.productId ? [payload, insurancePayload] : [payload]
					).subscribe(
						({ response }) => {
							if (
								(isDisableBNPLInsurance &&
									!isBuyNowPayLaterFlow &&
									currentPlan &&
									['14196', '1201420'].includes(currentPlan?.productId) &&
									selectedInsuranceProduct?.id &&
									selectedProductPaymentType !== PaymentMethod.UPFRONT) ||
								(!isDisableBNPLInsurance &&
									currentPlan &&
									['14196', '1201420'].includes(currentPlan?.productId) &&
									selectedInsuranceProduct?.id &&
									selectedProductPaymentType !== PaymentMethod.UPFRONT)
							) {
								OrderFlowService.addItemsToCart([
									{
										quantity: '1',
										productId: selectedInsuranceProduct?.id,
										xitem_planAction: 'DEVICE_INSURANCE_INSTALMENT' as IPlanAction,
										xitem_login: selectedAccount?.accountNum,
									},
								]).subscribe(({ response: res }) => {
									const orderId = res.orderItem[0].orderItemId;
									dispatch(removeBusyIndicator(ADD_TO_CART_BI));
									dispatch(
										setPersistentCheckoutDetails({
											telesalesInformation: { orderId: res.orderId },
										})
									);
									dispatch(
										setModal({
											type: modalTypes.ADDED_TO_CART,
											data: { orderId },
											withoutContainer: true,
										})
									);
								});
							} else {
								const orderId = response.orderItem[0].orderItemId;
								dispatch(removeBusyIndicator(ADD_TO_CART_BI));
								dispatch(
									setPersistentCheckoutDetails({
										telesalesInformation: { orderId: response.orderId },
									})
								);
								dispatch(
									setModal({
										type: modalTypes.ADDED_TO_CART,
										data: { orderId },
										withoutContainer: true,
									})
								);
							}
						},
						(e) => {
							dispatch(removeBusyIndicator(ADD_TO_CART_BI));
							if (e.response?.errors?.length > 0) {
								if (e.response?.errors[0]?.errorKey === errors.MICROSOFT_ERROR) {
									history.push(`/${getLang()}/microsoft-error`);
								}
							}
							if (e.response?.errors?.length > 0) {
								if (e.response?.errors[0]?.errorKey === errors.ITEM_OUT_OF_STOCK) {
									dispatch(
										setModal({
											type: modalTypes.GENERIC_ERROR,
											data: {
												icon: 'warning',
												iconFill: 'primary',
												title: translate('product.cart-card.modal.out-of-stock.title'),
												description: translate(
													'product.cart-card.modal.out-of-stock.description'
												),
											},
										})
									);
								} else if (e.response?.errors[0]?.errorKey === errors.ORDERITEM_MULTIPLE_TARIFFS) {
									dispatch(
										setModal({
											type: modalTypes.GENERIC_ERROR,
											data: {
												icon: 'warning',
												iconFill: 'primary',
												title: translate('product.cart-card.modal.multiple.tariffs.title'),
												description: translate(
													'product.cart-card.modal.multiple.tariffs.description'
												),
											},
										})
									);
								} else if (
									e.response?.errors[0]?.errorKey ===
										errors.ORDERITEM_MULTIPLE_ACCESSORY_INSTALMENT ||
									e.response?.errors[0]?.errorKey ===
										errors.ORDERITEM_MULTIPLE_TABLET_LAPTOP_INSTALMENT
								) {
									dispatch(
										setModal({
											type: modalTypes.GENERIC_ERROR,
											data: {
												icon: 'warning',
												iconFill: 'primary',
												title: translate('product.cart-card.modal.multiple.installments.title'),
												description: translate(
													'product.cart-card.modal.multiple.installments.description',
													selectedProduct?.name,
													getSameInstallmentItemFromCart(selectedProduct)
												),
											},
										})
									);
								} else if (e.response?.errors[0]?.errorKey === errors.ORDERITEM_MIXED_COMMITMENT) {
									dispatch(
										setModal({
											type: modalTypes.GENERIC_ERROR,
											data: {
												icon: 'warning',
												iconFill: 'primary',
												title: translate('product.cart-card.modal.mixed.installments.title'),
												description: translate(
													'product.cart-card.modal.mixed.installments.description'
												),
											},
										})
									);
								} else if (e.response?.errors[0]?.errorKey === errors.SESSION_GENERIC_USER) {
									dispatch(
										setModal({
											type: modalTypes.LOGIN_SESSION_EXPIRED,
											data: {},
										})
									);
								} else {
									setAddToCartWarning(true);
								}
							}
						}
					);
				}
			}
		}
	};

	const getPlanAction = () => {
		if (!DISABLE_NEW_PCT_LAUNCH && (isNewPCTAccessory || isNewPCTLaptopTablet)) {
			if (selectedProduct?.defItemType === 'TABLETS_LAPTOPS_INSTALMENTS') {
				return 'TABLETS_LAPTOPS_INSTALMENTS';
			} else if (selectedProduct?.defItemType === 'ITEM_ACCESSORY_INSTALMENTS') {
				return 'ITEM_ACCESSORY_INSTALMENTS';
			} else if (selectedProduct?.defItemType === 'TABLETS_LAPTOPS_INSTALMENTS_HBB') {
				return 'TABLETS_LAPTOPS_INSTALMENTS_HBB';
			} else if (selectedProduct?.defItemType === 'ITEM_ACCESSORY_INSTALMENTS_HBB') {
				return 'ITEM_ACCESSORY_INSTALMENTS_HBB';
			} else if (selectedProduct?.defItemType === 'TABLETS_LAPTOPS_INSTALMENTS_WFBB') {
				return 'TABLETS_LAPTOPS_INSTALMENTS_WFBB';
			} else if (selectedProduct?.defItemType === 'ITEM_ACCESSORY_INSTALMENTS_WFBB') {
				return 'ITEM_ACCESSORY_INSTALMENTS_WFBB';
			}
		} else if (tabletWithHBBWFBBPlan && isHBB) {
			return 'TABLETS_LAPTOPS_INSTALMENTS_HBB';
		} else if (tabletWithHBBWFBBPlan && isWFBB) {
			return 'TABLETS_LAPTOPS_INSTALMENTS_WFBB';
		} else if (accessoryWithHBBWFBBPlan && isHBB) {
			return 'ITEM_ACCESSORY_INSTALMENTS_HBB';
		} else if (accessoryWithHBBWFBBPlan && isWFBB) {
			return 'ITEM_ACCESSORY_INSTALMENTS_WFBB';
		} else if (accessoryWithPlan) {
			return getEbuPlanActionByActionKey('ITEM_ACCESSORY_INSTALMENTS', !!isB2b, !!isB2b2c);
		} else if (mobileDeviceWithPlan) {
			return getEbuPlanActionByActionKey('TABLETS_LAPTOPS_INSTALMENTS', !!isB2b, !!isB2b2c);
		} else if ((isHBB || isWFBB) && !newPlanIsHigher) {
			return isWFBB ? 'WFBB_THE_SAME_PLAN' : 'HBB_THE_SAME_PLAN';
		} else if (newPlanIsHigher) {
			return getEbuPlanActionByActionKey('MOBILE_PLAN_UPGRADE', !!isB2b, !!isB2b2c);
		} else if (
			isHBB &&
			isBuyNowPayLaterFlow &&
			(selectedProduct?.installmentPeriod === '12' || selectedProduct?.installmentPeriod === '24')
		) {
			return 'BNPL_HBB';
		} else if (
			isWFBB &&
			isBuyNowPayLaterFlow &&
			(selectedProduct?.installmentPeriod === '12' || selectedProduct?.installmentPeriod === '24')
		) {
			return 'BNPL_WFBB';
		} else if (
			isBaqati &&
			isBuyNowPayLaterFlow &&
			(selectedProduct?.installmentPeriod === '12' || selectedProduct?.installmentPeriod === '24')
		) {
			return 'BNPL_BAQATI';
		} else {
			return getEbuPlanActionByActionKey('MOBILE_THE_SAME_PLAN', !!isB2b, !!isB2b2c);
		}
	};

	const upfrontBusinessPrice = useMemo(() => {
		return Number(calculatedProduct?.device?.saleDetails?.totalAmount || 0);
	}, [calculatedProduct]);

	const standardBusinessDiscountPrice = useMemo(() => {
		const standardPrice = Number(calculatedProduct?.device?.saleDetails?.standardPrice || 0);
		const taxablePrice = Number(calculatedProduct?.device?.saleDetails?.taxableAmount || 0);
		return taxablePrice - standardPrice ? standardPrice : 0;
	}, [calculatedProduct, upfrontBusinessPrice]);

	const standardBusinessPrice = useMemo(() => {
		return Number(calculatedProduct?.device?.saleDetails?.standardPrice || 0);
	}, [calculatedProduct]);

	const monthlyBusinessPrice = useMemo(() => {
		return Number(calculatedProduct?.device?.deviceCommitments?.installmentAmount || 0);
	}, [calculatedProduct]);

	const upfrontPrice = useMemo(() => {
		return Number(selectedProduct?.priceOffer ?? '');
	}, [selectedPlan, selectedProductPaymentType, selectedProduct]);

	const monthlyPrice = useMemo(() => {
		const planMonthlyInstallment =
			accessoryWithPlan || mobileDeviceWithPlan ? 0 : Number(selectedPlan?.price ?? '');
		const planMonthlyInstallmentVat =
			accessoryWithPlan || mobileDeviceWithPlan ? 0 : Number(selectedPlan?.vatValue ?? '');
		let deviceMonthlyInstallment = 0;
		if (selectedProductPaymentType === PaymentMethod.MONTHLY) {
			deviceMonthlyInstallment = Number(selectedProduct?.installmentValue ?? '');
		}
		return planMonthlyInstallment + deviceMonthlyInstallment + planMonthlyInstallmentVat;
	}, [selectedPlan, selectedProductPaymentType, selectedProduct, accessoryWithPlan, mobileDeviceWithPlan]);

	const additionalInfo = useMemo(() => {
		if (item?.availableCapacity?.length) {
			return `${item.colors[0].identifier}, ${item.availableCapacity[0].value}`;
		} else if (item?.availableCountries.length && !item.isDigitalProduct && item?.availableValues.length) {
			return `${item.availableValues[0]?.value}, ${item.availableCountries[0].value}`;
		} else if (item?.availableCountries.length && !item.isDigitalProduct) {
			return `${item.availableCountries[0].value}`;
		} else if (item?.colors?.length) {
			return item.colors[0].value;
		} else if (item?.availableValues[0]?.value && item.isDigitalProduct) {
			return item?.availableValues[0]?.value.toLowerCase();
		}
		return '';
	}, [item]);

	const handleDeviceOnly = () => {
		dispatch(setSelectedItems({ planType: PlanType.DEVICE_ONLY, wrongDeviceChoosen: false }));
	};

	const userIsBusiness = useMemo(() => {
		if (allowedAccountCategories && selectedAccount && planType === PlanType.WITH_PLAN) {
			return !allowedAccountCategories.includes(selectedAccount.accountCategory || '');
		}
		return false;
	}, [selectedAccount]);

	const showNoPricesWarning = useMemo(() => {
		return (
			(planType === PlanType.WITH_PLAN ||
				((installmentPeriod === '12' || installmentPeriod === '24') && isBuyNowPayLaterFlow)) &&
			!selectedProduct?.installmentValue &&
			!userIsCorporate
		);
	}, [selectedProduct, planType, userIsCorporate]);

	const getWarningMessage = (errorMessage: string[]) => {
		if (errorMessage?.includes('8920100')) {
			return translate('product.details.plan.edit.warning.8920100');
		} else if (errorMessage?.includes('8920101')) {
			return translate('product.details.plan.edit.warning.8920101');
		} else if (errorMessage?.includes('8920102')) {
			return translate('product.details.plan.edit.warning.8920102');
		} else if (errorMessage?.includes('8920103')) {
			return translate('product.details.plan.edit.warning.8920103');
		} else if (errorMessage?.includes('8920104')) {
			return translate(
				'product.details.plan.edit.warning.8920104',
				isB2b2c || isB2b ? CUSTOMER_B2B2C_CARE_PHONE : CUSTOMER_CARE_PHONE
			);
		} else if (errorMessage?.includes('8920105')) {
			return translate('product.details.plan.edit.warning.8920105');
		} else if (errorMessage?.includes('8920106')) {
			return translate('product.details.plan.edit.warning.8920106');
		} else if (errorMessage?.includes('8920004')) {
			return translate('product.details.plan.edit.warning.8920004');
		} else if (showTwoMonthWarning) {
			return translate('product.details.plan.edit.warning.two-month-installment');
		} else if (showTwoDeviceAccessoriesWarning) {
			return translate('product.details.plan.edit.warning.two-device-accessories-installment');
		} else if (showTwoMobileDeviceWarning) {
			return translate('product.details.plan.edit.warning.two-mobile-device-installment');
		}
		return '';
	};

	const getWarningMessageForInstallmentAmount = (errorCode: string | undefined) => {
		switch (errorCode) {
			case 'DU02':
			case 'PS05':
			case 'PS06':
			case 'PS07':
			case 'PS08':
				return translate('product.details.plan.edit.not.eligible');
			case 'PS04':
				return translate('product.details.plan.edit.no.credit.limit');
			default:
				return translate('product.details.plan.edit.warning');
		}
	};

	const pathname = window.location.href.toLocaleLowerCase();

	const isDifferentPlan =
		currentPlan?.name?.toLowerCase() !== selectedPlan?.name?.toLowerCase() &&
		currentPlan?.name?.toLowerCase() !== selectedPlan?.shortDescription?.toLowerCase();

	const selectedPlanComponent = () => (
		<ConditionalRender
			show={
				!!selectedPlan &&
				!outOfStock &&
				!!selectedProduct &&
				!accessoryWithPlan &&
				!mobileDeviceWithPlan &&
				!tabletWithHBBWFBBPlan &&
				!accessoryWithHBBWFBBPlan &&
				!userIsCorporate
			}
			onTrue={() => (
				<ItemWrapper key={selectedPlan?.uniqueID}>
					<Icon name="eSim" height={40} width={40} />
					<ItemDescriptionWrapper>
						<ConditionalRender
							show={!!currentPlan}
							onTrue={() => (
								<PlanTitleWrapper>
									<TitleWrapper isDifferentPlan={isDifferentPlan}>
										<ItemTitle textCut={isDifferentPlan}>{currentPlan?.name}</ItemTitle>
									</TitleWrapper>
									<ConditionalRender
										show={isDifferentPlan}
										onTrue={() => (
											<>
												<IconWrapper>
													<Icon name="arrowSwipe" width={16} height={16} />
												</IconWrapper>
												<TitleWrapper>
													<ItemTitle textCut>
														{(selectedPlan?.name || selectedPlan?.shortDescription) &&
														(selectedPlan?.name || selectedPlan?.shortDescription).length <
															12
															? selectedPlan?.name || selectedPlan?.shortDescription
															: selectedPlan?.name || selectedPlan?.shortDescription}
													</ItemTitle>
												</TitleWrapper>
											</>
										)}
									/>
								</PlanTitleWrapper>
							)}
							onFalse={() => <ItemTitle textCut>{currentPlan?.name}</ItemTitle>}
						/>
						<ItemDescription>
							{!newPlanIsHigher && selectedPlan?.duration
								? translate('product.cart.plan.duration.renewal', selectedPlan?.duration)
								: selectedPlan?.duration
								? translate('product.cart.plan.duration.upgrade', selectedPlan?.duration ?? '-')
								: translate('product.cart.plan.no.commitment')}
						</ItemDescription>
					</ItemDescriptionWrapper>
				</ItemWrapper>
			)}
		/>
	);
	const isInstallmentAmountError = errorCodeForInstallmentAmount === '' ? false : true;
	const warning = () => (
		<Row>
			<BusyIndicator listener={[ADD_TO_CART_BI]} withoutLoader>
				<PlanEditWarning>
					<Icon name="warning" width={ICON_SIZE_40} height={ICON_SIZE_40} fill="primary" />
					<PlanEditWarningTitle>
						{!!voucherWarning
							? voucherWarning
							: getWarningMessage(backendWarnings) !== ''
							? getWarningMessage(backendWarnings)
							: userIsBusiness
							? translate('product.details.plan.edit.warning.block.business')
							: userHaveLegacyPlan
							? translate('product.details.plan.edit.warning.legacy.plan')
							: showNotValidWarning
							? translate('product.details.plan.edit.wrong.plan.selected')
							: wrongDeviceChoosen
							? translate('product.details.plan.edit.warning.wrong.device')
							: addToCartWarning || showNoPricesWarning
							? translate('product.details.generic.warning')
							: isInstallmentAmountError
							? getWarningMessageForInstallmentAmount(errorCodeForInstallmentAmount)
							: translate('product.details.plan.edit.warning')}
					</PlanEditWarningTitle>
					<WarningSubtitle>
						{showNotValidWarning
							? translate('product.details.plan.edit.warning.select.other.plan')
							: translate('product.details.plan.edit.warning.buy.device.only')}
						<PlanEditWarningDescriptionUnderline onClick={handleDeviceOnly}>
							{translate('product.details.plan.edit.warning.device.only.underline')}
						</PlanEditWarningDescriptionUnderline>
						{translate(
							!!profileHasMultiAccount
								? 'product.details.plan.edit.warning.device.only.other.number'
								: 'product.details.plan.edit.warning.device.only',
							isB2b2c || isB2b ? CUSTOMER_B2B2C_CARE_PHONE : CUSTOMER_CARE_PHONE
						)}
					</WarningSubtitle>
				</PlanEditWarning>
			</BusyIndicator>
		</Row>
	);

	const selectNumber = () => (
		<Row>
			<SelectNumberWarning>
				<Icon name="msgInfo" width={ICON_SIZE_40} height={ICON_SIZE_40} fill="black87" />
				<SelectNumberTitle>{translate('product.details.plan.select-number.warning')}</SelectNumberTitle>
			</SelectNumberWarning>
		</Row>
	);

	useEffect(() => {
		if (showNotValidWarning || showWarning || addToCartWarning) {
			TagManager.dataLayer({
				dataLayer: {
					event: 'GTM Logging',
					component: 'ProductCartCard',
					backendWarnings: backendWarnings.toString(),
					showTwoMonthWarning,
					planType,
					activeDeviceAccessoriesInstallmentId,
					accessoryWithPlan,
					allowedAccountCategories: allowedAccountCategories?.toString(),
					selectedAccount: !!selectedAccount,
					accountId: selectedAccount?.id,
					selectedAccountId: selectedAccount?.accountId,
					mobileDeviceWithPlan,
					oldDeviceCommitmentId,
					userHaveLegacyPlan,
					showNotValidWarning,
					wrongDeviceChoosen,
					userId: getUserId(),
				},
			});
		}
	}, [showNotValidWarning, showWarning]);

	const lastMonthConditionalRender = useMemo(() => {
		return (
			Boolean(selectedProduct?.isBNPL) &&
			!monthlyBusinessPrice &&
			!userIsCorporate &&
			!showNoPricesWarning &&
			!notChosenNumberWarning &&
			!outOfStock &&
			(!!selectedFilters?.installmentPeriod ||
				!!monthlyPrice ||
				(!!calculatedProduct?.device?.deviceCommitments?.installmentMonths &&
					!!calculatedProduct?.device?.saleDetails?.totalAmount)) &&
			(selectedProduct?.installmentPeriod === '12' || selectedProduct?.installmentPeriod === '24')
		);
	}, [
		selectedProduct?.isBNPL,
		monthlyBusinessPrice,
		userIsCorporate,
		showNoPricesWarning,
		notChosenNumberWarning,
		outOfStock,
		selectedFilters?.installmentPeriod,
		monthlyPrice,
		calculatedProduct?.device?.deviceCommitments?.installmentMonths,
		calculatedProduct?.device?.saleDetails?.totalAmount,
		selectedProduct?.installmentPeriod,
	]);

	// this installmentAmount is only applicable Non bnpl user eg. green and predictive Scoring.
	const nonBnplInstallmentAmount = Number(selectedProduct?.installmentValue ?? 0) + Number(selectedPlan?.price ?? 0);
	return (
		<SelectedItemsWrapper>
			<ConditionalRender
				show={bottomMode && !pathname.includes('microsoft-automation-services')}
				onTrue={() => (
					<ConditionalRender
						show={
							addToCartWarning ||
							showNotValidWarning ||
							showWarning ||
							!!voucherWarning ||
							showNoPricesWarning ||
							notChosenNumberWarning ||
							isInstallmentAmountError
						}
						onTrue={notChosenNumberWarning ? selectNumber : warning}
						onFalse={() => (
							<BottomSectionCartWrapper>
								<BottomSectionPricingWithButton>
									<ConditionalRender
										show={
											!showWarning && !showNotValidWarning && !voucherWarning && !addToCartWarning
										}
										onTrue={() => (
											<BottomColumn>
												<ConditionalRender
													show={
														isBuyNowPayLaterFlow &&
														(selectedProduct?.installmentPeriod === '24' ||
															selectedProduct?.installmentPeriod === '12') &&
														!userIsCorporate
													}
													onTrue={() => (
														<PriceBlockContainer isFirst={true}>
															<PriceAccordion isBnpl>
																<PriceHeaderWrapper>
																	<PriceIconWrapper>
																		<ParagraphSBold>
																			{translate('product.cart-card.upfront')}
																		</ParagraphSBold>
																	</PriceIconWrapper>
																	<PriceTagWrapper>
																		<SmallOMRWrapper>
																			{translate('omr')}
																		</SmallOMRWrapper>
																		<BodyMBold>
																			{roundMoney(
																				selectedProduct?.priceDisplay ?? 0
																			)}
																		</BodyMBold>
																	</PriceTagWrapper>
																</PriceHeaderWrapper>
																<VatWrapper>
																	<Info color="black54">
																		{translate(
																			'product.cart-card.with-vat',
																			selectedProduct?.vatPercent ?? 0
																		)}
																	</Info>
																	<InfoBold color="black54">
																		{`${translate('omr')} ${roundMoney(
																			selectedProduct?.priceOffer ?? 0
																		)}`}
																	</InfoBold>
																</VatWrapper>
															</PriceAccordion>
														</PriceBlockContainer>
													)}
												/>
												<ConditionalRender
													show={
														(isBuyNowPayLaterFlow &&
															!(
																selectedProduct?.installmentPeriod === '12' ||
																selectedProduct?.installmentPeriod === '24'
															)) ||
														(!isBuyNowPayLaterFlow &&
															!outOfStock &&
															!!upfrontPrice &&
															!monthlyBusinessPrice &&
															!(!!userIsCorporate && planType === PlanType.WITH_PLAN))
													}
													onTrue={() => (
														<PriceBlockContainer isFirst={true}>
															<PriceAccordion
																onClick={() => (
																	setShowFullUpfront(!showFullUpfront),
																	setShowFullMonthly(false),
																	setShowInstallements(false)
																)}
															>
																<PriceHeaderWrapper>
																	<PriceIconWrapper>
																		<ArrowIconContainer
																			isShowFull={showFullUpfront}
																		>
																			<Icon name="arrowDown" fill="black" />
																		</ArrowIconContainer>
																		<ParagraphSBold>
																			{translate('product.cart-card.upfront')}
																		</ParagraphSBold>
																	</PriceIconWrapper>
																	<PriceTagWrapper>
																		<SmallOMRWrapper>
																			{translate('omr')}
																		</SmallOMRWrapper>
																		<BodyMBold>
																			{roundMoney(
																				selectedProduct?.priceDisplay ?? 0
																			)}
																		</BodyMBold>
																	</PriceTagWrapper>
																</PriceHeaderWrapper>
																<VatWrapper>
																	<Info color="black54">
																		{translate(
																			'product.cart-card.with-vat',
																			selectedProduct?.vatPercent ?? 0
																		)}
																	</Info>
																	<InfoBold color="black54">
																		{`${translate('omr')} ${roundMoney(
																			selectedProduct?.priceOffer ?? 0
																		)}`}
																	</InfoBold>
																</VatWrapper>
															</PriceAccordion>
															<ConditionalRender
																show={showFullUpfront}
																onTrue={() => (
																	<PriceContentWrapper isShown={showFullUpfront}>
																		<PriceContentRowWrapper isBottomMode={true}>
																			<VerticalDivider />
																			<PriceDetailsWrapper>
																				<BodyS>{selectedProduct?.name}</BodyS>
																				<CaptionBigger>
																					<ConditionalRender
																						show={
																							!!selectedProduct?.attributes.find(
																								(item) =>
																									item.identifier ===
																									DEF_COLOR
																							)?.values[0].value
																						}
																						onTrue={() => (
																							<>
																								{`${
																									selectedProduct?.attributes.find(
																										(item) =>
																											item.identifier ===
																											DEF_COLOR
																									)?.values[0].value
																								}, `}
																							</>
																						)}
																					/>
																					{
																						selectedProduct?.attributes.find(
																							(item) =>
																								item.identifier ===
																								DEF_CAPACITY
																						)?.values[0].value
																					}
																				</CaptionBigger>
																				<BodySBold>
																					{`${translate('omr')} ${roundMoney(
																						selectedProduct?.priceDisplay ??
																							0
																					)}`}
																				</BodySBold>
																			</PriceDetailsWrapper>
																		</PriceContentRowWrapper>
																		<PriceContentRowWrapper isBottomMode={true}>
																			<VerticalDivider />
																			<PriceDetailsWrapper>
																				<VatRow>
																					<BodyS>
																						{translate(
																							'product.cart-card.vat-from-device'
																						)}
																					</BodyS>
																					<ConditionalRender
																						show={
																							selectedProduct?.priceDisplay ===
																							0
																						}
																						onTrue={() => (
																							<Tooltip
																								isLowerPinTooltip={true}
																								margin={
																									isRTL()
																										? '-120px -180px 0px 0px'
																										: '-120px 0px 0px -60px'
																								}
																								withPin
																								width={208}
																								text={translate(
																									'product.cart-card.device-vat.tooltip'
																								)}
																							>
																								<TooltipIconWrapper>
																									<Icon
																										name="questionMarkCircle"
																										fill="black87"
																									/>
																								</TooltipIconWrapper>
																							</Tooltip>
																						)}
																					/>
																				</VatRow>
																				<BodySBold>
																					{`${translate('omr')} ${roundMoney(
																						selectedProduct?.pricesWithVat
																							.vatValue ?? 0
																					)}`}
																				</BodySBold>
																			</PriceDetailsWrapper>
																		</PriceContentRowWrapper>
																	</PriceContentWrapper>
																)}
															/>
														</PriceBlockContainer>
													)}
												/>
												<ConditionalRender
													show={!outOfStock && !!upfrontBusinessPrice}
													onTrue={() => (
														<ConditionalRender
															show={!!userIsCorporate && planType === PlanType.WITH_PLAN}
															onTrue={() => (
																<>
																	<PriceBlockContainer isFirst={true}>
																		<PriceAccordion
																			onClick={() => (
																				setShowFullUpfront(!showFullUpfront),
																				setShowFullMonthly(false)
																			)}
																		>
																			<PriceHeaderWrapper>
																				<PriceIconWrapper>
																					<ArrowIconContainer
																						isShowFull={showFullUpfront}
																					>
																						<Icon
																							name="arrowDown"
																							fill="black"
																						/>
																					</ArrowIconContainer>
																					<ParagraphSBold>
																						{translate(
																							'product.cart-card.upfront'
																						)}
																					</ParagraphSBold>
																				</PriceIconWrapper>
																				<PriceTagWrapper>
																					<SmallOMRWrapper>
																						{translate('omr')}
																					</SmallOMRWrapper>
																					<BodyMBold>
																						{roundMoney(
																							calculatedProduct?.device
																								?.saleDetails
																								?.taxableAmount || 0
																						)}
																					</BodyMBold>
																				</PriceTagWrapper>
																			</PriceHeaderWrapper>
																			<VatWrapper>
																				<Info color="black54">
																					{translate(
																						'product.cart-card.with-vat',
																						calculatedProduct?.device
																							?.saleDetails
																							?.vatPercentage || 0
																					)}
																				</Info>
																				<InfoBold color="black54">
																					<ConditionalRender
																						show={
																							!!Number(
																								calculatedProduct
																									?.device
																									?.deviceCommitments
																									?.installmentMonths
																							)
																						}
																						onTrue={() => (
																							<>
																								{`${translate(
																									'omr'
																								)} ${roundMoney(
																									calculatedProduct
																										?.device
																										?.saleDetails
																										?.totalAmount ||
																										0
																								)}`}
																							</>
																						)}
																						onFalse={() => (
																							<StrikethroughWrapper>{`${translate(
																								'omr'
																							)} ${roundMoney(
																								calculatedProduct
																									?.device
																									?.saleDetails
																									?.standardPrice || 0
																							)}`}</StrikethroughWrapper>
																						)}
																					/>
																				</InfoBold>
																			</VatWrapper>
																			<ConditionalRender
																				show={
																					!Number(
																						calculatedProduct?.device
																							?.deviceCommitments
																							?.installmentMonths
																					)
																				}
																				onTrue={() => (
																					<VatWrapper>
																						<Info color="primary">
																							{translate(
																								'product.cart-card.after-discount'
																							)}
																						</Info>
																						<InfoBold color="primary">
																							{`${translate(
																								'omr'
																							)} ${roundMoney(
																								calculatedProduct
																									?.device
																									?.saleDetails
																									?.totalAmount || 0
																							)}`}
																						</InfoBold>
																					</VatWrapper>
																				)}
																			/>
																		</PriceAccordion>
																		<ConditionalRender
																			show={showFullUpfront}
																			onTrue={() => (
																				<PriceContentWrapper
																					isShown={showFullUpfront}
																				>
																					<PriceContentRowWrapper
																						isBottomMode={true}
																					>
																						<VerticalDivider />
																						<PriceDetailsWrapper>
																							<BodyS>
																								{selectedProduct?.name}
																							</BodyS>
																							<CaptionBigger>
																								<ConditionalRender
																									show={
																										!!selectedProduct?.attributes.find(
																											(item) =>
																												item.identifier ===
																												DEF_COLOR
																										)?.values[0]
																											.value
																									}
																									onTrue={() => (
																										<>
																											{`${
																												selectedProduct?.attributes.find(
																													(
																														item
																													) =>
																														item.identifier ===
																														DEF_COLOR
																												)
																													?.values[0]
																													.value
																											}, `}
																										</>
																									)}
																								/>
																								{
																									selectedProduct?.attributes.find(
																										(item) =>
																											item.identifier ===
																											DEF_CAPACITY
																									)?.values[0].value
																								}
																							</CaptionBigger>
																							<BodySBold>
																								{`${translate(
																									'omr'
																								)} ${roundMoney(
																									calculatedProduct
																										?.device
																										?.saleDetails
																										?.taxableAmount ||
																										0
																								)}`}
																							</BodySBold>
																						</PriceDetailsWrapper>
																					</PriceContentRowWrapper>
																					<PriceContentRowWrapper
																						isBottomMode={true}
																					>
																						<VerticalDivider />
																						<PriceDetailsWrapper>
																							<VatRow>
																								<BodyS>
																									{translate(
																										'product.cart-card.vat-from-device'
																									)}
																								</BodyS>
																								<ConditionalRender
																									show={
																										Number(
																											calculatedProduct
																												?.device
																												?.saleDetails
																												?.taxableAmount
																										) === 0
																									}
																									onTrue={() => (
																										<Tooltip
																											isLowerPinTooltip={
																												true
																											}
																											margin={
																												isRTL()
																													? '-120px -180px 0px 0px'
																													: '-120px 0px 0px -60px'
																											}
																											withPin
																											width={208}
																											text={translate(
																												'product.cart-card.device-vat.tooltip'
																											)}
																										>
																											<TooltipIconWrapper>
																												<Icon
																													name="questionMarkCircle"
																													fill="black87"
																												/>
																											</TooltipIconWrapper>
																										</Tooltip>
																									)}
																								/>
																							</VatRow>
																							<BodySBold>
																								{`${translate(
																									'omr'
																								)} ${roundMoney(
																									calculatedProduct
																										?.device
																										?.saleDetails
																										?.vatAmount || 0
																								)}`}
																							</BodySBold>
																						</PriceDetailsWrapper>
																					</PriceContentRowWrapper>
																				</PriceContentWrapper>
																			)}
																		/>
																	</PriceBlockContainer>
																</>
															)}
														/>
													)}
												/>
												<ConditionalRender
													show={
														isBuyNowPayLaterFlow &&
														(selectedProduct?.installmentPeriod === '24' ||
															selectedProduct?.installmentPeriod === '12') &&
														!userIsCorporate
													}
													onTrue={() => (
														<>
															<PriceBlockContainer isBottom={true}>
																<PriceAccordion isBnpl>
																	<PriceHeaderWrapper>
																		<PriceIconWrapper>
																			<ParagraphSBold>
																				{selectedProduct?.installmentPeriod ===
																				'12'
																					? translate(
																							'product.cart-card.11months'
																					  )
																					: translate(
																							'product.cart-card.23months'
																					  )}
																			</ParagraphSBold>
																		</PriceIconWrapper>
																		<PriceTagWrapper>
																			<SmallOMRWrapper>
																				{translate('omr')}
																			</SmallOMRWrapper>
																			<BodyMBold>
																				{roundMoney(
																					Number(
																						selectedProduct?.installmentValue
																					) ?? 0
																				)}
																			</BodyMBold>
																		</PriceTagWrapper>
																	</PriceHeaderWrapper>
																</PriceAccordion>
															</PriceBlockContainer>
															<PriceBlockContainer isBottom={true}>
																<PriceAccordion isBnpl>
																	<PriceHeaderWrapper>
																		<PriceIconWrapper>
																			<ParagraphSBold>
																				{translate('product.cart-card.last')}
																			</ParagraphSBold>
																		</PriceIconWrapper>
																		<PriceTagWrapper>
																			<SmallOMRWrapper>
																				{translate('omr')}
																			</SmallOMRWrapper>
																			<BodyMBold>
																				{roundMoney(
																					Number(
																						selectedProduct?.installmentValueFinal
																					) ?? 0
																				)}
																			</BodyMBold>
																		</PriceTagWrapper>
																	</PriceHeaderWrapper>
																</PriceAccordion>
															</PriceBlockContainer>
														</>
													)}
												/>
												<ConditionalRender
													show={
														!isBuyNowPayLaterFlow &&
														!monthlyBusinessPrice &&
														!userIsCorporate &&
														!outOfStock &&
														(!!monthlyPrice ||
															(!!calculatedProduct?.device?.deviceCommitments
																?.installmentMonths &&
																!!calculatedProduct?.device?.saleDetails
																	?.totalAmount)) &&
														(selectedProduct?.installmentPeriod === '12' ||
															selectedProduct?.installmentPeriod === '24')
													}
													onTrue={() => (
														<PriceBlockContainer isBottom={true}>
															<PriceAccordion
																onClick={() => (
																	setShowFullUpfront(false),
																	setShowFullMonthly(!showFullMonthly),
																	setShowInstallements(false)
																)}
															>
																<PriceHeaderWrapper>
																	<PriceIconWrapper>
																		<ArrowIconContainer
																			isShowFull={showFullMonthly}
																		>
																			<Icon name="arrowDown" fill="black" />
																		</ArrowIconContainer>
																		<ParagraphSBold>
																			{selectedProduct?.installmentPeriod === '24'
																				? translate('product.cart-card.24mo')
																				: translate(
																						'product.cart-card.for12mo'
																				  )}
																		</ParagraphSBold>
																	</PriceIconWrapper>
																	<PriceTagWrapper>
																		<SmallOMRWrapper>
																			{translate('omr')}
																		</SmallOMRWrapper>
																		<BodyMBold>
																			{roundMoney(
																				(Number(
																					selectedProduct?.installmentValue
																				) ?? 0) +
																					Number(selectedPlan?.price ?? 0)
																			)}
																		</BodyMBold>
																	</PriceTagWrapper>
																</PriceHeaderWrapper>
																<VatWrapper>
																	<Info color="black54">
																		{translate(
																			'product.cart-card.with-vat',
																			selectedPlan?.vatPercent ?? 0
																		)}
																	</Info>
																	<InfoBold color="black54">
																		{`${translate('omr')} ${roundMoney(
																			(Number(
																				selectedProduct?.installmentValue
																			) ?? 0) +
																				(Number(selectedPlan?.price) ?? 0) +
																				Number(selectedPlan?.vatValue ?? 0)
																		)}`}
																	</InfoBold>
																</VatWrapper>
															</PriceAccordion>
															<ConditionalRender
																show={showFullMonthly}
																onTrue={() => (
																	<PriceContentWrapper isShown={showFullMonthly}>
																		<PriceContentRowWrapper isBottomMode={true}>
																			<VerticalDivider />
																			<PriceDetailsWrapper>
																				<BodyS>{selectedProduct?.name}</BodyS>
																				<CaptionBigger>
																					<ConditionalRender
																						show={
																							!!selectedProduct?.attributes.find(
																								(item) =>
																									item.identifier ===
																									DEF_COLOR
																							)?.values[0].value
																						}
																						onTrue={() => (
																							<>
																								{`${
																									selectedProduct?.attributes.find(
																										(item) =>
																											item.identifier ===
																											DEF_COLOR
																									)?.values[0].value
																								}, `}
																							</>
																						)}
																					/>
																					{
																						selectedProduct?.attributes.find(
																							(item) =>
																								item.identifier ===
																								DEF_CAPACITY
																						)?.values[0].value
																					}
																				</CaptionBigger>
																				<BodySBold>
																					{`${translate('omr')} ${roundMoney(
																						Number(
																							selectedProduct?.installmentValue
																						) ?? 0
																					)}`}
																				</BodySBold>
																			</PriceDetailsWrapper>
																		</PriceContentRowWrapper>
																		<PriceContentRowWrapper isBottomMode={true}>
																			<VerticalDivider />
																			<PriceDetailsWrapper>
																				<BodyS>
																					{(selectedPlan?.name ||
																						selectedPlan?.shortDescription) ??
																						''}
																				</BodyS>
																				<BodySBold>
																					{`${translate('omr')} ${roundMoney(
																						selectedPlan?.price ?? 0
																					)}`}
																				</BodySBold>
																			</PriceDetailsWrapper>
																		</PriceContentRowWrapper>
																		<PriceContentRowWrapper isBottomMode={true}>
																			<VerticalDivider />
																			<PriceDetailsWrapper>
																				<VatRow>
																					<BodyS>
																						{translate(
																							'product.cart-card.plan-vat'
																						)}
																					</BodyS>
																					<Tooltip
																						isLowerPinTooltip={true}
																						margin={
																							isRTL()
																								? '-120px -180px 0px 0px'
																								: '-120px 0px 0px -60px'
																						}
																						withPin
																						width={208}
																						text={translate(
																							'product.cart-card.plan-vat.tooltip'
																						)}
																					>
																						<TooltipIconWrapper>
																							<Icon
																								name="questionMarkCircle"
																								fill="black87"
																							/>
																						</TooltipIconWrapper>
																					</Tooltip>
																				</VatRow>
																				<BodySBold>
																					{`${translate('omr')} ${roundMoney(
																						selectedPlan?.vatValue ?? 0
																					)}`}
																				</BodySBold>
																			</PriceDetailsWrapper>
																		</PriceContentRowWrapper>
																	</PriceContentWrapper>
																)}
															/>
														</PriceBlockContainer>
													)}
												/>
												<ConditionalRender
													show={
														!!monthlyBusinessPrice &&
														!!userIsCorporate &&
														!outOfStock &&
														((!userIsCorporate && !!monthlyPrice) ||
															(!!monthlyBusinessPrice &&
																!!calculatedProduct?.device?.deviceCommitments
																	?.installmentMonths &&
																!!calculatedProduct?.device?.saleDetails?.totalAmount))
													}
													onTrue={() => (
														<ConditionalRender
															show={!!userIsCorporate && planType === PlanType.WITH_PLAN}
															onTrue={() => (
																<PriceBlockContainer isFirst={true}>
																	<PriceAccordion
																		onClick={() => (
																			setShowFullMonthly(false),
																			setShowInstallements(!showInstallements)
																		)}
																	>
																		<PriceHeaderWrapper>
																			<PriceIconWrapper>
																				<ArrowIconContainer
																					isShowFull={showInstallements}
																				>
																					<Icon
																						name="arrowDown"
																						fill="black"
																					/>
																				</ArrowIconContainer>
																				<ParagraphSBold>
																					{translate(
																						'payment.summary.description.installments'
																					)}
																				</ParagraphSBold>
																			</PriceIconWrapper>
																			<PriceTagWrapper>
																				<SmallOMRWrapper>
																					{translate('omr')}
																				</SmallOMRWrapper>
																				<BodyMBold>
																					<StrikethroughWrapper>
																						{roundMoney(
																							(Number(
																								calculatedProduct
																									?.device
																									?.saleDetails
																									?.standardPrice
																							) || 0) /
																								(Number(
																									calculatedProduct
																										?.device
																										?.deviceCommitments
																										?.installmentMonths
																								) || 0)
																						)}
																					</StrikethroughWrapper>
																				</BodyMBold>
																			</PriceTagWrapper>
																		</PriceHeaderWrapper>
																		<VatWrapper>
																			<Info color="primary">
																				{translate(
																					'product.cart-card.after-discount'
																				)}
																			</Info>
																			<InfoBold color="primary">
																				{`${translate('omr')} ${roundMoney(
																					calculatedProduct?.device
																						?.deviceCommitments
																						?.installmentAmount || 0
																				)}`}
																			</InfoBold>
																		</VatWrapper>
																	</PriceAccordion>
																	<ConditionalRender
																		show={showInstallements}
																		onTrue={() => (
																			<PriceContentWrapper
																				isShown={showInstallements}
																			>
																				<PriceContentRowWrapper
																					isBottomMode={true}
																				>
																					<VerticalDivider />
																					<PriceDetailsWrapper>
																						<BodyS>
																							{selectedProduct?.name}
																						</BodyS>
																						<CaptionBigger>
																							<ConditionalRender
																								show={
																									!!selectedProduct?.attributes.find(
																										(item) =>
																											item.identifier ===
																											DEF_COLOR
																									)?.values[0].value
																								}
																								onTrue={() => (
																									<>
																										{`${
																											selectedProduct?.attributes.find(
																												(
																													item
																												) =>
																													item.identifier ===
																													DEF_COLOR
																											)?.values[0]
																												.value
																										}, `}
																									</>
																								)}
																							/>
																							{
																								selectedProduct?.attributes.find(
																									(item) =>
																										item.identifier ===
																										DEF_CAPACITY
																								)?.values[0].value
																							}
																						</CaptionBigger>
																						<BodySBold>
																							{`${translate(
																								'omr'
																							)} ${roundMoney(
																								calculatedProduct
																									?.device
																									?.deviceCommitments
																									?.installmentAmount ||
																									0
																							)}`}
																						</BodySBold>
																					</PriceDetailsWrapper>
																				</PriceContentRowWrapper>
																				<PriceContentRowWrapper
																					isBottomMode={true}
																				></PriceContentRowWrapper>
																			</PriceContentWrapper>
																		)}
																	/>
																</PriceBlockContainer>
															)}
															onFalse={() => (
																<PriceContainer>
																	<PriceTag price={monthlyPrice} />
																	<VatInfo>
																		{translate(
																			'navbar.cart.vat',
																			selectedProduct?.pricesWithVat.vatPercent ??
																				''
																		)}
																	</VatInfo>
																</PriceContainer>
															)}
														/>
													)}
												/>
											</BottomColumn>
										)}
									/>
									<BottomSectionButtonWrapper>
										<ButtonContent>
											<Button
												uniqueId="product.cart-card.add-to-cart"
												disabled={outOfStock || disableAddToCartButton}
												listener={[ADD_TO_CART_BI]}
												onClick={handleAddToCart}
												variant={preorder ? ButtonVariant.DEFAULT : ButtonVariant.PRIMARY}
												size={ButtonSize.LARGE}
												width={ButtonWidth.FLEX}
											>
												{translate('product.cart-card.add-to-cart')}
											</Button>
											<ConditionalRender
												show={!screen('xs')}
												onTrue={() => (
													<SecureTransactionWrapper isBottom={true}>
														<Icon
															name="lock"
															fill="black54"
															width={ICON_SIZE}
															height={ICON_SIZE}
														/>
														<SecureTransactionText color="black54">
															{translate('product.cart-card.transaction.part-1')}
															<HighlightedText color="black54">
																{translate('product.cart-card.transaction.part-2')}
															</HighlightedText>
														</SecureTransactionText>
													</SecureTransactionWrapper>
												)}
											/>
										</ButtonContent>
									</BottomSectionButtonWrapper>
								</BottomSectionPricingWithButton>
							</BottomSectionCartWrapper>
						)}
					/>
				)}
				onFalse={() => (
					<ConditionalRender
						show={!pathname.includes('microsoft-automation-services')}
						onTrue={() => (
							<>
								<ConditionalRender
									show={!!userIsCorporate}
									onTrue={() => (
										<DeviceWithPlanWrapper>
											<span>
												{translate('product.cart-card.top-label-1')}

												<CaptionHigherBold>
													{!!selectedProduct?.name?.length &&
													selectedProduct?.name?.length < 18
														? selectedProduct?.name
														: `${selectedProduct?.name?.substring(0, 17)}...`}
												</CaptionHigherBold>
											</span>
										</DeviceWithPlanWrapper>
									)}
								/>
								<ConditionalRender
									show={
										addToCartWarning ||
										showNotValidWarning ||
										showWarning ||
										!!voucherWarning ||
										showNoPricesWarning ||
										notChosenNumberWarning
									}
									onTrue={notChosenNumberWarning ? selectNumber : warning}
									onFalse={() => (
										<>
											<ConditionalRender
												show={
													!!selectedPlan &&
													!outOfStock &&
													!!selectedProduct &&
													!userIsCorporate
												}
												onTrue={() => (
													<>
														<ConditionalRender
															show={isDifferentPlan}
															onTrue={() => (
																<DeviceWithPlanWrapper>
																	<span>
																		{translate('product.cart-card.top-label-1')}

																		<CaptionHigherBold>
																			{!!selectedProduct?.name?.length &&
																			selectedProduct?.name?.length < 18
																				? selectedProduct?.name
																				: `${selectedProduct?.name?.substring(
																						0,
																						17
																				  )}...`}
																		</CaptionHigherBold>
																	</span>
																	<span>
																		{translate('product.cart-card.top-label-3')}

																		<CaptionHigherBold>
																			{(selectedPlan?.name ||
																				selectedPlan?.shortDescription) &&
																			(
																				selectedPlan?.name ||
																				selectedPlan?.shortDescription
																			).length < 12
																				? selectedPlan?.name ||
																				  selectedPlan?.shortDescription
																				: selectedPlan?.name ||
																				  selectedPlan?.shortDescription}
																		</CaptionHigherBold>
																	</span>
																</DeviceWithPlanWrapper>
															)}
															onFalse={() => (
																<DeviceWithPlanWrapper>
																	<span>
																		{translate('product.cart-card.top-label-1')}

																		<CaptionHigherBold>
																			{!!selectedProduct?.name?.length &&
																			selectedProduct?.name?.length < 18
																				? selectedProduct?.name
																				: `${selectedProduct?.name?.substring(
																						0,
																						17
																				  )}...`}
																		</CaptionHigherBold>
																	</span>
																	<span>
																		{translate('product.cart-card.top-label-2')}

																		<CaptionHigherBold>
																			{currentPlan?.name}
																		</CaptionHigherBold>
																	</span>
																</DeviceWithPlanWrapper>
															)}
														/>
														<Divider
															color="black12"
															marginTop={16}
															marginBottom={!!preorder ? 24 : 0}
														/>
													</>
												)}
											/>
											<ConditionalRender
												show={outOfStock && !salesLead}
												onTrue={() => (
													<OutOfStockWrapper>
														<Icon
															name="error"
															fill="warning"
															height={WARNING_ICON_SIZE}
															width={WARNING_ICON_SIZE}
														/>
														<OutOfStockDescription>
															{translate('product.cart-card.out-of-stock.description')}
														</OutOfStockDescription>
														<ConditionalRender
															show={!!selectedProduct}
															onTrue={() => (
																<OutOfStockItemTitle key={selectedProduct?.uniqueID}>
																	{selectedProduct?.name}
																</OutOfStockItemTitle>
															)}
														/>
													</OutOfStockWrapper>
												)}
											/>

											<ConditionalRender
												show={!!preorder}
												onTrue={() => (
													<CartAdditionalInfo
														preorder={{
															preorder: !!preorder,
															preorderDate: selectedProduct?.preorderDate,
														}}
													/>
												)}
											/>
										</>
									)}
								/>
								<SummaryWrapper isCorporate={!!userIsCorporate} isPreorder={!!preorder}>
									<ConditionalRender
										show={
											!addToCartWarning &&
											!showWarning &&
											!showNotValidWarning &&
											!voucherWarning &&
											!showNoPricesWarning &&
											!notChosenNumberWarning
										}
										onTrue={() => (
											<>
												<ConditionalRender
													show={
														isBuyNowPayLaterFlow &&
														(selectedProduct?.installmentPeriod === '24' ||
															selectedProduct?.installmentPeriod === '12') &&
														!userIsCorporate &&
														!(
															(!outOfStock || !!salesLead) &&
															!monthlyBusinessPrice &&
															!(!!userIsCorporate && planType === PlanType.WITH_PLAN)
														)
													}
													onTrue={() => (
														<>
															<PriceBlockContainer>
																<PriceAccordion isBnpl={true}>
																	<PriceHeaderWrapper>
																		<PriceIconWrapper>
																			<ConditionalRender
																				show={screen('lg')}
																				onTrue={() => (
																					<ParagraphSBold>
																						{translate(
																							'product.cart-card.upfront'
																						)}
																					</ParagraphSBold>
																				)}
																				onFalse={() => (
																					<BodyMBold>
																						{translate(
																							'product.cart-card.upfront'
																						)}
																					</BodyMBold>
																				)}
																			/>
																		</PriceIconWrapper>
																		<PriceTagWrapper>
																			<SmallOMRWrapper>
																				{translate('omr')}
																			</SmallOMRWrapper>
																			<BodyMBold>
																				{roundMoney(
																					selectedProduct?.priceDisplay ?? 0
																				)}
																			</BodyMBold>
																		</PriceTagWrapper>
																	</PriceHeaderWrapper>
																	<VatWrapper>
																		<Info color="black54">
																			{translate(
																				'product.cart-card.with-vat',
																				selectedProduct?.vatPercent ?? 0
																			)}
																		</Info>
																		<InfoBold color="black54">
																			{`${translate('omr')} ${roundMoney(
																				selectedProduct?.priceOffer ?? 0
																			)}`}
																		</InfoBold>
																	</VatWrapper>
																</PriceAccordion>
															</PriceBlockContainer>
															<PriceBlockContainer>
																<PriceAccordion isBnpl={true}>
																	<PriceHeaderWrapper>
																		<PriceIconWrapper>
																			<ConditionalRender
																				show={screen('lg')}
																				onTrue={() => (
																					<ParagraphSBold>
																						{selectedProduct?.installmentPeriod ===
																						'12'
																							? translate(
																									'product.cart-card.11months'
																							  )
																							: translate(
																									'product.cart-card.23months'
																							  )}
																					</ParagraphSBold>
																				)}
																				onFalse={() => (
																					<BodyMBold>
																						{selectedProduct?.installmentPeriod ===
																						'12'
																							? translate(
																									'product.cart-card.11months'
																							  )
																							: translate(
																									'product.cart-card.23months'
																							  )}
																					</BodyMBold>
																				)}
																			/>
																		</PriceIconWrapper>
																		<PriceTagWrapper>
																			<SmallOMRWrapper>
																				{translate('omr')}
																			</SmallOMRWrapper>
																			<BodyMBold>
																				{roundMoney(
																					Number(
																						selectedProduct?.installmentValue
																					) ?? 0
																				)}
																			</BodyMBold>
																		</PriceTagWrapper>
																	</PriceHeaderWrapper>
																</PriceAccordion>
															</PriceBlockContainer>
															<PriceBlockContainer>
																<PriceAccordion isBnpl={true}>
																	<PriceHeaderWrapper>
																		<PriceIconWrapper>
																			<ConditionalRender
																				show={screen('lg')}
																				onTrue={() => (
																					<ParagraphSBold>
																						{translate(
																							'product.cart-card.last'
																						)}
																					</ParagraphSBold>
																				)}
																				onFalse={() => (
																					<BodyMBold>
																						{translate(
																							'product.cart-card.last'
																						)}
																					</BodyMBold>
																				)}
																			/>
																		</PriceIconWrapper>
																		<PriceTagWrapper>
																			<SmallOMRWrapper>
																				{translate('omr')}
																			</SmallOMRWrapper>
																			<BodyMBold>
																				{roundMoney(
																					Number(
																						selectedProduct?.installmentValueFinal
																					) ?? 0
																				)}
																			</BodyMBold>
																		</PriceTagWrapper>
																	</PriceHeaderWrapper>
																</PriceAccordion>
															</PriceBlockContainer>
														</>
													)}
													onFalse={() => (
														<>
															<ConditionalRender
																show={
																	(!outOfStock || !!salesLead) &&
																	!monthlyBusinessPrice &&
																	!(
																		!!userIsCorporate &&
																		planType === PlanType.WITH_PLAN
																	)
																}
																onTrue={() => (
																	<PriceBlockContainer salesLead>
																		{!salesLead && (
																			<PriceAccordion
																				onClick={() =>
																					setShowFullUpfront(!showFullUpfront)
																				}
																			>
																				<PriceHeaderWrapper>
																					<PriceIconWrapper>
																						<ArrowIconContainer
																							isShowFull={showFullUpfront}
																						>
																							<Icon
																								name="arrowDown"
																								fill="black"
																							/>
																						</ArrowIconContainer>

																						<ConditionalRender
																							show={screen('lg')}
																							onTrue={() => (
																								<ParagraphSBold>
																									{translate(
																										'product.cart-card.upfront'
																									)}
																								</ParagraphSBold>
																							)}
																							onFalse={() => (
																								<BodyMBold>
																									{translate(
																										'product.cart-card.upfront'
																									)}
																								</BodyMBold>
																							)}
																						/>
																					</PriceIconWrapper>
																					<PriceTagWrapper>
																						<SmallOMRWrapper>
																							{translate('omr')}
																						</SmallOMRWrapper>
																						<BodyMBold>
																							{roundMoney(
																								selectedProduct?.priceDisplay ??
																									0
																							)}
																						</BodyMBold>
																					</PriceTagWrapper>
																				</PriceHeaderWrapper>
																				<VatWrapper>
																					<Info color="black54">
																						{translate(
																							'product.cart-card.with-vat',
																							selectedProduct?.vatPercent ??
																								0
																						)}
																					</Info>
																					<InfoBold color="black54">
																						{`${translate(
																							'omr'
																						)} ${roundMoney(
																							selectedProduct?.priceOffer ??
																								0
																						)}`}
																					</InfoBold>
																				</VatWrapper>
																			</PriceAccordion>
																		)}
																		<ConditionalRender
																			show={showFullUpfront}
																			onTrue={() => (
																				<PriceContentWrapper>
																					<PriceContentRowWrapper>
																						<VerticalDivider />
																						<PriceDetailsWrapper>
																							<BodyS>
																								{selectedProduct?.name}
																							</BodyS>
																							<CaptionBigger>
																								<ConditionalRender
																									show={
																										!!selectedProduct?.attributes.find(
																											(item) =>
																												item.identifier ===
																												DEF_COLOR
																										)?.values[0]
																											.value
																									}
																									onTrue={() => (
																										<>
																											{`${
																												selectedProduct?.attributes.find(
																													(
																														item
																													) =>
																														item.identifier ===
																														DEF_COLOR
																												)
																													?.values[0]
																													.value
																											}, `}
																										</>
																									)}
																								/>
																								{
																									selectedProduct?.attributes.find(
																										(item) =>
																											item.identifier ===
																											DEF_CAPACITY
																									)?.values[0].value
																								}
																							</CaptionBigger>
																							<BodySBold>
																								{`${translate(
																									'omr'
																								)} ${roundMoney(
																									selectedProduct?.priceDisplay ??
																										0
																								)}`}
																							</BodySBold>
																						</PriceDetailsWrapper>
																					</PriceContentRowWrapper>
																					<PriceContentRowWrapper>
																						<VerticalDivider />
																						<PriceDetailsWrapper>
																							<VatRow>
																								<BodyS>
																									{translate(
																										'product.cart-card.vat-from-device'
																									)}
																								</BodyS>
																								<ConditionalRender
																									show={
																										selectedProduct?.priceDisplay ===
																											0 &&
																										Number(
																											selectedProduct
																												?.pricesWithVat
																												.vatValue ??
																												0
																										) !== 0
																									}
																									onTrue={() => (
																										<Tooltip
																											isLowerPinTooltip={
																												true
																											}
																											margin={
																												isRTL()
																													? '-130px -120px 0px 0px'
																													: '-150px 0px 0px -20px'
																											}
																											withPin
																											width={208}
																											text={translate(
																												'product.cart-card.device-vat.tooltip'
																											)}
																										>
																											<TooltipIconWrapper>
																												<Icon
																													name="questionMarkCircle"
																													fill="black87"
																												/>
																											</TooltipIconWrapper>
																										</Tooltip>
																									)}
																								/>
																							</VatRow>
																							<BodySBold>
																								{`${translate(
																									'omr'
																								)} ${roundMoney(
																									selectedProduct
																										?.pricesWithVat
																										.vatValue ?? 0
																								)}`}
																							</BodySBold>
																						</PriceDetailsWrapper>
																					</PriceContentRowWrapper>
																				</PriceContentWrapper>
																			)}
																		/>
																	</PriceBlockContainer>
																)}
															/>
															<ConditionalRender
																show={!outOfStock && !!upfrontBusinessPrice}
																onTrue={() => (
																	<ConditionalRender
																		show={
																			!!userIsCorporate &&
																			planType === PlanType.WITH_PLAN
																		}
																		onTrue={() => (
																			<>
																				<PriceBlockContainer isFirst={true}>
																					<PriceAccordion
																						onClick={() => (
																							setShowFullUpfront(
																								!showFullUpfront
																							),
																							setShowFullMonthly(false)
																						)}
																					>
																						<PriceHeaderWrapper>
																							<PriceIconWrapper>
																								<ArrowIconContainer
																									isShowFull={
																										showFullUpfront
																									}
																								>
																									<Icon
																										name="arrowDown"
																										fill="black"
																									/>
																								</ArrowIconContainer>
																								<ParagraphSBold>
																									{translate(
																										'product.cart-card.upfront'
																									)}
																								</ParagraphSBold>
																							</PriceIconWrapper>
																							<PriceTagWrapper>
																								<SmallOMRWrapper>
																									{translate('omr')}
																								</SmallOMRWrapper>
																								<BodyMBold>
																									{roundMoney(
																										Number(
																											calculatedProduct
																												?.device
																												?.saleDetails
																												?.totalAmount
																										) -
																											(!!monthlyBusinessPrice
																												? 0
																												: Number(
																														calculatedProduct
																															?.device
																															?.saleDetails
																															?.vatAmount ??
																															0
																												  )) ||
																											0
																									)}
																								</BodyMBold>
																							</PriceTagWrapper>
																						</PriceHeaderWrapper>
																						<VatWrapper>
																							<Info color="black54">
																								{translate(
																									'product.cart-card.with-vat.b2b',
																									calculatedProduct
																										?.device
																										?.saleDetails
																										?.vatPercentage ||
																										0
																								)}
																							</Info>
																							<InfoBold color="black54">
																								<ConditionalRender
																									show={
																										!Number(
																											calculatedProduct
																												?.device
																												?.deviceCommitments
																												?.installmentMonths
																										)
																									}
																									onTrue={() => (
																										<StrikethroughWrapper>{`${translate(
																											'omr'
																										)} ${roundMoney(
																											calculatedProduct
																												?.device
																												?.saleDetails
																												?.standardPrice ||
																												0
																										)}`}</StrikethroughWrapper>
																									)}
																								/>
																							</InfoBold>
																						</VatWrapper>
																						<ConditionalRender
																							show={
																								!Number(
																									calculatedProduct
																										?.device
																										?.deviceCommitments
																										?.installmentMonths
																								)
																							}
																							onTrue={() => (
																								<VatWrapper>
																									<Info color="primary">
																										{translate(
																											'product.cart-card.after-discount'
																										)}
																									</Info>
																									<InfoBold color="primary">
																										{`${translate(
																											'omr'
																										)} ${roundMoney(
																											calculatedProduct
																												?.device
																												?.saleDetails
																												?.totalAmount ||
																												0
																										)}`}
																									</InfoBold>
																								</VatWrapper>
																							)}
																						/>
																					</PriceAccordion>
																					<ConditionalRender
																						show={showFullUpfront}
																						onTrue={() => (
																							<PriceContentWrapper
																								isShown={
																									showFullUpfront
																								}
																							>
																								<PriceContentRowWrapper
																									isBottomMode={true}
																								>
																									<VerticalDivider />
																									<PriceDetailsWrapper>
																										<BodyS>
																											{
																												selectedProduct?.name
																											}
																										</BodyS>
																										<CaptionBigger>
																											<ConditionalRender
																												show={
																													!!selectedProduct?.attributes.find(
																														(
																															item
																														) =>
																															item.identifier ===
																															DEF_COLOR
																													)
																														?.values[0]
																														.value
																												}
																												onTrue={() => (
																													<>
																														{`${
																															selectedProduct?.attributes.find(
																																(
																																	item
																																) =>
																																	item.identifier ===
																																	DEF_COLOR
																															)
																																?.values[0]
																																.value
																														}, `}
																													</>
																												)}
																											/>
																											{
																												selectedProduct?.attributes.find(
																													(
																														item
																													) =>
																														item.identifier ===
																														DEF_CAPACITY
																												)
																													?.values[0]
																													.value
																											}
																										</CaptionBigger>
																										<BodySBold>
																											{`${translate(
																												'omr'
																											)} ${roundMoney(
																												!!monthlyBusinessPrice
																													? Number(
																															calculatedProduct
																																?.device
																																?.saleDetails
																																?.totalAmount
																													  ) -
																															Number(
																																calculatedProduct
																																	?.device
																																	?.saleDetails
																																	?.vatAmount
																															)
																													: calculatedProduct
																															?.device
																															?.saleDetails
																															?.taxableAmount ||
																															0
																											)}`}
																										</BodySBold>
																									</PriceDetailsWrapper>
																								</PriceContentRowWrapper>
																								<PriceContentRowWrapper
																									isBottomMode={true}
																								>
																									<VerticalDivider />
																									<PriceDetailsWrapper>
																										<VatRow>
																											<BodyS>
																												{translate(
																													'product.cart-card.vat-from-device'
																												)}
																											</BodyS>
																											<ConditionalRender
																												show={
																													Number(
																														calculatedProduct
																															?.device
																															?.saleDetails
																															?.taxableAmount
																													) ===
																													0
																												}
																												onTrue={() => (
																													<Tooltip
																														isLowerPinTooltip={
																															true
																														}
																														margin={
																															isRTL()
																																? '-120px -180px 0px 0px'
																																: '-120px 0px 0px -60px'
																														}
																														withPin
																														width={
																															208
																														}
																														text={translate(
																															'product.cart-card.device-vat.tooltip'
																														)}
																													>
																														<TooltipIconWrapper>
																															<Icon
																																name="questionMarkCircle"
																																fill="black87"
																															/>
																														</TooltipIconWrapper>
																													</Tooltip>
																												)}
																											/>
																										</VatRow>
																										<BodySBold>
																											{`${translate(
																												'omr'
																											)} ${roundMoney(
																												calculatedProduct
																													?.device
																													?.saleDetails
																													?.vatAmount ||
																													0
																											)}`}
																										</BodySBold>
																									</PriceDetailsWrapper>
																								</PriceContentRowWrapper>
																							</PriceContentWrapper>
																						)}
																					/>
																				</PriceBlockContainer>
																			</>
																		)}
																	/>
																)}
															/>
															<ConditionalRender
																show={
																	!monthlyBusinessPrice &&
																	!userIsCorporate &&
																	!showNoPricesWarning &&
																	!notChosenNumberWarning &&
																	!outOfStock &&
																	(!!selectedFilters?.installmentPeriod ||
																		!!monthlyPrice ||
																		(!!calculatedProduct?.device?.deviceCommitments
																			?.installmentMonths &&
																			!!calculatedProduct?.device?.saleDetails
																				?.totalAmount)) &&
																	(selectedProduct?.installmentPeriod === '12' ||
																		selectedProduct?.installmentPeriod === '24')
																}
																onTrue={() => (
																	<PriceBlockContainer>
																		<PriceAccordion
																			onClick={() =>
																				setShowFullMonthly(!showFullMonthly)
																			}
																		>
																			<PriceHeaderWrapper>
																				<PriceIconWrapper>
																					<ArrowIconContainer
																						isShowFull={showFullMonthly}
																					>
																						<Icon
																							name="arrowDown"
																							fill="black"
																						/>
																					</ArrowIconContainer>
																					<ConditionalRender
																						show={screen('lg')}
																						onTrue={() => (
																							<ParagraphSBold>
																								{selectedProduct?.installmentPeriod ===
																								'24'
																									? translate(
																											'product.cart-card.24mo'
																									  )
																									: translate(
																											'product.cart-card.for12mo'
																									  )}
																							</ParagraphSBold>
																						)}
																						onFalse={() => (
																							<ConditionalRender
																								show={Boolean(
																									selectedProduct?.isBNPL
																								)}
																								onTrue={() => (
																									<BodyMBold>
																										{selectedProduct?.installmentPeriod ===
																										'24'
																											? screen(
																													'xl'
																											  )
																												? translate(
																														'product.cart-card.23months'
																												  )
																												: translate(
																														'product.cart-card.23months'
																												  )
																											: screen(
																													'xl'
																											  )
																											? translate(
																													'product.cart-card.11months'
																											  )
																											: translate(
																													'product.cart-card.11months'
																											  )}
																									</BodyMBold>
																								)}
																								onFalse={() => (
																									<BodyMBold>
																										{selectedProduct?.installmentPeriod ===
																										'24'
																											? screen(
																													'xl'
																											  )
																												? translate(
																														'product.cart-card.for24months'
																												  )
																												: translate(
																														'product.cart-card.24mo'
																												  )
																											: screen(
																													'xl'
																											  )
																											? translate(
																													'product.cart-card.for12months'
																											  )
																											: translate(
																													'product.cart-card.for12mo'
																											  )}
																									</BodyMBold>
																								)}
																							/>
																						)}
																					/>
																				</PriceIconWrapper>
																				<PriceTagWrapper>
																					<SmallOMRWrapper>
																						{translate('omr')}
																					</SmallOMRWrapper>
																					<ConditionalRender
																						show={Boolean(
																							selectedProduct?.isBNPL
																						)}
																						onTrue={() => (
																							<BodyMBold>
																								{roundMoney(
																									Number(
																										selectedProduct?.installmentValue ??
																											0
																									)
																								)}
																							</BodyMBold>
																						)}
																						onFalse={() => (
																							<BodyMBold>
																								{roundMoney(
																									nonBnplInstallmentAmount
																								)}
																							</BodyMBold>
																						)}
																					/>
																				</PriceTagWrapper>
																			</PriceHeaderWrapper>
																			<ConditionalRender
																				show={Boolean(!selectedProduct?.isBNPL)}
																				onTrue={() => (
																					<VatWrapper>
																						<Info color="black54">
																							{translate(
																								'product.cart-card.with-vat',
																								selectedPlan?.vatPercent ??
																									0
																							)}
																						</Info>
																						<InfoBold color="black54">
																							{`${translate(
																								'omr'
																							)} ${roundMoney(
																								(Number(
																									selectedProduct?.installmentValue
																								) ?? 0) +
																									(Number(
																										selectedPlan?.price
																									) ?? 0) +
																									Number(
																										selectedPlan?.vatValue ??
																											0
																									)
																							)}`}
																						</InfoBold>
																					</VatWrapper>
																				)}
																			/>
																		</PriceAccordion>
																		<ConditionalRender
																			show={showFullMonthly}
																			onTrue={() => (
																				<PriceContentWrapper>
																					<PriceContentRowWrapper>
																						<VerticalDivider />
																						<PriceDetailsWrapper>
																							<BodyS>
																								{selectedProduct?.name}
																							</BodyS>
																							<CaptionBigger>
																								<ConditionalRender
																									show={
																										!!selectedProduct?.attributes.find(
																											(item) =>
																												item.identifier ===
																												DEF_COLOR
																										)?.values[0]
																											.value
																									}
																									onTrue={() => (
																										<>
																											{`${
																												selectedProduct?.attributes.find(
																													(
																														item
																													) =>
																														item?.identifier ===
																														DEF_COLOR
																												)
																													?.values[0]
																													.value
																											}, `}
																										</>
																									)}
																								/>
																								{
																									selectedProduct?.attributes.find(
																										(item) =>
																											item?.identifier ===
																											DEF_CAPACITY
																									)?.values[0].value
																								}
																							</CaptionBigger>
																							<BodySBold>
																								{`${translate(
																									'omr'
																								)} ${roundMoney(
																									Number(
																										selectedProduct?.installmentValue
																									) ?? 0
																								)}`}
																							</BodySBold>
																						</PriceDetailsWrapper>
																					</PriceContentRowWrapper>
																					<PriceContentRowWrapper>
																						<VerticalDivider />
																						<PriceDetailsWrapper>
																							<BodyS>
																								{(selectedPlan?.name ||
																									selectedPlan?.shortDescription) ??
																									''}
																							</BodyS>
																							<BodySBold>
																								{`${translate(
																									'omr'
																								)} ${roundMoney(
																									selectedPlan?.price ??
																										0
																								)}`}
																							</BodySBold>
																						</PriceDetailsWrapper>
																					</PriceContentRowWrapper>
																					<PriceContentRowWrapper>
																						<VerticalDivider />
																						<PriceDetailsWrapper>
																							<VatRow>
																								<BodyS>
																									{translate(
																										'product.cart-card.plan-vat'
																									)}
																								</BodyS>
																								<Tooltip
																									isLowerPinTooltip={
																										true
																									}
																									margin={
																										isRTL()
																											? '-120px -180px 0px 0px'
																											: '-120px 0px 0px -60px'
																									}
																									withPin
																									width={208}
																									text={translate(
																										'product.cart-card.plan-vat.tooltip'
																									)}
																								>
																									<TooltipIconWrapper>
																										<Icon
																											name="questionMarkCircle"
																											fill="black87"
																										/>
																									</TooltipIconWrapper>
																								</Tooltip>
																							</VatRow>
																							<BodySBold>
																								{`${translate(
																									'omr'
																								)} ${roundMoney(
																									selectedPlan?.vatValue ??
																										0
																								)}`}
																							</BodySBold>
																						</PriceDetailsWrapper>
																					</PriceContentRowWrapper>
																				</PriceContentWrapper>
																			)}
																		/>
																	</PriceBlockContainer>
																)}
															/>
															<ConditionalRender
																show={
																	!!monthlyBusinessPrice &&
																	!!userIsCorporate &&
																	!outOfStock &&
																	((!userIsCorporate && !!monthlyPrice) ||
																		(!!monthlyBusinessPrice &&
																			!!calculatedProduct?.device
																				?.deviceCommitments
																				?.installmentMonths &&
																			!!calculatedProduct?.device?.saleDetails
																				?.totalAmount))
																}
																onTrue={() => (
																	<ConditionalRender
																		show={
																			!!userIsCorporate &&
																			planType === PlanType.WITH_PLAN
																		}
																		onTrue={() => (
																			<PriceBlockContainer isFirst={true}>
																				<PriceAccordion
																					onClick={() => (
																						setShowFullMonthly(false),
																						setShowInstallements(
																							!showInstallements
																						)
																					)}
																				>
																					<PriceHeaderWrapper>
																						<PriceIconWrapper>
																							<ArrowIconContainer
																								isShowFull={
																									showInstallements
																								}
																							>
																								<Icon
																									name="arrowDown"
																									fill="black"
																								/>
																							</ArrowIconContainer>
																							<ParagraphSBold>
																								{translate(
																									'payment.summary.description.installments'
																								)}
																							</ParagraphSBold>
																						</PriceIconWrapper>
																						<PriceTagWrapper>
																							<SmallOMRWrapper>
																								{translate('omr')}
																							</SmallOMRWrapper>
																							<BodyMBold>
																								<StrikethroughWrapper>
																									{roundMoney(
																										(Number(
																											calculatedProduct
																												?.device
																												?.saleDetails
																												?.standardPrice
																										) || 0) /
																											(Number(
																												calculatedProduct
																													?.device
																													?.deviceCommitments
																													?.installmentMonths
																											) || 0)
																									)}
																								</StrikethroughWrapper>
																							</BodyMBold>
																						</PriceTagWrapper>
																					</PriceHeaderWrapper>
																					<VatWrapper>
																						<Info color="primary">
																							{translate(
																								'product.cart-card.after-discount'
																							)}
																						</Info>
																						<InfoBold color="primary">
																							{`${translate(
																								'omr'
																							)} ${roundMoney(
																								calculatedProduct
																									?.device
																									?.deviceCommitments
																									?.installmentAmount ||
																									0
																							)}`}
																						</InfoBold>
																					</VatWrapper>
																				</PriceAccordion>
																				<ConditionalRender
																					show={showInstallements}
																					onTrue={() => (
																						<PriceContentWrapper
																							isShown={showInstallements}
																						>
																							<PriceContentRowWrapper
																								isBottomMode={true}
																							>
																								<VerticalDivider />
																								<PriceDetailsWrapper>
																									<BodyS>
																										{
																											selectedProduct?.name
																										}
																									</BodyS>
																									<CaptionBigger>
																										<ConditionalRender
																											show={
																												!!selectedProduct?.attributes.find(
																													(
																														item
																													) =>
																														item?.identifier ===
																														DEF_COLOR
																												)
																													?.values[0]
																													.value
																											}
																											onTrue={() => (
																												<>
																													{`${
																														selectedProduct?.attributes.find(
																															(
																																item
																															) =>
																																item?.identifier ===
																																DEF_COLOR
																														)
																															?.values[0]
																															.value
																													}, `}
																												</>
																											)}
																										/>
																										{
																											selectedProduct?.attributes.find(
																												(
																													item
																												) =>
																													item?.identifier ===
																													DEF_CAPACITY
																											)?.values[0]
																												.value
																										}
																									</CaptionBigger>
																									<BodySBold>
																										{`${translate(
																											'omr'
																										)} ${roundMoney(
																											calculatedProduct
																												?.device
																												?.deviceCommitments
																												?.installmentAmount ||
																												0
																										)}`}
																									</BodySBold>
																								</PriceDetailsWrapper>
																							</PriceContentRowWrapper>
																							<PriceContentRowWrapper
																								isBottomMode={true}
																							></PriceContentRowWrapper>
																						</PriceContentWrapper>
																					)}
																				/>
																			</PriceBlockContainer>
																		)}
																		onFalse={() => (
																			<PriceContainer>
																				<PriceTag price={monthlyPrice} />
																				<VatInfo>
																					{translate(
																						'navbar.cart.vat',
																						selectedProduct?.pricesWithVat
																							.vatPercent ?? ''
																					)}
																				</VatInfo>
																			</PriceContainer>
																		)}
																	/>
																)}
															/>
															<ConditionalRender
																show={lastMonthConditionalRender}
																onTrue={() => (
																	<PriceBlockContainer>
																		<PriceAccordion
																			onClick={() =>
																				setShowLastMonth(!showLastMonth)
																			}
																		>
																			<PriceHeaderWrapper>
																				<PriceIconWrapper>
																					<ArrowIconContainer
																						isShowFull={showLastMonth}
																					>
																						<Icon
																							name="arrowDown"
																							fill="black"
																						/>
																					</ArrowIconContainer>
																					<ParagraphSBold>
																						{translate(
																							'product.cart-card.last'
																						)}
																					</ParagraphSBold>
																				</PriceIconWrapper>
																				<PriceTagWrapper>
																					<SmallOMRWrapper>
																						{translate('omr')}
																					</SmallOMRWrapper>
																					<BodyMBold>
																						{roundMoney(
																							Number(
																								selectedProduct?.installmentValueFinal
																							) ?? 0
																						)}
																					</BodyMBold>
																				</PriceTagWrapper>
																			</PriceHeaderWrapper>
																		</PriceAccordion>
																		<ConditionalRender
																			show={showLastMonth}
																			onTrue={() => (
																				<PriceContentWrapper>
																					<PriceContentRowWrapper>
																						<VerticalDivider />
																						<PriceDetailsWrapper>
																							{selectedProduct?.name && (
																								<BodyS>
																									{
																										selectedProduct.name
																									}
																								</BodyS>
																							)}
																							<CaptionBigger>
																								<ConditionalRender
																									show={
																										!!selectedProduct?.attributes.find(
																											(item) =>
																												item.identifier ===
																												DEF_COLOR
																										)?.values?.[0]
																											?.value
																									}
																									onTrue={() => (
																										<>
																											{`${
																												selectedProduct?.attributes.find(
																													(
																														item
																													) =>
																														item?.identifier ===
																														DEF_COLOR
																												)
																													?.values?.[0]
																													?.value
																											}, `}
																										</>
																									)}
																								/>
																								{
																									selectedProduct?.attributes.find(
																										(item) =>
																											item?.identifier ===
																											DEF_CAPACITY
																									)?.values[0]?.value
																								}
																							</CaptionBigger>
																							<BodySBold>
																								{`${translate(
																									'omr'
																								)} ${roundMoney(
																									Number(
																										selectedProduct?.installmentValueFinal
																									) ?? 0
																								)}`}
																							</BodySBold>
																						</PriceDetailsWrapper>
																					</PriceContentRowWrapper>
																					<PriceContentRowWrapper>
																						<VerticalDivider />
																						<PriceDetailsWrapper>
																							{(selectedPlan?.name ||
																								selectedPlan?.shortDescription) && (
																								<BodyS>
																									{selectedPlan?.name ||
																										selectedPlan?.shortDescription}
																								</BodyS>
																							)}
																							<BodySBold>
																								{`${translate(
																									'omr'
																								)} ${roundMoney(
																									selectedPlan?.price ??
																										0
																								)}`}
																							</BodySBold>
																						</PriceDetailsWrapper>
																					</PriceContentRowWrapper>
																					<PriceContentRowWrapper>
																						<VerticalDivider />
																						<PriceDetailsWrapper>
																							<VatRow>
																								<BodyS>
																									{translate(
																										'product.cart-card.plan-vat'
																									)}
																								</BodyS>
																								<Tooltip
																									isLowerPinTooltip={
																										true
																									}
																									margin={
																										isRTL()
																											? '-120px -180px 0px 0px'
																											: '-120px 0px 0px -60px'
																									}
																									withPin
																									width={208}
																									text={translate(
																										'product.cart-card.plan-vat.tooltip'
																									)}
																								>
																									<TooltipIconWrapper>
																										<Icon
																											name="questionMarkCircle"
																											fill="black87"
																										/>
																									</TooltipIconWrapper>
																								</Tooltip>
																							</VatRow>
																							<BodySBold>
																								{`${translate(
																									'omr'
																								)} ${roundMoney(
																									selectedPlan?.vatValue ??
																										0
																								)}`}
																							</BodySBold>
																						</PriceDetailsWrapper>
																					</PriceContentRowWrapper>
																				</PriceContentWrapper>
																			)}
																		/>
																	</PriceBlockContainer>
																)}
															/>
														</>
													)}
												/>
											</>
										)}
									/>
									<ConditionalRender
										show={!notChosenNumberWarning}
										onTrue={() => (
											<ConditionalRender
												show={!salesLead}
												onTrue={() => (
													<>
														<ButtonWrapper>
															<Button
																uniqueId="product.cart-card.add-to-cart"
																disabled={
																	outOfStock ||
																	disableAddToCartButton ||
																	showNoPricesWarning
																}
																listener={[ADD_TO_CART_BI]}
																onClick={handleAddToCart}
																variant={
																	preorder
																		? ButtonVariant.DEFAULT
																		: ButtonVariant.PRIMARY
																}
																size={ButtonSize.LARGE}
																width={ButtonWidth.FLEX}
															>
																{translate('product.cart-card.add-to-cart')}
															</Button>
														</ButtonWrapper>
														<SecureTransactionWrapper>
															<Icon
																name="lock"
																fill="black54"
																width={ICON_SIZE}
																height={ICON_SIZE}
															/>
															<SecureTransactionText color="black54">
																{translate('product.cart-card.transaction.part-1')}
																<HighlightedText color="black54">
																	{translate('product.cart-card.transaction.part-2')}
																</HighlightedText>
															</SecureTransactionText>
														</SecureTransactionWrapper>
													</>
												)}
												onFalse={() => (
													<>
														{!salesLead && (
															<Divider
																withoutLine
																marginTop={16}
																marginBottom={!!preorder ? 24 : 0}
															/>
														)}
														<OutOfStockWrapper>
															{!salesLead && (
																<Icon
																	name="msgInfo"
																	fill="primary"
																	height={WARNING_ICON_SIZE}
																	width={WARNING_ICON_SIZE}
																/>
															)}
															<OutOfStockDescription paddingTop={salesLead ? 0 : 16}>
																{salesLead
																	? translate(
																			'product.cart-card.sales-lead-purchase-info'
																	  )
																	: translate('product.cart-card.sales-lead-info')}
															</OutOfStockDescription>
															<ConditionalRender
																show={!!selectedProduct}
																onTrue={() => (
																	<OutOfStockItemTitle
																		key={selectedProduct?.uniqueID}
																	>
																		{selectedProduct?.name}
																	</OutOfStockItemTitle>
																)}
															/>
														</OutOfStockWrapper>
													</>
												)}
											/>
										)}
									/>
								</SummaryWrapper>
							</>
						)}
					/>
				)}
			/>
		</SelectedItemsWrapper>
	);
};

export default ProductCartCard;
