import { BillingType, AccountType } from '@ApiModels/accounts';
import { ICustomerProductsPayload } from '@Services/packages/packages';
import { ICheckout } from '@Redux/modules/checkout/actions';
import { CATALOG_ID, STORE_ID } from '@Config/app.config';
import { getCardType } from '@Utils/converters/payment';
import { IGenerateOtpPayload, IGetUsagePayload } from '../queries/omantelApi';
import { IGetVehicleInsurance } from '../queries/omantelApi';
import moment from 'moment';
import { getLang } from '@Utils/language/language';
import { ITenant } from '@ApiModels/createTenant';

export function login(user: string, secret: string): Record<string, any> {
	return {
		user,
		secret,
	};
}

export function removePaymentToken(paymentTokenId: string): Record<string, any> {
	return {
		paymentTokenId,
	};
}

export function getRecommendedPlan(phoneNumber: string, billingType: BillingType): Record<string, any> {
	return {
		phoneNumber,
		billingType,
	};
}

export function getCustomerProducts({
	phoneNumber,
	billingType,
	accountType = AccountType.MOBILE,
	productsExtendedInfo = false,
}: ICustomerProductsPayload): Record<string, any> {
	return {
		phoneNumber,
		billingType,
		accountType,
		productsExtendedInfo,
	};
}

export function processOrder({
	totalAmount,
	orderId,
	billingAddress,
	contactDetails,
}: {
	totalAmount: string;
	orderId: string;
	billingAddress: ICheckout['payload']['billingAddress'];
	contactDetails: ICheckout['payload']['contactDetails'];
}): Record<string, any> {
	return {
		receipt_number: 'S201700124531',
		auth_amount: totalAmount,
		req_amount: totalAmount,
		req_reference_number: orderId,
		billto_country: 'OM',
		billto_email: contactDetails?.email,
		billto_address1: billingAddress?.way,
		billto_city: billingAddress?.city,
		billto_firstname: contactDetails?.firstName,
		billto_lastname: contactDetails?.lastName,
		transaction_id: '4833819372516477804056',
		decision: 'ACCEPT',
		authorization_no: '363808',
		req_payment_method: 'card',
		signature: '1234',
		req_transaction_type: 'sale',
		req_currency: 'OMR',
		retry: 'true',
		merchant_id: 'bm_omantel_omr',
		catalogId: CATALOG_ID,
		req_access_key: '1234',
		storeId: STORE_ID,
	};
}

export function makePaymentPayload({
	orderId,
	address,
	paymentData,
	totalPrice,
	contactDetails,
	flowType,
}: types.payment.IMakePayment): Record<string, any> {
	const payload: types.payment.IPayload = {
		amount: String(totalPrice || 0),
		billingAddress: null,
		card: null,
		channel: 'ESHOP',
		createPaymentToken: false,
		details: {},
		flowType,
		orderId,
		transactionCode: 'S',
	};

	if (String(totalPrice) === '0') {
		payload.flowType = null;
	}
	if (paymentData?.card) {
		const type = getCardType(paymentData.card.cardNumber.replaceAll(' ', '')) ?? 'VISA';
		payload.card = {
			cvv: paymentData.card.cvv,
			expiration: {
				month: paymentData.card.expire.split('/')[0],
				year: paymentData.card.expire.split('/')[1],
			},
			number: paymentData.card.cardNumber.replaceAll(' ', ''),
			type: type.toUpperCase(),
		};
		payload.createPaymentToken = paymentData.card.savePaymentToken;
	} else if (paymentData?.token) {
		payload.useToken = paymentData.token.id;
	}
	if ((address || (contactDetails?.street1 && contactDetails?.city)) && contactDetails) {
		payload.billingAddress = {
			city: contactDetails?.city ?? address?.city ?? '',
			country: 'OM',
			email: contactDetails.email,
			firstName: paymentData?.card?.name.split(' ')[0] ?? contactDetails.firstName,
			lastName: paymentData?.card?.name.split(' ')[1] ?? contactDetails.lastName,
			street1: contactDetails?.street1 ?? address?.way ?? '',
		};
	}

	return payload;
}

export function getUsage({
	phoneNumber,
	billingType,
	language,
	apiVersion = 3,
	getProducts,
	serializeNulls = false,
	accountType,
}: IGetUsagePayload): Record<string, any> {
	return {
		accountType,
		apiVersion,
		billingType,
		getProducts,
		language,
		phoneNumber,
		serializeNulls,
	};
}

export function getMakasibLoyaltyAccountOperation(phoneNumber: string): Record<string, any> {
	return {
		msisdn: phoneNumber,
		language: '1',
	};
}

export function getTenant(tenantId: string, tenantName: string): Record<string, any> {
	return {
		tenantId,
		tenantName,
	};
}

export function createTenant(tenantId: ITenant): Record<string, any> {
	return {
		...tenantId,
	};
}

export function manageMakasibVoucherReservation(voucherCode: string, action: 'GW' | 'U' | 'G'): Record<string, any> {
	return {
		voucherCode,
		action,
	};
}

export function getBillsHistoryForAccounts(): Record<string, any> {
	return {
		returnAdjustments: true,
		fromDate: moment()
			.subtract(6, 'months')
			.locale('en-gb')
			.startOf('month')
			.endOf('days')
			.format('YYYY-MM-DDTHH:mm:ss'),
		toDate: moment().locale('en-gb').endOf('days').format('YYYY-MM-DDTHH:mm:ss'),
	};
}

export function getUserGiftCardsByOrderId(orderId: string): Record<string, any> {
	return { orderId };
}

export function getSimDetails(phoneNumber: string): Record<string, any> {
	return {
		phoneNumber,
	};
}

export function getContractDetails(subscriberNo: string): Record<string, any> {
	return {
		subscriberNo,
	};
}

export function generateOTP({ msisdn, operationContext }: IGenerateOtpPayload): Record<string, any> {
	return {
		msisdn,
		operationContext,
	};
}
let getCustomerAccountProductPayload: string;
export function getCustomerAccountProduct(
	phoneNumber: string,
	doNotGetPinPukDetails: 'true' | 'false' = 'false'
): Record<string, any> {
	getCustomerAccountProductPayload = JSON.stringify({
		customer: {
			customerAccount: {
				product: {
					productCharacteristic: [
						{
							name: 'PHONE_NUMBER',
							value: phoneNumber,
						},
					],
				},
			},
		},
		doNotGetPinPukDetails,
	});
	return {
		customer: {
			customerAccount: {
				product: {
					productCharacteristic: [
						{
							name: 'PHONE_NUMBER',
							value: phoneNumber,
						},
					],
				},
			},
		},
		doNotGetPinPukDetails,
	};
}

export function postUserPersonalInfo(
	functionalFlow: string,
	errorCode: string,
	errorMsg: string,
	response: string,
	request: string | undefined
): Record<string, any> {
	return {
		functionalFlow,
		source: 'Eshop',
		errorCode,
		errorMsg,
		request: request ? request : getCustomerAccountProductPayload,
		response,
	};
}

export function getVehicleInsurance({
	insurerName,
	insuranceType,
	insuredLicense,
	insuredContactNo,
	vehiclePlateCharCode,
	vehicleNo,
	vehicleValue,
	languageCode,
}: IGetVehicleInsurance): Record<string, any> {
	return {
		insurerName,
		insuranceType,
		insuredLicense,
		insuredContactNo,
		vehiclePlateCharCode,
		vehicleNo,
		vehicleValue,
		languageCode,
	};
}

export function getVehiclePlateChars(languageCode: string): Record<string, any> {
	return {
		languageCode,
	};
}

export function getProductsUnauthorized(phoneNumber: string): Record<string, any> {
	return {
		operationContext: 'BILLING_TYPE',
		customerAccount: {
			product: {
				productCharacteristic: {
					name: 'PHONE_NUMBER',
					value: phoneNumber,
				},
			},
		},
	};
}

export function validateOperation(operationContext: string): Record<string, any> {
	return {
		operationContext,
	};
}

export function validateOTP({ msisdn, operationId, token }: types.payloads.IValidateOTP): Record<string, any> {
	return {
		msisdn,
		operationId,
		token,
	};
}

export function submitProductOrder({
	productId,
	configurationProduct,
	subscriberNo,
}: types.payloads.ISubmitProductOrder): Record<string, any> {
	return {
		RequestBody: {
			OfferOrder: {
				ProductOffer: [
					{
						ProductList: {
							Product: [
								{
									ProductId: productId,
								},
								{
									ProductId: configurationProduct,
								},
							],
						},
					},
				],
				Subscriber: {
					SubscriberNo: subscriberNo,
					CommunicationLanguage: getLang() === 'en' ? '1' : '11',
				},
			},
		},
	};
}

export function validateResource({
	customerCode,
	customerType,
	requestType,
	serviceOrderType,
}: types.payloads.IValidateResourcePayload): Record<string, any> {
	return {
		customerCode,
		customerType,
		requestType,
		serviceOrderType,
	};
}

export function getBranches({
	longitude,
	latitude,
	language,
	maxDistance,
}: types.payloads.IGetBranchesPayload): Record<string, any> {
	return {
		longitude,
		latitude,
		language,
		maxDistance,
	};
}

export function getServices({
	branchId,
	availableforAppointment,
	availableforWalkin,
	language,
}: types.payloads.IGetServicesPayload): Record<string, any> {
	return {
		branchId,
		availableforAppointment,
		availableforWalkin,
		language,
	};
}

export function getDates({ branchId, serviceId }: types.payloads.IGetDatesPayload): Record<string, any> {
	return {
		branchId,
		serviceId,
	};
}

export function getHours({ branchId, serviceId, startDate }: types.payloads.IGetHoursPayload): Record<string, any> {
	return {
		branchId,
		serviceId,
		startDate,
	};
}

export function bookAppointment({
	branchId,
	branchName,
	serviceId,
	fullName,
	contactNumber,
	serviceNumber,
	appointmentDate,
	language,
}: types.payloads.IBookAppointmentPayload): Record<string, any> {
	return {
		branchId,
		branchName,
		serviceId,
		fullName,
		contactNumber,
		serviceNumber,
		appointmentDate,
		language,
	};
}

export function cancelAppointment({ appointmentId }: types.payloads.ICancelAppointmentPayload): Record<string, any> {
	return {
		appointmentId,
	};
}

export function getAppointment({ appointmentId }: types.payloads.IGetAppointmentPayload): Record<string, any> {
	return {
		appointmentId,
	};
}

export function getAppointments({ contactNumber }: types.payloads.IGetAppointmentsPayload): Record<string, any> {
	return {
		contactNumber,
	};
}
