import React, { FunctionComponent, useEffect, useState } from 'react';
import TextField from '@Components/controls/TextField/TextField';
import { cardNumberFormat, cvvFormat, expDateFormat, nameFormat } from '@Utils/converters/payment';
import { Col, Row, useScreenClass } from 'react-grid-system';
import {
	AddCardFormWrapper,
	ImageWrapper,
	CheckboxWrapper,
	LogoWrapper,
	SmallScreenImageWrapper,
} from './AddCardForm.styled';
import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';
import Checkbox from '@Components/controls/Checkbox/Checkbox';
import { CheckboxShape } from '@Components/controls/Checkbox/Checkbox.styled';
import cardsLogo from '@Assets/images/cardsLogo.png';
import { ICheckout as ICheckoutAction } from '@Redux/modules/checkout/actions';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import cardDataValidator from '@Utils/validators/cardDataValidator';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
interface IAddCardForm {
	setPaymentData: (data: ICheckoutAction['payload']['paymentData']) => void;
	selectedPaymentData: ICheckoutAction['payload']['paymentData'];
	displaySaveCard: boolean;
}

enum ICardImages {
	FRONT_BLANK = 'cardFrontBlank',
	FRONT_NUMBER = 'cardFrontNumber',
	FRONT_NAME = 'cardFrontName',
	FRONT_EXP = 'cardFrontExp',
	BACK_CVV = 'cardBackCCV',
	BACK_BLANK = 'cardBackBlank',
}

const initCardForm = {
	cardNumber: '',
	name: '',
	expire: '',
	cvv: '',
	savePaymentToken: false,
};
const TAG_MANAGER_SAVE_CARD_FOR_FUTURE = 'Save card for future';

const AddCardForm: FunctionComponent<IAddCardForm> = ({
	setPaymentData,
	displaySaveCard,
	selectedPaymentData = { card: initCardForm },
}) => {
	const paymentDataFromStore = useSelector((state: types.redux.IState) => state.checkout.paymentData);
	const { translate } = useTranslate();
	const [selectedFieldImage, setSelectedFieldImage] = useState<types.iconNames>(ICardImages.FRONT_BLANK);
	const [isFocused, setFocused] = useState(false);
	const screenClass = useScreenClass();

	useEffect(() => {
		if (paymentDataFromStore?.card) {
			setPaymentData(paymentDataFromStore);
		}
	}, []);

	const changeValue = (key: string, value: string | boolean) => {
		const oldState = selectedPaymentData?.card ? selectedPaymentData.card : initCardForm;
		setPaymentData({ card: { ...oldState, [key]: value } });
	};

	const selectCardImage = (name: types.iconNames) => {
		setSelectedFieldImage(name);
		setFocused(true);
	};

	const handleOnBlur = () => {
		if (selectedFieldImage.startsWith('cardBack') && !isFocused) {
			setSelectedFieldImage(ICardImages.BACK_BLANK);
		} else {
			setSelectedFieldImage(ICardImages.FRONT_BLANK);
		}
	};

	return (
		<Formik
			validationSchema={cardDataValidator()}
			validateOnBlur={true}
			initialValues={paymentDataFromStore?.card ?? initCardForm}
			onSubmit={() => {}}
		>
			{({ setFieldValue, values, errors, touched, setFieldTouched }) => (
				<>
					<AddCardFormWrapper md={6} lg={8} xl={6}>
						<>
							<ConditionalRender
								show={
									screenClass.includes('xs') ||
									screenClass.includes('sm') ||
									screenClass.includes('lg')
								}
								onTrue={() => (
									<AddCardFormWrapper>
										<SmallScreenImageWrapper>
											<Icon name={selectedFieldImage} height="100%" width="100%" />
										</SmallScreenImageWrapper>
										<LogoWrapper>
											<img src={cardsLogo} alt="visa and mastercard logo" />
										</LogoWrapper>
									</AddCardFormWrapper>
								)}
							/>
							<TextField
								onFocus={() => selectCardImage(ICardImages.FRONT_NUMBER)}
								width="100%"
								onBlur={() => {
									setFieldTouched('cardNumber');
									handleOnBlur();
								}}
								id="cc-number"
								type="tel"
								onChange={(e) => {
									setFieldValue('cardNumber', cardNumberFormat(String(e)));
									changeValue('cardNumber', cardNumberFormat(String(e)));
								}}
								value={values.cardNumber}
								name="cc-number"
								maxlength={19}
								label={translate('secure-checkout.input.card-number.label')}
								placeholder={translate('secure-checkout.input.card-number.placeholder')}
								marginBottom={16}
								message={!!errors.cardNumber && touched.cardNumber ? errors.cardNumber : ''}
								error={!!errors.cardNumber && touched.cardNumber}
							/>
							<TextField
								onFocus={() => selectCardImage(ICardImages.FRONT_NAME)}
								width="100%"
								onBlur={() => {
									setFieldTouched('name');
									handleOnBlur();
								}}
								id="cc-name"
								type="text"
								onChange={(e) => {
									changeValue('name', nameFormat(String(e)));
									setFieldValue('name', nameFormat(String(e)));
								}}
								value={values.name}
								name="cc-name"
								label={translate('secure-checkout.input.card-holder.label')}
								placeholder={translate('secure-checkout.input.card-holder.placeholder')}
								marginBottom={16}
								message={!!errors.name && touched.name ? errors.name : ''}
								error={!!errors.name && touched.name}
							/>
							<Row>
								<Col xs={6} sm={6} lg={6}>
									<TextField
										onFocus={() => selectCardImage(ICardImages.FRONT_EXP)}
										onBlur={() => {
											setFieldTouched('expire');
											handleOnBlur();
										}}
										id="cc-expires"
										type="tel"
										onChange={(e) => {
											changeValue('expire', expDateFormat(String(e)));
											setFieldValue('expire', expDateFormat(String(e)));
										}}
										value={values.expire}
										name="cc-expires"
										maxlength={7}
										label={translate('secure-checkout.input.exp-date.label')}
										placeholder={translate('secure-checkout.input.exp-date.placeholder')}
										marginBottom={16}
										message={!!errors.expire && touched.expire ? errors.expire : ''}
										error={!!errors.expire && touched.expire}
									/>
								</Col>
								<Col xs={6} sm={6} lg={6}>
									<TextField
										onFocus={() => selectCardImage(ICardImages.BACK_CVV)}
										onBlur={() => {
											setFieldTouched('cvv');
											handleOnBlur();
										}}
										id="cc-csc"
										type="password"
										onChange={(e) => {
											changeValue('cvv', cvvFormat(String(e)));
											setFieldValue('cvv', cvvFormat(String(e)));
										}}
										value={values.cvv}
										name="cc-csc"
										maxlength={4}
										label={translate('secure-checkout.input.cvc-cvv.label')}
										placeholder={translate('secure-checkout.input.cvc-cvv.placeholder')}
										marginBottom={16}
										message={!!errors.cvv && touched.cvv ? errors.cvv : ''}
										error={!!errors.cvv && touched.cvv}
									/>
								</Col>
							</Row>
						</>
					</AddCardFormWrapper>
					<ConditionalRender
						show={!screenClass.includes('xs') && !screenClass.includes('sm') && !screenClass.includes('lg')}
						onTrue={() => (
							<AddCardFormWrapper md={6}>
								<ImageWrapper>
									<Icon name={selectedFieldImage} height="100%" width="100%" />
								</ImageWrapper>
								<LogoWrapper>
									<img src={cardsLogo} alt="visa and mastercard logo" />
								</LogoWrapper>
							</AddCardFormWrapper>
						)}
					/>
					<ConditionalRender
						show={displaySaveCard}
						onTrue={() => (
							<Col lg={12}>
								<CheckboxWrapper>
									<Checkbox
										active={!!values.savePaymentToken}
										onChange={() => {
											changeValue('savePaymentToken', !values.savePaymentToken);
											setFieldValue('savePaymentToken', !values.savePaymentToken);
											if (!values.savePaymentToken) {
												TagManager.dataLayer({
													dataLayer: {
														event: TAG_MANAGER_SAVE_CARD_FOR_FUTURE,
														userId: getUserId(),
													},
												});
											}
										}}
										shape={CheckboxShape.CIRCULAR}
										size={24}
										label={translate('secure-checkout.input.save-card')}
										labelClickable
									/>
								</CheckboxWrapper>
							</Col>
						)}
					/>
				</>
			)}
		</Formik>
	);
};

export default AddCardForm;
