import { IPaymentToken } from '@ApiModels/paymentTokens';
import { Action, ActionFunction1, createAction } from 'redux-actions';

export interface IPaymentData {
	token?: IPaymentToken;
	card?: {
		cardNumber: string;
		name: string;
		expire: string;
		cvv: string;
		savePaymentToken: boolean;
	};
	paymentMethod?: string;
}

interface IAddress {
	organization?: string;
	governorate: string;
	wilayat: string;
	city: string;
	area: string;
	way: string;
	house?: string;
	apartment?: string;
	landmark?: string;
	markerPosition?: google.maps.LatLngLiteral;
	addressId?: string;
	zipCode?: string;
}

export type AddressType = 'same' | 'diffrent';
export interface ICheckout {
	payload: {
		contactDetails?: {
			title: string;
			firstName: string;
			middleName: string;
			lastName: string;
			email: string;
			phoneNumber: string;
			street1?: string;
			city?: string;
			birthDate?: string;
			fatherName?: string;
			grandfatherName?: string;
		};
		billingAddress?: IAddress;
		shipmentAddress?: IAddress;
		paymentData?: IPaymentData;
		shipmentSameOrDiffrent?: AddressType;
		checkoutTimer?: number;
		onlyGiftCardOrDigitals?: boolean;
		pickup?: boolean;
	};
}

export const setCheckoutDetails: ActionFunction1<ICheckout['payload'], Action<ICheckout['payload']>> = createAction(
	'SET_CHECKOUT_DETAILS',
	(action: ICheckout['payload']) => action
);
export const clearCheckoutDetails = createAction('CLEAR_CHECKOUT_DETAILS');
