import styled from 'styled-components';
import { isRTL, paddingLeft } from '@Utils/layout/layoutSupport';
import { BodySHigher, DigitXsSm } from '@Components/Typography/Typography.styled';

export const ItemWrapper = styled.div`
	display: flex;
	padding: 8px 0;
	align-items: center;
`;

export const ItemImage = styled.div<{ src: string; isSmallSize?: boolean }>`
	width: 56px;
	height: 56px;
	${({ src, isSmallSize }) =>
		src && {
			background: `url(${src})`,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center center',
			width: isSmallSize ? '38px' : '56px',
			height: isSmallSize ? '38px' : '56px',
		}}
`;

export const ItemDescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	${() =>
		isRTL()
			? {
					paddingRight: '8px',
			  }
			: {
					paddingLeft: '8px',
			  }}
`;

export const ItemTitle = styled(BodySHigher)`
	margin-bottom: 4px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ItemDescription = styled(BodySHigher)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const RemoveItemWrapper = styled(DigitXsSm)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	text-decoration: underline;
	cursor: pointer;
`;

export const PlanDetailsWrapper = styled(BodySHigher)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	margin-right: 4px;
`;

export const PlanDetailsClickableWrapper = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export const ItemActionsWrapper = styled.div`
	display: flex;
	gap: 4px;
	flex-direction: row;
`;

export const ItemPrice = styled.div`
	padding: 0 8px;
`;

export const RowWrapper = styled.div<{ margin?: string }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	${({ margin }) => ({
		margin: margin ? margin : '0px',
	})}
`;

export const IconWrapper = styled.div`
	cursor: pointer;
`;

export const VatInformationRowWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 36px;
`;

export const WarningIconWrapper = styled.div`
	margin-right: 19px;
	margin-left: 4px;
`;

export const PriceDescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const VatInformationWrapper = styled.div`
	flex-wrap: wrap;
`;

export const ItemDetailsWrapper = styled.div`
	display: flex;
	padding: 6px 0;
	align-items: center;
`;

export const QuantityWrapper = styled.div`
	${() => paddingLeft(64)}
`;

export const FreeQuantityWrapper = styled.div`
	display: flex;
	justify-content: end;
`;

export const TitleContainer = styled.div`
	flex-direction: row;
	display: flex;
	justify-content: flex-start;
`;

export const IconContainer = styled.div`
	padding: 0 6px;
	margin-top: -2px;
`;

export const SIMIconContainer = styled.div`
	width: 56px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
