import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';

import React, { FunctionComponent } from 'react';
import { Col, Row, Visible } from 'react-grid-system';
import {
	BoldDescriptionWrapper,
	CardIconsContainer,
	CheckoutInfoCardContainer,
	ColContent,
	DescriptionContainer,
	DescriptionWrapper,
	IconWrapper,
	InfoWrapper,
	TitleWrapper,
	VerticalDivider,
	ListItem,
	List,
} from './CheckoutInfo.styled';
import { useSelector } from 'react-redux';

const CheckoutInfo: FunctionComponent<types.cards.ICartCheckoutInfoCardProps> = ({
	params: { isBuyHbbWfbbProcessWithOtherDevice, isBuyHbbWfbbProcess, isPrepaid },
}) => {
	const { translate } = useTranslate();
	const isApplePayAllow = useSelector((state: types.redux.IState) => state.api.authentication.isApplePayAllow);
	return (
		<CheckoutInfoCardContainer>
			<Col sm={isBuyHbbWfbbProcessWithOtherDevice || !!isPrepaid ? 4 : 6} xs={12}>
				<InfoWrapper additional={isBuyHbbWfbbProcessWithOtherDevice}>
					<div>
						<Row>
							<TitleWrapper>{translate('checkout-info.title')}</TitleWrapper>
						</Row>
						<List>
							<DescriptionContainer>
								<ListItem>
									<DescriptionWrapper>{translate('checkout-info.description-1')}</DescriptionWrapper>
								</ListItem>
							</DescriptionContainer>
							<DescriptionContainer>
								<ListItem>
									<DescriptionWrapper>{translate('checkout-info.description-2')}</DescriptionWrapper>
								</ListItem>
							</DescriptionContainer>
							<ConditionalRender
								show={!!isPrepaid}
								onTrue={() => (
									<DescriptionContainer>
										<ListItem>
											<DescriptionWrapper>
												{translate('checkout-info.description-civil-number')}
											</DescriptionWrapper>
										</ListItem>
									</DescriptionContainer>
								)}
							/>
							<ConditionalRender
								show={!!isPrepaid}
								onTrue={() => (
									<DescriptionContainer>
										<ListItem>
											<DescriptionWrapper>
												{translate('checkout-info.description-3-hayyak')}
											</DescriptionWrapper>
										</ListItem>
									</DescriptionContainer>
								)}
								onFalse={() => (
									<DescriptionContainer>
										<ListItem>
											<DescriptionWrapper>
												{translate(
													isBuyHbbWfbbProcessWithOtherDevice
														? 'checkout-info.description-3'
														: 'checkout-info.description-3-hbbwfbb'
												)}
											</DescriptionWrapper>
										</ListItem>
									</DescriptionContainer>
								)}
							/>
						</List>
					</div>

					<Visible xl lg md sm>
						<Col xl="content" lg="content" md="content" sm="content">
							<VerticalDivider />
						</Col>
					</Visible>
				</InfoWrapper>
				<Visible xs>
					<Divider withoutLine marginBottom={16} />
				</Visible>
			</Col>
			<ConditionalRender
				show={!!isPrepaid}
				onTrue={() => (
					<Col sm={!!isPrepaid ? 4 : 5} xs={12}>
						<ColContent>
							<div>
								<Row>
									<TitleWrapper>{translate('checkout-info.title-3')}</TitleWrapper>
								</Row>
								<DescriptionContainer>
									<DescriptionWrapper>
										{translate('checkout-info.title-3.description.2')}
									</DescriptionWrapper>
								</DescriptionContainer>
							</div>
							<Visible xl lg md sm>
								<Col xl="content" lg="content" md="content" sm="content">
									<VerticalDivider />
								</Col>
							</Visible>
						</ColContent>
					</Col>
				)}
			/>
			<ConditionalRender
				show={isBuyHbbWfbbProcessWithOtherDevice || !isBuyHbbWfbbProcess}
				onTrue={() => (
					<>
						<Col sm={isBuyHbbWfbbProcessWithOtherDevice || !!isPrepaid ? 4 : 6} xs={12}>
							<InfoWrapper additional={isPrepaid ? !isPrepaid : isBuyHbbWfbbProcessWithOtherDevice}>
								<div>
									<Row>
										<TitleWrapper>{translate('checkout-info.title-2')}</TitleWrapper>
									</Row>
									<DescriptionContainer>
										<DescriptionWrapper>
											{translate('checkout-info.description-4')}
										</DescriptionWrapper>
									</DescriptionContainer>
									<DescriptionContainer>
										<BoldDescriptionWrapper>
											{translate(
												isPrepaid
													? 'checkout-info.description-5.1'
													: 'checkout-info.description-5'
											)}
										</BoldDescriptionWrapper>
									</DescriptionContainer>
									<CardIconsContainer>
										<IconWrapper>
											<Icon name="visa" width={56} height={48} />
										</IconWrapper>
										<IconWrapper>
											<Icon name="mastercard" width={56} height={40} />
										</IconWrapper>
										<IconWrapper>
											<Icon name="american_express" width={56} height={40} />
										</IconWrapper>

										{isApplePayAllow && (
											<IconWrapper>
												<Icon name="apple_pay" width={56} height={40} />
											</IconWrapper>
										)}
									</CardIconsContainer>
								</div>
								<ConditionalRender
									show={isBuyHbbWfbbProcess}
									onTrue={() => (
										<Visible xl lg md sm>
											<Col xl="content" lg="content" md="content" sm="content">
												<VerticalDivider />
											</Col>
										</Visible>
									)}
								/>
							</InfoWrapper>
							<Visible xs>
								<Divider withoutLine marginBottom={16} />
							</Visible>
						</Col>
					</>
				)}
			/>
			<ConditionalRender
				show={isBuyHbbWfbbProcess && !isPrepaid}
				onTrue={() => (
					<Col sm={isBuyHbbWfbbProcessWithOtherDevice ? 4 : 5} xs={12}>
						<ColContent>
							<div>
								<Row>
									<TitleWrapper>{translate('checkout-info.title-3')}</TitleWrapper>
								</Row>
								<DescriptionContainer>
									<DescriptionWrapper>
										{translate(
											isBuyHbbWfbbProcess
												? 'checkout-info.title-3.description.1'
												: 'checkout-info.title-3.description'
										)}
									</DescriptionWrapper>
								</DescriptionContainer>
							</div>
						</ColContent>
						<Visible xs>
							<Divider withoutLine marginBottom={16} />
						</Visible>
					</Col>
				)}
			/>
		</CheckoutInfoCardContainer>
	);
};

export default CheckoutInfo;
