import endpoints from '@Utils/api/endpoints';
import { ENV } from '@Config/app.config';

const _url = endpoints.omantelApi.paymentSite().url;

const CONFIG = {
	PROD: 'portal.omantel.om/OmantelApi/webengine/',
	STG: 'testportal.omantel.om/OmantelApi/webengine/',
	TST: 'testportal.omantel.om/OmantelApi/webengine/',
};

const OmanNetPaymentURLGenerator = (): string => {
	return `https://${CONFIG[ENV]}${_url}`;
};

const OmanNetPaymentURL = OmanNetPaymentURLGenerator();

/**
 * Redirects to payment redirection URL
 * @param url {string} - Payment redirection URL. Defaults to Omanet payment URL
 * Redirects the user to the specified payment redirection URL
 */
export const paymentRedirection = (url?: string): void => {
	window.location.href = url ?? OmanNetPaymentURL;
};
