import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
	CardContainer,
	HeaderDescriptionWrapper,
	HeaderTitle,
	HeaderWrapper,
	ContentWrapper,
	PaymentInfoWrapper,
	PaymentLabelColumn,
	LabelWrapper,
	PaymentTokenWrapper,
	TitleLabelWrapper,
	PaymentIconWrapper,
	MakasibInfoWrapper,
	DividerWrapper,
} from '@Components/Card/cards/PaymentDetails/PaymentDetails.styled';
import { useSelector } from 'react-redux';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import PaymentTokenItem from '@Components/PaymentTokenItem/PaymentTokenItem';
import { IPaymentToken } from '@ApiModels/paymentTokens';
import { displayCardNumber, getCardType } from '@Utils/converters/payment';
import { useCart } from '@Utils/context/CartContext';
import { MAKASIB_POINTS_PRODUCT_ID, MAKASIB_VOUCHER_PRODUCT_ID } from '@Config/app.config';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { IPointsConversionElement } from '@ApiModels/getMakasibPointsCalculation';
import { convertPriceToPoints } from '@Utils/converters/makasibPointsCalculation';
import useScreen from '@Utils/hooks/useScreen';
import { roundMoney } from '@Utils/converters/roundMoney';

const PaymentDetails: FunctionComponent<types.cards.IPaymentDetailsCardProps> = ({ params: { paymentData } }) => {
	const [pointsCalculations, setPointsCalculations] = useState<IPointsConversionElement[]>([]);
	const { translate } = useTranslate();
	const paymentDataFromStore = useSelector((state: types.redux.IState) => state.checkout.paymentData);
	const { cart } = useCart();
	const { screen } = useScreen();
	const selectedItem = useMemo(() => {
		if (paymentDataFromStore?.token) {
			return paymentDataFromStore.token;
		} else if (paymentDataFromStore?.card) {
			const { cardNumber } = paymentDataFromStore.card;
			return {
				cardType: getCardType(cardNumber.replaceAll(' ', '')) ?? 'visa',
				displayNumber: displayCardNumber(cardNumber),
			} as IPaymentToken;
		}
	}, [paymentDataFromStore]);

	const makasibCartItem = cart.items.find((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID);
	const isApplePayAllow = useSelector((state: types.redux.IState) => state.api.authentication.isApplePayAllow);
	useEffect(() => {
		if (!!makasibCartItem) {
			OrderFlowService.getMakasibPointsCalculation().subscribe(
				(r: { nontelecomPointsConversion: IPointsConversionElement[] }) => {
					setPointsCalculations(r.nontelecomPointsConversion);
				},
				() => {}
			);
		}
	}, []);

	const vouchersValue = useMemo(() => {
		let counter = 0;
		cart.items
			.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)
			.forEach((item) => (counter = counter + Number(item.usedVoucherValueInOMR)));
		return counter;
	}, [cart.items]);

	return (
		<CardContainer>
			<HeaderWrapper>
				<Icon name="card" fill="black38" width={40} height={40} />
				<HeaderDescriptionWrapper>
					<HeaderTitle>{translate('payment-details.title')}</HeaderTitle>
				</HeaderDescriptionWrapper>
			</HeaderWrapper>
			<ContentWrapper>
				<ConditionalRender
					show={!!makasibCartItem}
					onTrue={() => (
						<MakasibInfoWrapper>
							<PaymentIconWrapper isMakasib>
								<Icon name="makasibPoints" fill="black38" width={35} height={35} />
							</PaymentIconWrapper>
							<PaymentLabelColumn>
								<TitleLabelWrapper>{translate('cart.makasib.summary.title')}</TitleLabelWrapper>
								<LabelWrapper>
									{translate(
										'payment-details.payment.makasib.description',
										!!makasibCartItem
											? convertPriceToPoints(pointsCalculations, -makasibCartItem?.price)
											: '',
										!!makasibCartItem ? roundMoney(-makasibCartItem.price) : ''
									)}
								</LabelWrapper>
								<ConditionalRender
									show={vouchersValue > 0}
									onTrue={() => (
										<DividerWrapper isSmall={screen(['sm', 'xs'])}>
											<Divider />
										</DividerWrapper>
									)}
								/>
							</PaymentLabelColumn>
						</MakasibInfoWrapper>
					)}
				/>
				<ConditionalRender
					show={vouchersValue > 0}
					onTrue={() => (
						<MakasibInfoWrapper>
							<PaymentIconWrapper isMakasib>
								<Icon name="eVoucher" fill="black38" width={35} height={35} />
							</PaymentIconWrapper>
							<PaymentLabelColumn>
								<TitleLabelWrapper>{translate('cart.e-voucher.payment.summary')}</TitleLabelWrapper>
								<ConditionalRender
									show={
										cart.items.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)
											.length > 1
									}
									onTrue={() => (
										<LabelWrapper>
											{translate(
												'cart.e-voucher.payment.summary.multiple-vouchers',
												cart.items.filter(
													(item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID
												).length,
												vouchersValue
											)}
										</LabelWrapper>
									)}
									onFalse={() => (
										<LabelWrapper>
											{translate(
												'cart.e-voucher.payment.summary.single-voucher',
												cart.items.find((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)
													?.voucherId ?? '',
												vouchersValue
											)}
										</LabelWrapper>
									)}
								/>
								<ConditionalRender
									show={Number(cart.totalProductPrice) > 0}
									onTrue={() => (
										<DividerWrapper isSmall={screen(['sm', 'xs'])}>
											<Divider />
										</DividerWrapper>
									)}
								/>
							</PaymentLabelColumn>
						</MakasibInfoWrapper>
					)}
				/>
				<ConditionalRender
					show={Number(cart.totalProductPrice) > 0}
					onTrue={() => (
						<PaymentInfoWrapper>
							<ConditionalRender
								show={!!isApplePayAllow && paymentData?.paymentMethod === 'applePay'}
								onTrue={() => (
									<>
										<PaymentIconWrapper>
											<Icon name="apple_pay" width={35} height={35} />
										</PaymentIconWrapper>
										<div style={{ marginBottom: '16px' }}>
											<PaymentLabelColumn>
												<TitleLabelWrapper>
													{translate('secure-checkout.payment-appple-pay')}
												</TitleLabelWrapper>
											</PaymentLabelColumn>
										</div>
									</>
								)}
								onFalse={() => (
									<>
										<PaymentIconWrapper>
											<Icon name="card" fill="black38" width={35} height={35} />
										</PaymentIconWrapper>
										<PaymentLabelColumn>
											<TitleLabelWrapper isCardPayment>
												{translate('payment-details.payment.card')}
											</TitleLabelWrapper>
											<PaymentTokenWrapper>
												{selectedItem && <PaymentTokenItem token={selectedItem} />}
											</PaymentTokenWrapper>
											<LabelWrapper isCardPayment>
												{translate(
													'payment-details.payment.card.description',
													roundMoney(cart.totalProductPrice)
												)}
											</LabelWrapper>
										</PaymentLabelColumn>
									</>
								)}
							/>
						</PaymentInfoWrapper>
					)}
				/>
			</ContentWrapper>
		</CardContainer>
	);
};

export default PaymentDetails;
