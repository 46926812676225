import RxQuery from '@Utils/api/rxQuery/rxQuery';
import endpoints from '@Utils/api/endpoints';
import * as payloads from '@Utils/api/payloads/omantelApi';
import { BillingType } from '@ApiModels/accounts';
import { ICustomerProductsPayload } from '@Services/packages/packages';
import { ICheckout } from '@Redux/modules/checkout/actions';
import { getLanguage } from '@Utils/language/language';
import { CAR_INSURANCE_API_TIMEOUT } from '@Config/app.config';
import { InsuranceType } from '@Utils/context/InsuranceContext';
import { ITenant } from '@ApiModels/createTenant';

const omantelApi = () =>
	new RxQuery('omantelApi').setHeaders({ 'X-Omt-App': 'eshop', 'X-Omt-Device': window.navigator.platform });
// Accounts
export const getAccountsForLoggedUser = (): RxQuery => {
	return omantelApi().setEndpoint(endpoints.omantelApi.getAccountsForLoggedUser()).setType('accountsForLoggedUser');
};

export const getCustomerAccountOperation = (
	phoneNumber: string,
	doNotGetPinPukDetails: 'true' | 'false' = 'false'
): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getCustomerAccountOperation())
		.setPayload(payloads.getCustomerAccountProduct(phoneNumber, doNotGetPinPukDetails))
		.setType('relatedParties');
};

export const postUserPersonalInfo = (
	functionalFlow: string,
	errorCode: string,
	errorMsg: string,
	response: string,
	request: string | undefined
): RxQuery => {
	return omantelApi()
		.setHeaders({
			'x-channel-id': 'Eshop',
			'x-device-id': window.navigator.platform,
			'x-unique-id': String(Math.random().toString(36).substr(2, 9)),
			'x-language': 'EN',
		})
		.setEndpoint(endpoints.omantelApi.postUserPersonalInfo())
		.setPayload(payloads.postUserPersonalInfo(functionalFlow, errorCode, errorMsg, response, request));
};

// Authorization
export const login = (user: string, secret: string): RxQuery => {
	return omantelApi()
		.setPayload(payloads.login(user, secret))
		.setEndpoint(endpoints.omantelApi.login())
		.disableCache()
		.setType('authentication')
		.setTimeout(120000);
};

export const logout = (): RxQuery => {
	return omantelApi().setEndpoint(endpoints.omantelApi.logout()).disableCache().setTimeout(120000);
};

export const getFullUserProfileForCurrentUser = (): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getFullUserProfileForCurrentUser())
		.setInvalidateKey('getFullUserProfileForCurrentUser')
		.handleOffline()
		.disabledContentTypeHeader()
		.setTimeout(120000)
		.setType('userProfile');
};

export const getEbuUserProfileForCurrentUser = (): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getEbuUserProfileForCurrentUser())
		.setInvalidateKey('getEbuUserProfileForCurrentUser')
		.setType('ebuUserProfile');
};

// Payment
export const getPaymentTokensForCurrentUser = (): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getPaymentTokensForCurrentUser())
		.disableCache()
		.handleOffline()
		.setType('paymentTokens');
};

export const removePaymentToken = (paymentTokenId: string): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.removePaymentToken())
		.setPayload(payloads.removePaymentToken(paymentTokenId))
		.disableCache();
};

// Plans
export const getRecommendedPlan = (phoneNumber: string, billingType: BillingType): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getRecommendedPlan())
		.setPayload(payloads.getRecommendedPlan(phoneNumber, billingType))
		.setType('recommendedPlan')
		.setInvalidateKey('getRecommendedPlan');
};

export const getCustomerProducts = (payload: ICustomerProductsPayload): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getCustomerProducts())
		.setPayload(payloads.getCustomerProducts(payload))
		.setType('customerProducts');
};

export const getPaymentInformation = (): RxQuery => {
	return omantelApi().setEndpoint(endpoints.omantelShop.getPaymentInformation()).disableCache();
};

export const doPreCheckout = (): RxQuery => {
	return omantelApi().setEndpoint(endpoints.omantelShop.doPreCheckout()).disableCache().setType('preCheckout');
};

export const doCheckout = (): RxQuery => {
	return omantelApi().setEndpoint(endpoints.omantelShop.doCheckout()).disableCache().setType('checkout');
};

export const processOrder = ({
	totalAmount,
	orderId,
	billingAddress,
	contactDetails,
}: {
	totalAmount: string;
	orderId: string;
	billingAddress: ICheckout['payload']['billingAddress'];
	contactDetails: ICheckout['payload']['contactDetails'];
}): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelShop.processOrder())
		.setPayload(
			payloads.processOrder({
				totalAmount,
				orderId,
				billingAddress,
				contactDetails,
			})
		)
		.setType('processOrder')
		.disableCache();
};

export const makePayment = (): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.makePayment())
		.disableCache()
		.setHeader('X-Omt-User-Language', getLanguage().toLowerCase())
		.setTimeout(60000);
};

export interface IGetUsagePayload {
	accountType?: string;
	billingType?: string;
	getProducts: boolean;
	language: string;
	phoneNumber?: string;
	serializeNulls: boolean;
	apiVersion?: number;
}

export interface IGenerateOtpPayload {
	msisdn: string;
	operationContext: string;
}

export const getUsage = (payload: IGetUsagePayload): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getUsage())
		.setPayload(payloads.getUsage(payload))
		.withErrorHandling()
		.handleOffline()
		.setType('usage');
};

export const getMakasibLoyaltyAccountOperation = (phoneNumber: string): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getMakasibLoyaltyAccountOperation())
		.disableCache()
		.setPayload(payloads.getMakasibLoyaltyAccountOperation(phoneNumber))
		.setType('makasibLoyaltyAccount');
};

export const getTenant = (tenantId: string, tenantName: string, orderId: string): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getTenant(orderId))
		.setPayload(payloads.getTenant(tenantId, tenantName))
		.setType('getTenant');
};

export const createTenant = (orderId: string, tenantData: ITenant): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.createTenant(orderId))
		.setPayload(payloads.createTenant(tenantData))
		.setType('createTenant');
};

export const changeTenantProvider = (orderId: string): RxQuery => {
	return omantelApi().setEndpoint(endpoints.omantelApi.changeTenantProvider(orderId)).setType('getTenant');
};

export const getTenantFromOrdersHistory = (orderId: string): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getTenantFromOrdersHistory(orderId))
		.setType('getTenantFromOrdersHistory');
};

export const manageMakasibVoucherReservation = (voucherCode: string, action: 'GW' | 'U' | 'G'): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.manageMakasibVoucherReservation())
		.disableCache()
		.setPayload(payloads.manageMakasibVoucherReservation(voucherCode, action))
		.setType('manageMakasibVoucherReservation');
};

export const getBillsHistoryForAccounts = (): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getBillsHistoryForAccounts())
		.setPayload(payloads.getBillsHistoryForAccounts())
		.setType('billsHistoryForAccounts');
};

export const getUserGiftCards = (): RxQuery => {
	return omantelApi().setEndpoint(endpoints.omantelApi.getUserGiftCards()).setType('userGiftCards');
};

export const getUserGiftCardsByOrderId = (orderId: string): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getUserGiftCardsByOrderId())
		.setPayload(payloads.getUserGiftCardsByOrderId(orderId))
		.setType('userGiftCards');
};

export const getSimDetails = (phoneNumber: string): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getSimDetails())
		.setPayload(payloads.getSimDetails(phoneNumber))
		.setType('simDetails');
};

export const getContractDetails = (subscriberNo: string): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getContractDetails())
		.setPayload(payloads.getContractDetails(subscriberNo))
		.setType('contractDetails');
};

export interface IGetVehicleInsurance {
	insurerName: 'BIMA' | 'NLG';
	insuranceType: InsuranceType;
	insuredLicense: string;
	insuredContactNo: string;
	vehiclePlateCharCode: string;
	vehicleNo: string;
	vehicleValue: string;
	languageCode: string;
}

export const getVehicleInsurance = (payload: IGetVehicleInsurance): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getVehicleInsurance())
		.setPayload(payloads.getVehicleInsurance(payload))
		.setType('vehicleInsurance')
		.setTimeout(CAR_INSURANCE_API_TIMEOUT)
		.withErrorHandling();
};

export const getVehiclePlateChars = (languageCode: string): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getVehiclePlateChars())
		.setPayload(payloads.getVehiclePlateChars(languageCode))
		.setType('vehiclePlateChars');
};

export const generateOTP = (payload: IGenerateOtpPayload): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.generateOTP())
		.disableCache()
		.setPayload(payloads.generateOTP(payload))
		.setType('generateOtp');
};

export const getProductsUnauthorized = (phoneNumber: string): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getProductsUnauthorized())
		.setPayload(payloads.getProductsUnauthorized(phoneNumber))
		.setType('getProductsUnauthorized');
};

export const validateOperation = (operationContext: string): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.validateOperation())
		.setPayload(payloads.validateOperation(operationContext))
		.setType('validateOperation')
		.disableCache();
};

export const validateOTP = (payload: types.payloads.IValidateOTP): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.validateOTP())
		.setPayload(payloads.validateOTP(payload))
		.setType('validateOTP')
		.disableCache();
};

export const submitProductOrder = (payload: types.payloads.ISubmitProductOrder): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.submitProductOrder())
		.setPayload(payloads.submitProductOrder(payload))
		.setType('submitProductOrder')
		.disableCache();
};

export const validateResource = (
	customerCode: string | null,
	customerType: string | null,
	requestType: string,
	serviceOrderType: string
): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.validateResource())
		.setPayload(payloads.validateResource({ customerCode, customerType, requestType, serviceOrderType }))
		.setType('validateResource')
		.disableCache();
};

export const getBranches = (payload: types.payloads.IGetBranchesPayload): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getBranches())
		.setPayload(payloads.getBranches(payload))
		.setType('getBranches')
		.disableCache();
};

export const getServices = (payload: types.payloads.IGetServicesPayload): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getServices())
		.setPayload(payloads.getServices(payload))
		.setType('getServices')
		.disableCache();
};

export const getDates = (payload: types.payloads.IGetDatesPayload): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getDates())
		.setPayload(payloads.getDates(payload))
		.setType('getDates')
		.disableCache();
};

export const getHours = (payload: types.payloads.IGetHoursPayload): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getHours())
		.setPayload(payloads.getHours(payload))
		.setType('getHours')
		.disableCache();
};

export const bookAppointment = (payload: types.payloads.IBookAppointmentPayload): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.bookAppointment())
		.setPayload(payloads.bookAppointment(payload))
		.setType('bookAppointment')
		.disableCache();
};

export const cancelAppointment = (payload: types.payloads.ICancelAppointmentPayload): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.cancelAppointment())
		.setPayload(payloads.cancelAppointment(payload))
		.setType('cancelAppointment')
		.disableCache();
};

export const getAppointment = (payload: types.payloads.IGetAppointmentPayload): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getAppointment())
		.setPayload(payloads.getAppointment(payload))
		.setType('getAppointment')
		.disableCache();
};

export const getAppointments = (payload: types.payloads.IGetAppointmentsPayload): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getAppointments())
		.setPayload(payloads.getAppointments(payload))
		.setType('getAppointments')
		.disableCache();
};

export const getCorpAccountsForCurrentUser = (): RxQuery => {
	return omantelApi()
		.setEndpoint(endpoints.omantelApi.getCorpAccountsForCurrentUser())
		.setInvalidateKey('getCorpAccountsForCurrentUser')
		.setType('corpAccounts');
};
