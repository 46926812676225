import React, { FunctionComponent, useMemo, useRef, useState } from 'react';
import {
	TextFieldContainer,
	TextFieldWrapper,
	TextFieldInput,
	TextFieldContent,
	Label,
	IconWrapperLeft,
	IconWrapperRight,
	TextFieldMessage,
	StaticLabel,
	RightTextClickWrapper,
	VerticalDivider,
	RightTextWrapper,
} from './TextField.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import theme from '@Utils/theme/theme';
import { ButtonM, FloatingLabel } from '@Components/Typography/Typography.styled';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';

export interface ITextFieldProps {
	label?: string;
	placeholder?: string;
	id: string;
	disabled?: boolean;
	buttonDisabled?: boolean;
	error?: boolean;
	onChange: (value: string | number) => void;
	value: string | number;
	typography?: keyof types.ITypography;
	rightText?: string;
	rightTextOnClick?: () => void;
	rightTextSpinner?: boolean;
	rightTextListener?: string | string[] | boolean;
	marginBottom?: number;
	marginTop?: number;
	leftIcon?: types.iconNames;
	rightIcon?: types.iconNames;
	staticLabel?: string;
	onIconClick?: () => void;
	message?: string;
	onFocus?: () => void;
	onBlur?: () => void;
	onEnter?: () => void;
	width?: number | string;
	type?: string;
	height?: number;
	isDomain?: boolean;
	name?: string;
	maxlength?: number;
	pattern?: string;
	noBorder?: boolean;
	leftIconFill?: types.theme.themeColors;
	placeholderTypography?: keyof types.ITypography;
	placeholderColor?: types.theme.themeColors;
	borderColor?: types.theme.themeColors;
	hoverColor?: types.theme.themeColors;
	inActive?: boolean;
	boxShadow?: boolean;
	padding?: string;
	rightTextPointer?: boolean;
}

const ICON_SIZE = 24;

const TextField: FunctionComponent<ITextFieldProps> = ({
	disabled,
	buttonDisabled,
	label,
	onChange,
	typography = 'valueBold',
	value,
	placeholder,
	error,
	marginBottom,
	marginTop,
	isDomain,
	rightText,
	rightTextOnClick,
	rightTextSpinner,
	rightTextListener,
	leftIcon,
	rightIcon,
	staticLabel,
	onIconClick,
	message,
	onFocus,
	onBlur,
	onEnter,
	width = 320,
	type = 'text',
	height,
	leftIconFill = 'black38',
	id,
	name,
	maxlength,
	pattern,
	noBorder = false,
	inActive = false,
	borderColor,
	rightTextPointer = false,
	hoverColor = theme.colors.primary,
	padding,
	placeholderTypography,
	placeholderColor,
	boxShadow,
}: ITextFieldProps) => {
	const [active, setActive] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const noLabel = !label;

	const handleOnBlur = () => {
		if (!disabled) {
			if (onBlur) {
				onBlur();
			}
			setActive(false);
		}
	};

	const handleOnFocus = () => {
		if (!disabled) {
			if (onFocus) {
				onFocus();
			}
			setActive(true);
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}
	};

	const handleKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			handleOnSubmit();
		}
	};

	const handleOnSubmit = () => {
		if (!disabled) {
			if (onEnter) {
				onEnter();
			}
		}
	};

	const handleOnChange = (e: { target: { value: React.ReactText } }) => {
		onChange(e.target.value);
	};

	const handleIconClick = () => {
		if (!disabled && onIconClick) {
			onIconClick();
		}
	};

	const getIconColor: types.color = useMemo(() => {
		if (!!disabled) {
			return 'black24';
		} else {
			return 'black87';
		}
	}, [disabled]);
	return (
		<TextFieldContainer width={width} dir={isDomain ? 'ltr' : 'unset'}>
			<TextFieldWrapper
				active={active}
				disabled={!!disabled}
				onClick={handleOnFocus}
				error={!!error}
				marginBottom={marginBottom}
				marginTop={marginTop}
				message={!!message}
				height={height}
				borderColor={borderColor}
				hoverColor={hoverColor}
				noBorder={noBorder}
				inActive={inActive}
				padding={padding}
				boxShadow={boxShadow}
			>
				<TextFieldContent>
					<ConditionalRender
						show={!!label}
						onTrue={() => (
							<Label value={value} active={active} error={!!error} disabled={!!disabled}>
								{label}
							</Label>
						)}
					/>
					<ConditionalRender
						show={!!staticLabel}
						onTrue={() => <StaticLabel data-value={staticLabel} value={value} active={active} />}
					/>
					<ConditionalRender
						show={!!leftIcon}
						onTrue={() => (
							<IconWrapperLeft>
								<Icon name={leftIcon} width={ICON_SIZE} height={ICON_SIZE} fill={leftIconFill} />
							</IconWrapperLeft>
						)}
					/>
					<TextFieldInput
						value={value}
						name={name}
						ref={inputRef}
						onFocus={handleOnFocus}
						onBlur={handleOnBlur}
						onChange={handleOnChange}
						onKeyPress={handleKeyPress}
						typography={typography}
						placeholder={placeholder}
						active={active}
						noLabel={noLabel}
						disabled={disabled}
						type={type}
						id={id ?? ''}
						maxLength={maxlength}
						pattern={pattern}
						placeholderTypography={placeholderTypography}
						placeholderColor={placeholderColor}
					/>

					<ConditionalRender
						show={!!rightIcon}
						onTrue={() => (
							<IconWrapperRight onClick={handleIconClick}>
								<Icon name={rightIcon} width={ICON_SIZE} height={ICON_SIZE} fill={getIconColor} />
							</IconWrapperRight>
						)}
					/>
					<ConditionalRender
						show={!!rightText}
						onTrue={() => (
							<RightTextWrapper>
								<ConditionalRender
									show={!!rightTextOnClick}
									onTrue={() => (
										<BusyIndicator listener={rightTextListener}>
											<RightTextClickWrapper cursorPointer={rightTextPointer}>
												<FloatingLabel
													onClick={() =>
														buttonDisabled !== undefined && buttonDisabled
															? {}
															: disabled
															? {}
															: !!rightTextOnClick
															? rightTextOnClick()
															: {}
													}
												>
													<ButtonM
														color={
															(buttonDisabled !== undefined && buttonDisabled) || disabled
																? 'black38'
																: 'primaryHover'
														}
													>
														{rightText}
													</ButtonM>
												</FloatingLabel>
											</RightTextClickWrapper>
										</BusyIndicator>
									)}
									onFalse={() => (
										<>
											<VerticalDivider />
											<FloatingLabel color="black54">{rightText}</FloatingLabel>
										</>
									)}
								/>
							</RightTextWrapper>
						)}
					/>
				</TextFieldContent>
			</TextFieldWrapper>
			<ConditionalRender
				show={!!message}
				onTrue={() => (
					<TextFieldMessage error={!!error} marginBottom={marginBottom}>
						{message}
					</TextFieldMessage>
				)}
			/>
		</TextFieldContainer>
	);
};

export default TextField;
